




li a {
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 12px !important;
 
  font-weight: 100;
}

.NavLink {

  text-decoration: none;
  color: black;
  /* font-weight: bold !important; */
  font-size: 15px !important;
}

.navbar {
  border-bottom: 1px solid lightgray;
}

.nav-link i {
  font-size: 20px;
}

.icons {
  width: 350px;
  margin: 0;
}

.nav-item a .i {
  margin-left: 20px;
}

.carouselItem1 {
  background-image: url('./img/header1.jpg');
  height: 800px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.carouselItem2 {
  background-image: url('./img/header2.jpg');
  height: 800px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.carouselItem3 {
  background-image: url('./img/header3.jpeg');
  height: 800px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.sliderText {

  text-transform: uppercase;
  letter-spacing: 1px;
}

.middleText {

  text-transform: uppercase;
  letter-spacing: 1px;
}

.sliderButton {
  
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 0;
}

.MiddleLineText {
  
  font-style: italic;
  font-size: 30px;
}

.mainText {
  margin-top: 200px;
}

.image-container {
  position: relative;
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: center;
}

.ImageCon1 {
  height: 400px;
  width: 90%;
}

.text-container {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.text-container1 {
  position: absolute;
  bottom: 30%;
  width: 100%;
  justify-content: center;
  display: flex;
}

.cardText {
  
  color: white;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}



.btn-secondary {
  border-radius: 0;
  color: black;
  background-color: transparent;
  border: 2px solid white;
  text-transform: uppercase;
  font-weight: 400;
  color: white;
  letter-spacing: 1px;
  
}

.TitleText h3 {
  
  letter-spacing: 1px;
  padding: 10px;
  font-size: 27px;

}

.BuyingImage {
  height: 400px;
  width: 310px;
}

.CardText h5 {
  text-transform: uppercase;
  
  font-size: 13px;
  color: black;
  letter-spacing: 3px;
}

.CardText p {
  text-transform: uppercase;
  
  font-size: 11px;
  color: black;
  letter-spacing: 3px;
}

.BlackTag {
  right: 0;
  padding: 5px;
  color: white;
  background-color: black;
  text-transform: uppercase;
  

}

.BlackTag1 {

  width: 100%;
  padding: 5px;
  color: white;
  background-color: black;
  text-transform: uppercase;
  

}

.ImageContainer1 img {
  transform: rotate(90deg);
  width: 60%;
  margin-left: 100px;
}

.VideoContainer {
  height: 500px;
  width: 100%;
  background-color: black;
  margin-top: 95px;
  background-image: url('https://cdn.shopify.com/s/files/1/0466/5763/4466/files/WhatsApp_Video_2021-09-16_at_7.07.48_AM.mp4?v=1631794108');
}

.HeroBannerImage {
  height: 650px;
  width: 100%;
  background-image: url('./img/ni.jpg');
  background-size: cover;
  margin-top: 50px;
}

.HeroBannerText {
  margin-top: 50px;
  position: absolute;

}

.HeroBannerText p {
  text-transform: uppercase;
  
  letter-spacing: 2px;
  color: gray;
}

.HeroBannerText h3 {
  
  letter-spacing: 2px;
  font-size: 30px;
  color: black;
  margin-bottom: 10px;
}

.HeroBannerText h5 {
  
  letter-spacing: 2px;
  font-size: 18px;
  color: gray;
  margin-top: 20px;
  margin-bottom: 20px;
}

.HeroBannerText button {
  
  letter-spacing: 2px;
  font-size: 16px;
  color: white;
  background-color: black;
  border-radius: 0;
  font-weight: bold;
}

.MemorialCardText h2 {
  
  letter-spacing: 2px;
  font-size: 29px;

}

.MemorialCardText button {
  border-radius: 0;
  color: black;
  background-color: transparent;
  border: 2px solid lightgray;
  text-transform: uppercase;
  font-weight: 400;
  color: gray;
  font-size: 13px;
  letter-spacing: 1px;
  
}

.MemorialCardImage1 {
  height: 100%;
  width: 100%;
  padding: 5px;
}

.MemorialCardText1 p {
  
  color: black;
}

.MemorialCardText1 h3 {
  
  color: black;
  font-size: 24px;
}

.MemorialCardText1 h5 {
  
  color: black;
  font-size: 20px;
}

.MemorialCardImage1 {
  width: 100%;
}

.MemorialCardImage2 {
  width: 87%;
  height: 50%;
  margin-top: 5px;
}

.MemorialCardText1 {
  width: 100%;
}

.MainFooter {
  border-top: 1px solid lightgray;
}

.FooterSection1 .NavLink {
  text-decoration: none;
  /* font-weight: bold; */
  
}

.FooterSection1 p {
  color: black;
  
  text-decoration: none;
}

.EmailForm input {
  border: 0;
  color: black;
  border-bottom: 2px solid black;
  border-radius: 0px;
}

.EmailForm input::placeholder {
  color: black;
  
  font-size: 18px;
  letter-spacing: 1px;
}

.input-group-text {
  background-color: transparent;
  border: 0;
  border-radius: 0%;
  border-bottom: 2px solid black;
}

.input-group-text i {
  font-weight: 100;
}

.AllSection >  h5 {
  font-size: 20px;
 
 
}

.AllSection >  h2 {
  font-size: 50px;
 

}

.AllSection >  h4 {
  font-size: 30px;
 

}


textarea {
  height: 200px;
  width: 300px;

}

.input-group input {
  border-radius: 0;
}

.input-group input::placeholder {
  border-radius: 0;
  color: black;
  
  text-transform: uppercase;
  font-size: 12px;
  color: gray;
}

.ContactButton {
  
  letter-spacing: 2px;
  font-size: 14px;
  color: white;
  background-color: black;
  border-radius: 0;
  font-weight: bold;
}

.EndText h6 {
  
  letter-spacing: 1px;
  font-size: 13px;
  text-transform: uppercase;
}

.AboutSection h6 {
  
  font-size: 15px;
  letter-spacing: 1px;
  margin-top: 20px;

}

.contained:hover {
  cursor: pointer;
}

.ModalText h5 {
  margin: 20px;
  
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 15px;

}

.ModalText h6 {
  margin: 20px;

  
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 13px;
}

.ModalText button {
  
  letter-spacing: 1px;
  font-size: 13px;
  border-radius: 0;
}

.ModalTextBox {
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;

}

.viewIcon {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #35313399;
  bottom: 90px;
  right: 30px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;

}

.ViewIconMobile {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #35313399;
  top: 5%;
  left:5%;
  color: white;
  font-size: small;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ViewIconMobile:hover {
  background: rgb(46, 45, 45);
}

.viewIcon:hover {
  background: rgb(46, 45, 45);
}

.viewIcon:hover .fa.fa-eye {
  color: #fff !important;
}

.imgActive {
  filter: grayscale(100%);
}

.middleIconImage {
  width: 60px;
}

@media screen and (max-width: 500px) {
  .icons {
    width: 100px;
  }

  .nav-item a {
    margin-left: 0px;
  }

  .Menubar {
    margin-right: 0px;
  }

  .carouselItem1 {
    text-align: center;
    height: 300px;
  }

  .carouselItem2 {
    text-align: center;
    height: 300px;

  }

  .carouselItem3 {
    text-align: center;
    height: 300px;

  }

  .mainText {
    text-align: center;
    margin-top: 100px;
  }

  .mainText h1 {
    font-size: 40px;
  }

  .ImageCon1 {
    height: 400px;
    margin: 5px;
  }

  .middleIconImage {
    width: 40px;
  }

  .BuyingImage {
    height: 200px;
    width: 150px;
    margin-top: 10px;
  }

  .BlackTag {
    display: none;
  }

  .BlackTag1 {
    display: none;
  }

  .HeroBannerText {
    margin-right: 100px;
  }

  .MemorialCard1 {
    display: flex;
    margin-top: 20px;
  }

  .MemorialCardImage1 {
    width: 100%;
    text-align: left;
  }

  .MemorialCardImage2 {
    width: 95%;
    height: 100%;
  }

  .card3 {
    margin-top: 10px;
  }

  .MemorialCardText1 p {
    font-size: 13px;
    margin-top: 15px;
  }

  .MemorialCardText1 h5 {
    font-size: 15px;
  }

  .MemorialCardText1 h3 {
    font-size: 20px;
  }

  .MemorialCardText1 {
    width: 50%;
    text-align: left;
    padding-left: 15px;
  }

  .CardImageContainer {
    width: 50%;
  }

  .FooterSection1 {
    text-align: center;
    border-top: 1px solid lightgray;
    
  }

  .MainFooter {
    border: 0
  }

  .EmailTag {
    justify-content: center;
  }

  .SocialMediaIcons {
    text-align: center;
    /* margin: 10px; */
    margin-top: 10px;
  }

  .HeroBannerImage {
    height: 800px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}