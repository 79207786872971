/*-----------------------------------------------------------------------------------

    
    CSS INDEX
    =========================================
    01. header css here
    02. slider area css here
    03. product section css here
    04. banner section css here 
    05. blog area css here
    06. newsletter area css here
    07. footer area css here
    08. shop page css here
    09. error page css heer 
    10. about page css here
    11. blog page css here 
    12. blog details css here
    13. cart page css here
    14. checkout page css here
    15. contact page css here
    16. faq page css here
    17. login page css here
    18. my account css here
    19. portfolio page css here
    20. services page css here
    21. shipping css here
    22. wishlist css here
    23. newsletter popup css here
    24. modal css here
    25. privacy-policy css here
    26. compare css here
    27. coming soon css here
    
    

-----------------------------------------------------------------------------------*/
/* @import url("https://fonts.googleapis.com/css?family=Playfair+Display:400,400i,700,700i,900,900i|Rubik:300,300i,400,400i,500,500i,700,700i,900,900i|Prata&amp;subset=cyrillic,cyrillic-ext,vietnamese"); */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Alice", serif;
  font-weight: 400;
  font-style: normal;


}

/* transition */
/* flex */
/* transform */
/* opacity */
/*----------------------------------------*/
/*  01. Theme default CSS
/*----------------------------------------*/
/*-- Google Font --*/
/*-- Common Style --*/
*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  height: 100%;

}

body {
  line-height: 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  visibility: visible;

  color: #5a5a5a;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin-top: 0;
  /*  */

}

p {}

h1 {
  font-size: 48px;
  line-height: 1;
  font-weight: 700;
}

h2 {
  font-size: 36px;
  line-height: 36px;
}

h3 {
  font-size: 30px;
  line-height: 30px;
}

h4 {
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
}

h5 {
  font-size: 14px;
  line-height: 18px;
}

h6 {
  font-size: 12px;
  line-height: 14px;
}

p:last-child {
  margin-bottom: 0;
}

a,
button {
  color: inherit;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;

}

.btn:focus {
  box-shadow: none;
}

a,
button,
img,
input,
span {
  transition: all 0.3s ease 0s;
}

*:focus {
  outline: none !important;
}

a:focus {
  color: inherit;
  outline: none;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

button,
input[type="submit"] {
  cursor: pointer;
}

img {
  max-width: 100%;
  height: auto;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

/*-- 
    - Common Classes
-----------------------------------------*/
.fix {
  overflow: hidden;
}

.hidden {
  display: none;
}

.clear {
  clear: both;
}

@media only screen and (max-width: 767px) {
  .container {
    max-width: 500px;
  }
}

@media only screen and (max-width: 479px) {
  .container {
    width: 100%;
  }
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.no-gutters>.col,
.no-gutters>[class*="col-"] {
  padding-right: 0;
  padding-left: 0;
  margin: 0;
}

/*-- 
    - Background color
-----------------------------------------*/
.bg-white {
  background-color: #ffffff;
}

.bg-light-grey {
  background-color: #f6fafb;
}

.bg-grey {
  background-color: #f8f8f8;
}

.bluewood-bg {
  background: #354b65;
}

/*- Overlay Color BG -*/
.bluewood-bg-overlay {
  position: relative;
}

.bluewood-bg-overlay::before {
  background: rgba(70, 90, 112, 0.9);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.overly-bg-black {
  position: relative;
}

.overly-bg-black::after {
  background: rgba(0, 0, 0, 0.3);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

/*-- 
    - color
-----------------------------------------*/
/*-- 

/*-- 
    Scroll Up 
-----------------------------------------*/
#scrollUp {
  background: #212121 none repeat scroll 0 0;
  bottom: 85px;
  color: #ffffff;
  cursor: pointer;
  display: none;
  font-size: 20px;
  height: 45px;
  line-height: 39px;
  position: fixed;
  right: 12px;
  text-align: center;
  width: 45px;
  z-index: 9999;
  border-radius: 50%;
  border: 2px solid #fff;
  -webkit-transition: .3s;
  transition: .3s;
}

#scrollUp:hover i {
  animation-name: fadeInUp;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

/*-- 
    - Main Wrapper
-----------------------------------------*/
.main-wrapper.header-transparent {
  padding: 0 !important;
  margin: 0 !important;
}

/*-- 
    - Section Padding
-------------------------------------*/
.section-ptb {
  padding: 100px 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-ptb {
    padding: 90px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-ptb {
    padding: 80px 0;
  }
}

@media only screen and (max-width: 767px) {
  .section-ptb {
    padding: 70px 0;
  }
}

@media only screen and (max-width: 479px) {
  .section-ptb {
    padding: 60px 0;
  }
}

.section-pt {
  padding-top: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-pt {
    padding-top: 90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-pt {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-pt {
    padding-top: 70px;
  }
}

@media only screen and (max-width: 479px) {
  .section-pt {
    padding-top: 60px;
  }
}

.section-pt-70 {
  padding-top: 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-pt-70 {
    padding-top: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-pt-70 {
    padding-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-pt-70 {
    padding-top: 40px;
  }
}

@media only screen and (max-width: 479px) {
  .section-pt-70 {
    padding-top: 30px;
  }
}

.section-pb-70 {
  padding-bottom: 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-pb-70 {
    padding-bottom: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-pb-70 {
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-pb-70 {
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 479px) {
  .section-pb-70 {
    padding-bottom: 30px;
  }
}

.section-pb {
  padding-bottom: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-pb {
    padding-bottom: 90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-pb {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-pb {
    padding-bottom: 70px;
  }
}

@media only screen and (max-width: 479px) {
  .section-pb {
    padding-bottom: 60px;
  }
}

/* 02. Element */
.button {
  background: #f743a6;
  box-shadow: none;
  color: #ffffff;
  display: inline-block;
  height: 45px;
  line-height: 45px;
  padding: 0 22px;
  text-transform: uppercase;
  font-size: 13px;
  border-radius: 3px;
}

/* 2. Home 1 Header css here */
/* 01. header css here*/
.sticky-header.sticky {
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  background: rgba(255, 255, 255, 0.9);
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.11);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.11);
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 900ms;
  animation-duration: 900ms;
  -webkit-animation-timing-function: cubic-bezier(0.2, 1, 0.22, 1);
  animation-timing-function: cubic-bezier(0.2, 1, 0.22, 1);
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-fill-mode: none;
  animation-fill-mode: none;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sticky-header.sticky {
    position: inherit;
    -webkit-box-shadow: inherit;
    box-shadow: inherit;
    -webkit-animation-name: inherit;
    animation-name: inherit;
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .sticky-header.sticky {
    position: inherit;
    -webkit-box-shadow: inherit;
    box-shadow: inherit;
    -webkit-animation-name: inherit;
    animation-name: inherit;
    padding: 0;
  }
}

.off_canvars_overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  cursor: crosshair;
  background: #5a5a5a;
  top: 0;
}

.off_canvars_overlay.active {
  opacity: 0.5;
  visibility: visible;
}

.Offcanvas_menu {
  display: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .Offcanvas_menu {
    display: block;
  }

  .Offcanvas_menu {
    display: block;
    position: fixed;
    z-index: 9999999;
    top: 0;
    left: 39px;

  }

}

@media only screen and (max-width: 767px) {
  .Offcanvas_menu {
    display: block;
    position: fixed;
    z-index: 9999999;
    top: 0;
    left: 39px;

  }
}

.Offcanvas_menu .container {
  position: relative;
}

.Offcanvas_menu_wrapper {
  width: 290px;
  position: fixed;
  background: #fff;
  z-index: 999;
  top: 0;
  height: 100vh;
  transition: .5s;
  left: 0;
  margin-left: -300px;
  padding: 50px 20px 30px;
  overflow-y: auto;
}

.Offcanvas_menu_wrapper.active {
  margin-left: 0;
}

.Offcanvas_menu_wrapper .slinky-theme-default {
  background: inherit;
  min-height: 300px;
  overflow-y: auto;
}

.Offcanvas_menu_wrapper .search_container {
  display: block;
}

.Offcanvas_menu_wrapper .home_contact {
  display: flex;
}

.Offcanvas_menu_wrapper .welcome_text {
  display: block;
}

.Offcanvas_menu_wrapper .top_right {
  display: block;
}

.offcanvas_main_menu>li.menu-item-has-children.menu-open>span.menu-expand {
  transform: rotate(180deg);
}

.offcanvas_main_menu>li ul li.menu-item-has-children.menu-open span.menu-expand {
  transform: rotate(180deg);
}

.offcanvas_main_menu li {
  position: relative;
}

.offcanvas_main_menu li:last-child {
  margin: 0;
}

.offcanvas_main_menu li span.menu-expand {
  position: absolute;
  right: 0;
}

.offcanvas_main_menu li a {
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  display: block;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ebebeb;
}

.offcanvas_main_menu li a:hover {
  color: #f743a6;
}

.offcanvas_main_menu li ul.sub-menu {
  padding-left: 20px;
}

.Offcanvas_footer {
  margin-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}

.Offcanvas_footer span a {
  font-size: 14px;
}

.Offcanvas_footer span a:hover {
  color: #f743a6;
}

.Offcanvas_footer ul {
  margin-top: 20px;
}

.Offcanvas_footer ul li {
  display: inline-block;
  margin-right: 4px;
}

.Offcanvas_footer ul li:last-child {
  margin-right: 0;
}

.Offcanvas_footer ul li a {
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  font-size: 13px;
  color: #ffffff;
}

.Offcanvas_footer ul li.facebook a {
  background: #3c5b9b;
}

.Offcanvas_footer ul li.facebook a:hover {
  background: #f743a6;
}

.Offcanvas_footer ul li.twitter a {
  background: #1DA1F2;
}

.Offcanvas_footer ul li.twitter a:hover {
  background: #f743a6;
}

.Offcanvas_footer ul li.pinterest a {
  background: #BD081B;
}

.Offcanvas_footer ul li.pinterest a:hover {
  background: #f743a6;
}

.Offcanvas_footer ul li.google-plus a {
  background: #DD4D42;
}

.Offcanvas_footer ul li.google-plus a:hover {
  background: #f743a6;
}

.Offcanvas_footer ul li.linkedin a {
  background: #010103;
}

.Offcanvas_footer ul li.linkedin a:hover {
  background: #f743a6;
}

.slinky-theme-default a:not(.back) {
  padding: 10px 0;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
}

.slinky-theme-default a:not(.back):hover {
  background: inherit;
  color: #f743a6;
}

.canvas_close {
  position: absolute;
  top: 10px;
  right: 13px;
}

.canvas_close a {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 500;
  width: 32px;
  height: 32px;
  display: block;
  text-align: center;
  line-height: 31px;
  border: 1px solid #ebebeb;
  border-radius: 50%;
}

.canvas_close a:hover {
  background: #f743a6;
  border-color: #f743a6;
  color: #fff;
}

.canvas_open {
  position: absolute;
  right: 13px;
  top: 36px;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .canvas_open {
    left: 64px;
    top: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .canvas_open {
    left: 0px;
    top: 27px;
  }
}

.canvas_open a {
  font-size: 30px;
  color: #5a5a5a;
  width: 43px;
  height: 41px;
  display: block;
  line-height: 40px;
  text-align: center;
  border: 1px solid #5a5a5a;
  border-radius: 3px;
}

@media only screen and (max-width: 767px) {
  .canvas_open a {
    width: 35px;
    height: 34px;
    line-height: 34px;
    font-size: 24px;
  }
}

.canvas_open a:hover {
  color: #f743a6;
  border-color: #f743a6;
}

.Offcanvas_two .cart_link {
  margin-left: 18px;
}

.Offcanvas_two .cart_link>a {
  padding: 11px 5px 13px 11px;
}

.Offcanvas_two .cart_link>a i.ion-android-cart {
  margin-right: 5px;
  padding-right: 7px;
}

.Offcanvas_two .box_setting {
  margin-left: 16px;
  margin-top: 0;
  margin-bottom: 0;
}

.Offcanvas_two .dropdown_search {
  left: -10px;
}

.Offcanvas_two .dropdown_setting {
  min-width: 160px;
  left: -48px;
}

.Offcanvas_five .canvas_open a {
  color: #ffffff;
  border: 1px solid #ffffff;
}

.Offcanvas_five .canvas_open a:hover {
  color: #f743a6;
  border-color: #f743a6;
}

.Offcanvas_five .contact_box {
  margin-bottom: 19px;
  text-align: center;
}

.Offcanvas_five .wishlist_btn {
  margin-left: 16px;
}

.Offcanvas_five .wishlist_btn a {
  width: 45px;
  height: 45px;
  border-radius: 3px;
  text-align: center;
  line-height: 45px;
  border: 1px solid #ebebeb;
  color: #212121;
  font-size: 20px;
  display: block;
}

.Offcanvas_five .wishlist_btn a:hover {
  color: #f743a6;
}

.Offcanvas_five .cart_link {
  margin-left: 18px;
}

.Offcanvas_five .cart_link>a {
  padding: 11px 6px 13px 14px;
}

.Offcanvas_five .cart_link>a i.ion-android-cart {
  margin-right: 5px;
  padding-right: 5px;
}

.Offcanvas_five .dropdown_search {
  left: -10px;
}

.Offcanvas_black2 .canvas_open a {
  color: #ffffff;
  border: 1px solid #ffffff;
}

.Offcanvas_black2 .canvas_open a:hover {
  color: #f743a6;
  border-color: #f743a6;
}

.header_top {
  border-bottom: 1px solid #ebebeb;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_top {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .header_top {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .welcome_text {
    text-align: center;
    margin-bottom: 11px;
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .welcome_text {
    text-align: center;
    margin-bottom: 11px;
    display: none;
  }
}

.welcome_text p {
  font-size: 12px;
  color: #212121;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .welcome_text p {
    line-height: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .welcome_text p {
    line-height: 22px;
  }
}

.welcome_text p span {
  color: #f743a6;
}

.top_right {
  text-align: right;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .top_right {
    text-align: center !important;
    display: none;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .top_right {
    text-align: center !important;
    display: none;
    margin-bottom: 15px;
  }
}

.top_right.text-left {
  text-align: left;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .top_right.text-left {
    text-align: center !important;
  }
}

@media only screen and (max-width: 767px) {
  .top_right.text-left {
    text-align: center !important;
  }
}

.top_right>ul>li {
  display: inline-block;
  position: relative;
  padding-left: 20px;
  margin-left: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .top_right>ul>li {
    padding-left: 13px;
    margin-left: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .top_right>ul>li {
    padding-left: 13px;
    margin-left: 13px;
  }
}

.top_right>ul>li:hover ul.dropdown_currency {
  top: 100%;
  opacity: 1;
  visibility: visible;
}

.top_right>ul>li:hover ul.dropdown_language,
.top_right>ul>li:hover .header_black ul.dropdown_currency,
.header_black .top_right>ul>li:hover ul.dropdown_currency,
.top_right>ul>li:hover .header_black ul.dropdown_links,
.header_black .top_right>ul>li:hover ul.dropdown_links {
  top: 100%;
  opacity: 1;
  visibility: visible;
}

.top_right>ul>li:hover ul.dropdown_links {
  top: 100%;
  opacity: 1;
  visibility: visible;
}

@media only screen and (max-width: 767px) {
  .top_right>ul>li {
    padding-left: 14px;
    margin-left: 14px;
  }
}

.top_right>ul>li:first-child {
  padding-left: 0;
  margin-left: 0;
}

.top_right>ul>li::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 15px;
  background: #ebebeb;
  top: 50%;
  left: 0px;
  transform: translatey(-50%);
}

.top_right>ul>li:first-child::before {
  display: none;
}

.top_right>ul>li>a {
  color: #242424;
  text-transform: capitalize;
  line-height: 26px;
  font-size: 12px;
  cursor: pointer;
  display: block;
  font-weight: 400;
  padding: 9px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .top_right>ul>li>a {
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .top_right>ul>li>a {
    padding: 0;
  }
}

.top_right>ul>li>a i {
  margin-left: 3px;
  font-size: 10px;
}

.top_right>ul>li>a:hover {
  color: #f743a6;
}

.top_right>ul>li>a:not([href]):not([tabindex]) {
  color: #212121;
}

.top_right>ul>li:hover a:not([href]):not([tabindex]) {
  color: #f743a6;
}

.dropdown_currency,
.dropdown_language,
.header_black .dropdown_currency,
.header_black .dropdown_links,
.dropdown_links {
  position: absolute;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  min-width: 200px;
  text-align: center;
  top: 125%;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
  overflow: hidden;
  left: -154%;
  z-index: 9999;
  padding: 0 15px;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

  .dropdown_currency,
  .dropdown_language,
  .header_black .dropdown_currency,
  .header_black .dropdown_links,
  .dropdown_links {
    left: -10px;
    min-width: 144px;
    padding: 0 10px;
  }
}

@media only screen and (max-width: 767px) {

  .dropdown_currency,
  .dropdown_language,
  .header_black .dropdown_currency,
  .header_black .dropdown_links,
  .dropdown_links {
    left: -10px;
    min-width: 144px;
    padding: 0 10px;
  }
}

.dropdown_links {
  left: -98%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dropdown_links {
    left: auto;
    right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .dropdown_links {
    left: auto;
    right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dropdown_currency {
    left: -30px;
  }
}

@media only screen and (max-width: 767px) {
  .dropdown_currency {
    left: -30px;
  }
}

.dropdown_currency li {
  border-bottom: 1px solid #ebebeb;
  line-height: 36px;
  padding: 5px 0;
}

.dropdown_currency li:last-child {
  border-bottom: 0;
}

.dropdown_currency li a {
  text-transform: capitalize;
  display: block;
  font-size: 13px;
  color: #242424;
  text-align: center;
}

.dropdown_currency li a:hover {
  color: #f743a6;
}

.dropdown_language li,
.header_black .dropdown_currency li,
.header_black .dropdown_links li {
  border-bottom: 1px solid #ebebeb;
  line-height: 36px;
  padding: 5px 0;
}

.dropdown_language li:last-child,
.header_black .dropdown_currency li:last-child,
.header_black .dropdown_links li:last-child {
  border-bottom: 0;
}

.dropdown_language li a,
.header_black .dropdown_currency li a,
.header_black .dropdown_links li a {
  text-transform: capitalize;
  display: block;
  font-size: 12px;
  color: #242424;
  text-align: center;
}

.dropdown_language li a:hover,
.header_black .dropdown_currency li a:hover,
.header_black .dropdown_links li a:hover {
  color: #f743a6;
}

.dropdown_links li {
  border-bottom: 1px solid #ebebeb;
  line-height: 36px;
  padding: 5px 0;
}

.dropdown_links li:last-child {
  border-bottom: 0;
}

.dropdown_links li a {
  text-transform: capitalize;
  display: block;
  font-size: 12px;
  color: #242424;
  text-align: center;
}

.dropdown_links li a:hover {
  color: #f743a6;
}

.top_right ul li a img {
  margin-right: 5px;
}

/*header top css end*/
/* 2.2 header middel css here */
.home_contact {
  display: flex;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home_contact {
    display: none;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .home_contact {
    display: none;
    margin-bottom: 30px;
  }
}

.home_contact .contact_box {
  padding-right: 25px;
  margin-right: 25px;
  border-right: 2px solid #ebebeb;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home_contact .contact_box {
    padding-right: 12px;
    margin-right: 12px;
  }
}

@media only screen and (max-width: 767px) {
  .home_contact .contact_box {
    padding-right: 12px;
    margin-right: 12px;
  }
}

.home_contact .contact_box:last-child {
  padding-right: 0;
  margin-right: 0;
  border-right: 0;
}

.home_contact .contact_box label {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 11px;
  color: #a4a4a4;
  line-height: 17px;
  margin-bottom: 3px;
}

.home_contact .contact_box p {
  font-size: 12px;
  line-height: 17px;
}

.home_contact .contact_box p a:hover {
  color: #f743a6;
}

.logo {
  padding: 0 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .logo {
    padding: 0 40px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .logo {
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .logo a img {
    max-width: 110px;
  }
}

@media only screen and (max-width: 575px) {
  .logo a img {
    max-width: 107px;
  }
}

.header_middel {
  padding: 40px 0;
  border-bottom: 1px solid #ebebeb;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

  /* .header_middel {
    position: fixed;
    padding: 30px 0 32px;
    border-bottom: 0;
  } */
  .header_middel {
    position: fixed;
    background: #ffffff;
    padding: 20px 0 23px;
    border-bottom: 0;
    right: 0;
    z-index: 99;
    width: 100%;
  }

  .canvas_open {
    position: absolute;
    right: 0px;
    left: 60px;
    top: 19px;
    z-index: 9;
  }
}

@media only screen and (max-width: 767px) {
  .header_middel {
    position: fixed;
    background: #ffffff;
    padding: 20px 0 23px;
    border-bottom: 0;
    right: 0;
    z-index: 99;
    width: 100%;
  }

  .homeslider {
    margin-top: 82px;
  }


  .breadcrumbs_area {
    margin-top: 82px;
  }


  .bg2 {
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    height: 50vh;
    background-size: cover;
  }
}

.middel_right {
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  align-items: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .middel_right {
    justify-content: center;
  }
}

@media only screen and (max-width: 767px) {
  .middel_right {
    justify-content: center;
  }
}

.search_btn {
  position: relative;
}

.search_btn:hover .dropdown_search {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

.search_btn a {
  width: 45px;
  height: 45px;
  border-radius: 3px;
  text-align: center;
  line-height: 45px;
  /* border: 1px solid #ebebeb; */
  color: #212121;
  font-size: 20px;
  display: block;
}

.search_btn a:hover {
  color: #f743a6;
}

@media only screen and (max-width: 575px) {
  .search_btn a {
    width: 40px;
    height: 38px;
    line-height: 36px;
  }
}

.dropdown_search {
  position: absolute;
  right: 0;
  opacity: 0;
  visibility: hidden;
  top: 120%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dropdown_search {
    right: auto;
    left: -18px;
  }
}

@media only screen and (max-width: 767px) {
  .dropdown_search {
    right: auto;
    left: -82px;
  }
}

.dropdown_search form {
  position: relative;
  width: 280px;
  border: 1px solid #ebebeb;
  background: #ffff;
  z-index: 9;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dropdown_search form {
    width: 240px;
  }
}

@media only screen and (max-width: 767px) {
  .dropdown_search form {
    width: 240px;
    z-index: 99;
  }
}

.dropdown_search form input {
  border: 0;
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0 67px 0 10px;
  background: inherit;
  color: #a4a4a4;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dropdown_search form input {
    height: 38px;
    line-height: 38px;
  }
}

@media only screen and (max-width: 767px) {
  .dropdown_search form input {
    height: 38px;
    line-height: 38px;
  }
}

.dropdown_search form button {
  position: absolute;
  top: 0;
  right: 0;
  ackground: #ebebeb;
  width: 59px;
  height: 100%;
  border: 0;
  border-left: 1px solid #ddd;
  padding: 0;
  font-size: 20px;
  background: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dropdown_search form button {
    width: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .dropdown_search form button {
    width: 45px;
  }
}

.cart_link {
  margin-left: 25px;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cart_link {
    margin-left: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .cart_link {
    margin-left: 20px;
  }
}

.cart_link span.cart_quantity {
  position: absolute;
  left: -11px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  background: #f743a6;
  color: #fff;
  border-radius: 50%;
  top: 12px;
}

@media only screen and (max-width: 575px) {
  .cart_link span.cart_quantity {
    width: 18px;
    height: 18px;
    line-height: 18px;
    font-size: 12px;
  }
}

.cart_link>a {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  color: #212121;
  border: 1px solid #ebebeb;
  padding: 11px 10px 13px 18px;
  line-height: 15px;
  border-radius: 3px;
  display: block;
}

.cart_link>a:hover {
  color: #f743a6;
}

@media only screen and (max-width: 575px) {
  .cart_link>a {
    padding: 6px 9px 10px 12px;
  }
}

@media only screen and (max-width: 575px) {
  .cart_link>a span.cart_text_quantity {
    display: none;
  }
}

.cart_link>a i.ion-android-cart {
  font-size: 19px;
  margin-right: 10px;
  border-right: 1px solid #ebebeb;
  position: relative;
  top: 3px;
  padding-right: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cart_link>a i.ion-android-cart {
    font-size: 14px;
    margin-right: 4px;
  }
}

@media only screen and (max-width: 575px) {
  .cart_link>a i.ion-android-cart {
    margin-right: 0;
    padding-right: 0;
    border-right: 0;
  }
}

.cart_link>a i.fa-angle-down {
  font-size: 17px;
  margin-left: 3px;
}

@media only screen and (max-width: 575px) {
  .cart_link>a i.fa-angle-down {
    display: none;
  }
}

.cart_close {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 10px;
}

.cart_text h3 {
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 0;
}

.mini_cart_close>a {
  font-size: 25px;
}

.mini_cart_close>a:hover {
  color: #f743a6;
}

.mini_cart {
  position: fixed;
  min-width: 355px;
  height: 100%;
  padding: 18px 20px 33px;
  background: #fff;
  z-index: 999;
  right: 0;
  top: 0;
  transition: .5s;
  transform: translateX(100%);
}

.mini_cart.active {
  transform: translateX(0);
  transition: .5s;
}

@media only screen and (max-width: 767px) {
  .mini_cart {
    min-width: 300px;
    height: 100%;
    padding: 18px 18px 33px;
  }
}

.mini_cart_footer {
  padding: 20px 18px;
  background: #242424;
}

.cart_img {
  width: 100px;
  margin-right: 10px;
}

.cart_info {
  width: 58%;
}

.cart_info a {
  font-weight: 400;
  color: #242424;
  text-transform: capitalize;
  font-size: 14px;

}

.cart_info a:hover {
  color: #f743a6;
}

.cart_info span {
  display: block;
}

.cart_info span.price_cart {
  color: #f743a6;
  font-weight: 500;
}

.cart_remove {
  width: 10%;
  text-align: right;
}

.cart_remove a {
  color: #212121;
  font-size: 18px;
}

.cart_remove a:hover {
  color: #f743a6;
}

.cart_item {
  overflow: hidden;
  padding: 20px 0;
  border-bottom: 1px solid #ebebeb;
  display: flex;
}

.cart_total {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}

.cart_total span {
  color: #242424;
  font-size: 14px;
  font-weight: 400;
}

.cart_button a {
  text-transform: uppercase;
  border-radius: 5px;
  font-weight: 400;
  color: #ffffff;
  background: #2e2e2e;
  display: block;
  text-align: center;
  line-height: 20px;
  margin-bottom: 0;
  padding: 13px 0px 11px;
}

.cart_button a:hover {
  background: #f743a6;
}

.cart_button a.active {
  background: #f743a6;
}

.cart_button.view_cart {
  padding-bottom: 15px;
}

/*header middel css end*/
/* 2.4 main menu css here */
.logo_sticky {
  display: none;
  margin-right: 35px;
}

.logo_sticky a img {
  max-width: 120px;
}

.header_bottom {
  border-bottom: 1px solid #ebebeb;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_bottom {
    max-height: 0;
    border-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .header_bottom {
    border-bottom: 0;
  }
}

.header_bottom.sticky-header.sticky .main_menu_inner {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_bottom.sticky-header.sticky .main_menu_inner {
    display: inherit;
  }
}

@media only screen and (max-width: 767px) {
  .header_bottom.sticky-header.sticky .main_menu_inner {
    display: inherit;
  }
}

.header_bottom.sticky-header.sticky .logo_sticky {
  display: block;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_bottom.sticky-header.sticky .logo_sticky {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .header_bottom.sticky-header.sticky .logo_sticky {
    display: none;
  }
}

.main_menu_inner {
  text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main_menu {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .main_menu {
    display: none;
  }
}

.main_menu nav>ul>li {
  display: inline-block;
  position: relative;
}

.main_menu nav>ul>li>a {
  display: block;
  padding: 19px 20px;
  color: #212121;
  font-size: 13px;
  line-height: 26px;
  text-transform: uppercase;
  font-weight: 500;
}

.main_menu nav>ul>li>a i {
  margin-left: 2px;
}

.main_menu nav>ul>li:hover>a {
  color: #f743a6;
}

.main_menu nav>ul>li.active>a {
  color: #f743a6;
}

.main_menu nav>ul>li:first-child a {
  padding-left: 0;
}

.main_menu nav>ul>li:last-child a {
  padding-right: 0;
}

.main_menu nav>ul>li ul.sub_menu {
  position: absolute;
  min-width: 200px;
  padding: 30px 25px;
  background: #fff;
  border: 1px solid #ebebeb;
  -webkit-transform: perspective(600px) rotateX(-90deg);
  transform: perspective(600px) rotateX(-90deg);
  -webkit-transform-origin: 0 0 0;
  transform-origin: 0 0 0;
  left: 0;
  right: auto;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  z-index: 9;
  top: 100%;
  text-align: left;
}

.main_menu nav ul li:hover .sub_menu,
.main_menu nav ul li:hover .mega_menu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: perspective(600px) rotateX(0deg);
  transform: perspective(600px) rotateX(0deg);
}

.main_menu nav ul li ul li.active a,
.main_menu nav ul li ul li a:hover {
  color: #f743a6;
}

.main_menu nav>ul>li>ul>li>a {
  color: #808080;
  font-size: 13px;
  text-transform: capitalize;
  line-height: 18px;
  margin-bottom: 15px;
  display: block;
  font-weight: 400;
}

.main_menu nav>ul>li>ul.sub_menu>li:last-child>a {
  margin-bottom: 0;
}

.main_menu nav>ul>li>ul.mega_menu li ul li:last-child a {
  margin-bottom: 0;
}

.main_menu nav ul li ul.mega_menu {
  position: absolute;
  min-width: 580px;
  padding: 30px 25px 35px;
  background: #fff;
  border: 1px solid #ebebeb;
  -webkit-transform: perspective(600px) rotateX(-90deg);
  transform: perspective(600px) rotateX(-90deg);
  -webkit-transform-origin: 0 0 0;
  transform-origin: 0 0 0;
  left: 0;
  right: auto;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  z-index: 9;
  top: 100%;
  text-align: left;
}

.main_menu nav>ul>li>ul.mega_menu>li {
  width: 33%;
  float: left;
}

.main_menu nav>ul>li>ul.mega_menu>li>a {
  text-transform: uppercase;
  color: #242424;
  font-weight: 500;
  margin-bottom: 30px;
}

.main_menu nav>ul>li>ul.mega_menu>li>a:hover {
  color: #f743a6;
}

.main_menu nav>ul>li>ul.mega_menu li ul li a {
  color: #808080;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 11px;
  display: block;
  text-transform: capitalize;
  font-weight: 400;
}

.main_menu nav>ul>li>ul.mega_menu li ul li a:hover {
  color: #f743a6;
}

.main_menu nav ul li ul.sub_menu.pages {
  left: inherit;
  right: inherit;
}

/*main menu css end*/
/*mobaile menu css here*/
.mean-container .mean-bar {
  background: inherit;
  position: absolute;
  z-index: 9;
  top: 43px;
  left: 0;
  width: 100%;
}

.mean-container .mean-bar::after {
  top: -33px;
  left: 22px;
}

@media only screen and (max-width: 767px) {
  .mean-container .mean-bar::after {
    left: 22px;
    font-size: 15px;
  }
}

.mean-container .mean-nav {
  max-height: 300px;
  overflow: auto;
}

.mean-container .mean-nav ul li a {
  font-weight: 400;
}

.mean-container a.meanmenu-reveal {
  float: left;
  right: 17px;
  top: -34px;
}

@media only screen and (max-width: 767px) {
  .mean-container a.meanmenu-reveal {
    right: 15px;
    top: -34px;
  }
}

.mobile-menu {
  border: 1px solid #ddd;
  height: 45px;
  top: -31px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .mobile-menu {
    top: 25px;
  }
}

/*home two css here*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .search_btn.mobail_none2 {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .search_btn.mobail_none2 {
    display: none;
  }
}

.social_icone {
  text-align: right;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .social_icone {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .social_icone {
    text-align: center !important;
    display: none;
  }
}

.social_icone ul li {
  display: inline-block;
  margin-left: 5px;
}

.social_icone ul li:first-child {
  margin-left: 0;
}

.social_icone ul li a {
  background: #ebebeb;
  color: #aaaaaa;
  display: block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
}

.social_icone ul li a:hover {
  background: #f743a6;
  color: #ffffff;
}

.box_setting {
  position: relative;
}

.box_setting:hover .dropdown_setting {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

.box_setting>a {
  width: 45px;
  height: 45px;
  border-radius: 3px;
  text-align: center;
  line-height: 45px;
  border: 1px solid #ebebeb;
  color: #242424;
  font-size: 25px;
  display: block;
}

.box_setting>a:hover {
  color: #f743a6;
}

@media only screen and (max-width: 575px) {
  .box_setting>a {
    width: 40px;
    height: 41px;
    line-height: 42px;
  }
}

.dropdown_setting {
  position: absolute;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  min-width: 200px;
  text-align: center;
  top: 120%;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
  overflow: hidden;
  left: 0;
  z-index: 9999;
  padding: 0 15px;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
}

.dropdown_setting ul li {
  border-bottom: 1px solid #ebebeb;
  line-height: 36px;
  padding: 5px 0;
}

.dropdown_setting ul li:last-child {
  border-bottom: 0;
}

.dropdown_setting ul li a {
  text-transform: capitalize;
  display: block;
  font-size: 12px;
  color: #242424;
  text-align: center;
}

.header_two .dropdown_language,
.header_two .header_black .dropdown_currency,
.header_black .header_two .dropdown_currency,
.header_two .header_black .dropdown_links,
.header_black .header_two .dropdown_links {
  left: 0;
}

@media only screen and (max-width: 767px) {

  .header_two .dropdown_language,
  .header_two .header_black .dropdown_currency,
  .header_black .header_two .dropdown_currency,
  .header_two .header_black .dropdown_links,
  .header_black .header_two .dropdown_links {
    left: -33px;
  }
}

.header_two .dropdown_currency {
  left: 0;
}

@media only screen and (max-width: 767px) {
  .header_two .dropdown_currency {
    left: -33px;
  }
}

.header_two .dropdown_setting {
  right: 0;
  left: auto;
}

@media only screen and (max-width: 767px) {
  .header_two .dropdown_setting {
    right: -72px;
  }
}

@media only screen and (max-width: 767px) {
  .header_two .header_top {
    padding: 12px 0 17px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_two .top_right {
    text-align: left !important;
  }
}

@media only screen and (max-width: 767px) {
  .header_two .top_right {
    margin-bottom: 10px;
  }
}

.header_two .logo {
  padding: 0 40px 0 0;
  position: relative;
  top: -6px;
}

@media only screen and (max-width: 767px) {
  .header_two .logo {
    top: 0;
  }
}

@media only screen and (max-width: 479px) {
  .header_two .logo {
    padding: 0;
  }
}

.header_two .header_middel {
  padding: 20px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_two .header_middel {
    padding: 30px 0 28px;
  }
}

@media only screen and (max-width: 767px) {
  .header_two .header_middel {
    padding: 20px 0 23px;
  }
}

.header_two .cart_link {
  margin-left: 20px;
}

.header_two .search_btn {
  margin-right: 15px;
}

@media only screen and (max-width: 479px) {
  .header_two .search_btn {
    margin-right: 0;
  }
}

.header_two .search_btn form {
  width: 265px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .header_two .search_btn form {
    width: 183px;
  }
}

@media only screen and (max-width: 479px) {
  .header_two .search_btn form {
    width: 265px;
  }
}

.header_two .search_btn form input {
  width: 100%;
  height: 45px;
  line-height: 45px;
  color: #a4a4a4;
  padding: 0 35px 0 14px;
  border: 1px solid #ebebeb;
  font-size: 12px;
}

.header_two .search_btn form button {
  position: absolute;
  right: 0;
  top: 0;
  color: #242424;
  border: 0;
  background: inherit;
  font-size: 20px;
  height: 100%;
  width: 35px;
}

.header_two .search_btn form button:hover {
  color: #f743a6;
}

/*home three css here*/
.middel_right.middle_mobail3 {
  display: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .middel_right.middle_mobail3 {
    display: flex;
  }
}

@media only screen and (max-width: 767px) {
  .middel_right.middle_mobail3 {
    display: flex;
  }
}

.home_three_body_wrapper {
  padding-left: 300px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .home_three_body_wrapper {
    padding-left: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home_three_body_wrapper {
    padding-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home_three_body_wrapper {
    padding-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .home_three_body_wrapper {
    padding-left: 0;
  }
}

.header_sidebar_area {
  position: fixed;
  z-index: 99;
  background: #fff;
  height: 100vh;
  border-right: 1px solid #ebebeb;
  width: 285px;
  padding: 30px 15px 30px 15px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .header_sidebar_area {
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_sidebar_area {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_sidebar_area {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .header_sidebar_area {
    display: none;
  }
}

.header_sidebar_area .social_icone {
  margin-bottom: 12px;
  padding-left: 20px;
}

.sidebar_logo {
  padding: 50px 0 100px 10px;
}

.sidebar_logo a img {
  width: 125px;
}

.header_sidebar_menu .main_menu nav>ul>li {
  display: block;
  margin-bottom: 16px;
}

.header_sidebar_menu .main_menu nav>ul>li:first-child>a {
  padding: 5px 20px;
}

.header_sidebar_menu .main_menu nav>ul>li>a {
  padding: 4px 20px;
  text-transform: uppercase;
  font-size: 13px;
}

.header_sidebar_menu .main_menu nav>ul>li>a i {
  float: right;
}

.header_sidebar_menu .main_menu nav>ul>li ul.sub_menu {
  left: 100%;
  top: 0;
}

.header_sidebar_menu .main_menu nav>ul>li ul.mega_menu {
  left: 100%;
  top: 0;
}

.sidebar_newsletter {
  padding-left: 20px;
  padding-top: 60px;
  margin-bottom: 35px;
}

.sidebar_newsletter p {
  color: #5a5a5a;
  font-size: 12px;
  line-height: 26px;
}

.sidebar_form {
  padding-left: 20px;
  margin-bottom: 33px;
}

.sidebar_form form {
  position: relative;
}

.sidebar_form form input {
  border: 0;
  border-bottom: 1px solid #ebebeb;
  color: #5a5a5a;
  font-size: 12px;
  font-weight: 400;
  height: 30px;
  line-height: 30px;
  width: 100%;
  padding: 0 35px 0 10px;
  background: inherit;
}

.sidebar_form form button {
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: 0;
  height: 100%;
  font-size: 18px;
}

.sidebar_form form button:hover {
  color: #f743a6;
}

.sidebar_footer {
  padding-left: 20px;
}

.sidebar_footer p {
  font-size: 12px;
  line-height: 26px;
}

.sidebar_footer p a {
  color: #f743a6;
  text-decoration: underline;
}

.sidebar_footer p i {
  color: red;
}

.header_three .sticky-header.sticky {
  position: inherit;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
  -webkit-animation-name: inherit;
  animation-name: inherit;
  padding: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .header_three .sticky-header.sticky {
    position: fixed;
    background: rgba(255, 255, 255, 0.9);
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.11);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.11);
    animation-name: fadeInDown;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_three .sticky-header.sticky {
    position: fixed;
    background: rgba(255, 255, 255, 0.9);
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.11);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.11);
    animation-name: fadeInDown;
  }
}

.header_three .header_top {
  padding: 0;
}

@media only screen and (max-width: 767px) {
  .header_three .header_top {
    padding: 12px 0 16px;
  }
}

@media only screen and (max-width: 479px) {
  .header_three .header_top {
    padding: 13px 0 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_three .top_right {
    text-align: left !important;
  }
}

@media only screen and (max-width: 767px) {
  .header_three .top_right {
    margin-bottom: 6px;
  }
}

@media only screen and (max-width: 479px) {
  .header_three .top_right {
    margin-bottom: 12px;
  }
}

.header_three .logo {
  padding: 0;
}

.header_three .logo a img {
  width: 130px;
}

.header_three .header_middel {
  padding: 19px 0;
  display: none;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .header_three .header_middel {
    display: block;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_three .header_middel {
    display: block;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_three .header_middel {
    display: block;
    padding: 27px 0 28px;
  }
}

@media only screen and (max-width: 767px) {
  .header_three .header_middel {
    display: block;
    padding: 20px 0 23px;
  }
}

.header_three .header_bottom {
  border-bottom: 0;
  display: none;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .header_three .header_bottom {
    display: block;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_three .header_bottom {
    display: block;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_three .header_bottom {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .header_three .header_bottom {
    display: block;
  }
}

.header_three .header_bottom.sticky-header.sticky .logo_sticky {
  display: none;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .header_three .header_bottom.sticky-header.sticky .logo_sticky {
    display: block;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_three .header_bottom.sticky-header.sticky .logo_sticky {
    display: block;
  }
}

.header_three .cart_link>a {
  border: 0;
  padding: 14px 10px 16px 18px;
}

@media only screen and (max-width: 479px) {
  .header_three .cart_link>a {
    display: inline-block;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_three .cart_link>a {
    border: 1px solid #ebebeb;
  }
}

@media only screen and (max-width: 767px) {
  .header_three .cart_link>a {
    border: 1px solid #ebebeb;
  }
}

@media only screen and (max-width: 575px) {
  .header_three .cart_link>a {
    padding: 9px 10px 13px 15px;
  }
}

.header_three .box_setting>a {
  border: 0;
  font-size: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_three .box_setting>a {
    border: 1px solid #ebebeb;
  }
}

@media only screen and (max-width: 767px) {
  .header_three .box_setting>a {
    border: 1px solid #ebebeb;
  }
}

@media only screen and (max-width: 479px) {
  .header_three .dropdown_setting {
    right: auto;
    left: -30px !important;
  }
}

@media only screen and (max-width: 479px) {
  .header_three .search_btn {
    margin-bottom: 22px;
  }
}

.header_three .search_btn form {
  width: 295px;
  position: relative;
  border-left: 1px solid #ebebeb;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_three .search_btn form {
    width: 235px;
  }
}

@media only screen and (max-width: 767px) {
  .header_three .search_btn form {
    border-left: 0;
    border-right: 0;
    width: 210px;
  }
}

@media only screen and (max-width: 479px) {
  .header_three .search_btn form {
    width: 270px;
  }
}

.header_three .search_btn form input {
  width: 100%;
  height: 50px;
  border: 0;
  color: #a4a4a4;
  background: inherit;
  padding: 0 60px 0 30px;
  font-size: 13px;
}

@media only screen and (max-width: 767px) {
  .header_three .search_btn form input {
    border: 1px solid #ebebeb;
    height: 40px;
    padding: 0 52px 0 20px;
  }
}

.header_three .search_btn form button {
  position: absolute;
  right: 12px;
  top: 0;
  height: 100%;
  border: 0;
  background: inherit;
  font-size: 20px;
  width: 55px;
}

.header_three .search_btn form button:hover {
  color: #f743a6;
}

@media only screen and (max-width: 767px) {
  .header_three .search_btn form button {
    right: 0px;
    width: 50px;
  }
}

.header_three .box_setting {
  border-left: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_three .box_setting {
    border-left: 0;
    border-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .header_three .box_setting {
    border-left: 0;
    border-right: 0;
  }
}

.header_three .box_setting>a {
  width: 50px;
  height: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_three .box_setting>a {
    width: 45px;
    height: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .header_three .box_setting>a {
    width: 45px;
    height: 45px;
  }
}

@media only screen and (max-width: 575px) {
  .header_three .box_setting>a {
    width: 40px;
    height: 41px;
  }
}

.header_three .dropdown_setting {
  right: 0;
  left: auto;
  min-width: 160px;
}

.header_three .dropdown_language,
.header_three .header_black .dropdown_currency,
.header_black .header_three .dropdown_currency,
.header_three .header_black .dropdown_links,
.header_black .header_three .dropdown_links {
  left: 0;
}

@media only screen and (max-width: 767px) {

  .header_three .dropdown_language,
  .header_three .header_black .dropdown_currency,
  .header_black .header_three .dropdown_currency,
  .header_three .header_black .dropdown_links,
  .header_black .header_three .dropdown_links {
    left: -45px;
  }
}

.header_three .dropdown_currency {
  left: 0;
}

@media only screen and (max-width: 767px) {
  .header_three .dropdown_currency {
    left: -45px;
  }
}

@media only screen and (max-width: 767px) {
  .offcanvas_three .canvas_open {
    top: 31px;
  }
}

@media only screen and (max-width: 575px) {
  .offcanvas_three .canvas_open {
    top: 27px;
  }
}

/*home five css here*/
.home_black_version {
  background: #242424;
}

.header_black .sticky-header.sticky {
  background: rgba(54, 63, 77, 0.9);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.11);
}

@media only screen and (max-width: 767px) {
  .header_black .sticky-header.sticky {
    background: inherit;
    box-shadow: inherit;
  }
}

.header_black .header_top {
  border-bottom: 1px solid #2d2d2d;
}

@media only screen and (max-width: 767px) {
  .header_black .header_top {
    padding: 16px 0 10px;
  }
}

.header_black .header_middel {
  border-bottom: 1px solid #2d2d2d;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_black .header_middel {
    border-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .header_black .header_middel {
    border-bottom: 0;
  }
}

.header_black .header_bottom {
  border-bottom: 1px solid #2d2d2d;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_black .header_bottom {
    border-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .header_black .header_bottom {
    border-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .header_black .social_icone {
    margin-bottom: 10px;
  }
}

.header_black .social_icone ul li a {
  background: #2f2f2f;
  color: #ccc;
}

.header_black .social_icone ul li a:hover {
  background: #a8741a;
}

.header_black .top_right>ul>li::before {
  background: #2d2d2d;
}

.header_black .top_right>ul>li:hover>a {
  color: #a8741a;
}

.header_black .top_right>ul>li>a {
  color: #ffffff;
}

.header_black .top_right>ul>li>a:not([href]):not([tabindex]) {
  color: #ffffff;
}

.header_black .top_right>ul>li>a:not([href]):not([tabindex]):hover {
  color: #a8741a;
}

.header_black .dropdown_language,
.header_black .dropdown_currency,
.header_black .dropdown_links {
  background: #242424;
}

.header_black .dropdown_language li,
.header_black .dropdown_currency li,
.header_black .dropdown_links li {
  border-bottom: 1px solid #2d2d2d;
}

.header_black .dropdown_language li:last-child,
.header_black .dropdown_currency li:last-child,
.header_black .dropdown_links li:last-child {
  border-bottom: 0;
}

.header_black .dropdown_language li a,
.header_black .dropdown_currency li a,
.header_black .dropdown_links li a {
  color: #ffffff;
}

.header_black .dropdown_language li a:hover,
.header_black .dropdown_currency li a:hover,
.header_black .dropdown_links li a:hover {
  color: #a8741a;
}

.header_black .dropdown_links {
  left: -98%;
}

@media only screen and (max-width: 767px) {
  .header_black .dropdown_links {
    left: auto;
    right: 0;
  }
}

.header_black .home_contact {
  align-items: center;
}

.header_black .contact_box {
  margin-left: 15px;
}

.header_black .contact_box p {
  color: #a8741a;
  font-size: 14px;
}

.header_black .search_btn a {
  border: 1px solid #2d2d2d;
  color: #ffffff;
}

.header_black .search_btn a:hover {
  color: #a8741a;
}

.header_black .wishlist_btn {
  margin-left: 18px;
}

@media only screen and (max-width: 767px) {
  .header_black .wishlist_btn {
    display: none;
  }
}

.header_black .wishlist_btn a {
  width: 45px;
  height: 45px;
  border-radius: 3px;
  text-align: center;
  line-height: 45px;
  font-size: 20px;
  display: block;
  border: 1px solid #2d2d2d;
  color: #ffffff;
}

.header_black .wishlist_btn a:hover {
  color: #a8741a;
}

.header_black .cart_close {
  border-bottom: 1px solid #2d2d2d;
}

.header_black .cart_text h3 {
  color: #fff;
}

.header_black .mini_cart_close>a {
  color: #fff;
}

.header_black .mini_cart_close>a:hover {
  color: #a8741a;
}

.header_black .cart_button a.active {
  background: #a8741a;
}

.header_black .cart_link span.cart_quantity {
  background: #a8741a;
}

.header_black .cart_link>a {
  color: #ffffff;
  border: 1px solid #2d2d2d;
}

.header_black .cart_link>a:hover {
  color: #a8741a;
}

.header_black .cart_link>a i.ion-android-cart {
  border-right: 1px solid #2d2d2d;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_black .cart_link>a i.ion-android-cart {
    padding-right: 0;
    margin-right: 0;
    border: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_black .cart_link>a i.fa-angle-down {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_black .cart_link a span.cart_text_quantity {
    display: none;
  }
}

.header_black .dropdown_search form {
  background: #242424;
  border: 1px solid #2d2d2d;
}

.header_black .dropdown_search form input {
  font-size: 12px;
}

.header_black .dropdown_search form button {
  border-left: 1px solid #2d2d2d;
  color: #ffffff;
}

.header_black .dropdown_search form button:hover {
  color: #a8741a;
}

.header_black .mini_cart {
  background: #242424;
  border: 1px solid #2d2d2d;
}

@media only screen and (max-width: 767px) {
  .header_black .mini_cart {
    right: -4px;
  }
}

.header_black .cart_info a {
  color: #ffffff;

}

.header_black .cart_info a:hover {
  color: #a8741a;
}

.header_black .cart_info span.quantity {
  color: #a0a0a0;
}

.header_black .cart_total span {
  color: #ffffff;
}

.header_black .cart_button a:hover {
  background: #a8741a;
}

.header_black .mini_cart_footer {
  padding: 0 25px 25px;
}

.header_black .cart_item {
  border-bottom: 1px solid #2d2d2d;
}

.header_black .main_menu nav>ul>li.active>a {
  color: #a8741a;
}

.header_black .main_menu nav>ul>li:hover>a {
  color: #a8741a;
}

.header_black .main_menu nav>ul>li>a {
  color: #ffffff;

  font-weight: 400;
  text-transform: capitalize;
  font-size: 18px;
}

.header_black .main_menu nav>ul>li ul.sub_menu {
  background: #242424;
  border: 1px solid #2d2d2d;
}

.header_black .main_menu nav>ul>li ul.sub_menu li a {
  color: #ffffff;

}

.header_black .main_menu nav>ul>li ul.sub_menu li a:hover {
  color: #a8741a;
}

.header_black .main_menu nav>ul>li ul.mega_menu {
  background: #242424;
  border: 1px solid #2d2d2d;
}

.header_black .main_menu nav>ul>li ul.mega_menu>li>a {
  color: #ffffff;

}

.header_black .main_menu nav>ul>li ul.mega_menu>li>a:hover {
  color: #a8741a;
}

.header_black .main_menu nav>ul>li ul.mega_menu>li ul li a {
  color: #ffffff;
}

.header_black .main_menu nav>ul>li ul.mega_menu>li ul li a:hover {
  color: #a8741a;
}

.header_black .mean-container .mean-bar::after {
  color: #ffffff;
}

.header_black .mean-container a.meanmenu-reveal {
  color: #ffffff;
}

.header_black .mean-container a.meanmenu-reveal span {
  background: #ffffff;
}

.header_black .mobile-menu {
  border: 1px solid #2d2d2d;
}

/*home six css here*/
.header_black .search_btn form {
  position: relative;
  width: 270px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_black .search_btn form {
    width: 200px;
  }
}

.header_black .search_btn form input {
  width: 100%;
  height: 45px;
  background: inherit;
  border: 1px solid #2d2d2d;
  color: #a4a4a4;
  font-size: 12px;
  padding: 0 48px 0 15px;
  border-radius: 3px;
}

.header_black .search_btn form button {
  position: absolute;
  top: 0;
  right: 10px;
  border: 0;
  background: inherit;
  color: #ffffff;
  font-size: 20px;
  height: 100%;
}

.header_black .search_btn form button:hover {
  color: #a8741a;
}

.header_black .box_setting {
  margin-left: 18px;
}

@media only screen and (max-width: 767px) {
  .header_black .box_setting {
    margin: 0;
  }
}

.header_black .box_setting>a {
  border: 1px solid #2d2d2d;
  color: #ffffff;
}

.header_black .box_setting>a:hover {
  color: #a8741a;
}

.header_black .dropdown_setting {
  right: -40px;
  left: auto;
  min-width: 165px;
  background: #242424;
}

@media only screen and (max-width: 767px) {
  .header_black .dropdown_setting {
    right: auto;
    left: 0;
    min-width: 160px;
  }
}

.header_black .dropdown_setting ul li {
  border-bottom: 1px solid #2d2d2d;
}

.header_black .dropdown_setting ul li:last-child {
  border-bottom: 0;
}

.header_black .dropdown_setting ul li a {
  color: #ffffff;
}

.header_black.black_two .dropdown_language,
.header_black.black_two .dropdown_currency,
.header_black.black_two .dropdown_links {
  left: 0;
}

@media only screen and (max-width: 767px) {

  .header_black.black_two .dropdown_language,
  .header_black.black_two .dropdown_currency,
  .header_black.black_two .dropdown_links {
    left: -40px;
  }
}

@media only screen and (max-width: 767px) {
  .header_black.black_two .dropdown_currency {
    left: -40px;
  }
}

.header_black.black_two .logo {
  padding: 0 40px 0 0;
}

@media only screen and (max-width: 767px) {
  .header_black.black_two .logo {
    padding: 0;
  }
}

.header_black.black_two .header_middel {
  padding: 20px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_black.black_two .header_middel {
    padding: 28px 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .header_black.black_two .middel_right {
    padding: 35px 0 0;
  }
}

@media only screen and (max-width: 575px) {
  .header_black.black_two .middel_right {
    padding: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_black.black_two .top_right {
    text-align: left !important;
  }
}

@media only screen and (max-width: 767px) {
  .header_black.black_two .top_right {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .header_black.black_two .search_btn {
    margin-right: 22px;
  }
}

@media only screen and (max-width: 575px) {
  .header_black.black_two .search_btn {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .header_black.black_two .search_btn form {
    width: 224px;
  }
}

@media only screen and (max-width: 767px) {
  .header_black.black_two .mobile-menu {
    top: 5px;
  }
}

@media only screen and (max-width: 767px) {
  .header_black.black_two .mini_cart {
    right: 0;
  }
}

/*home seven css here*/
.home_black_version.version_3 {
  padding-left: 286px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .home_black_version.version_3 {
    padding-left: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home_black_version.version_3 {
    padding-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home_black_version.version_3 {
    padding-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .home_black_version.version_3 {
    padding-left: 0;
  }
}

.sidebar_black_version {
  background: #242424;
  border-right: 1px solid #2d2d2d;
}

.sidebar_black_version .main_menu nav>ul>li.active>a {
  color: #a8741a;
}

.sidebar_black_version .main_menu nav>ul>li ul.sub_menu {
  background: #242424;
  border: 1px solid #2d2d2d;
}

.sidebar_black_version .main_menu nav>ul>li ul.sub_menu li a {
  color: #ffffff;

}

.sidebar_black_version .main_menu nav>ul>li ul.sub_menu li a:hover {
  color: #a8741a;
}

.sidebar_black_version .main_menu nav>ul>li ul.sub_menu li a {
  color: #ffffff;

}

.sidebar_black_version .main_menu nav>ul>li ul.mega_menu {
  background: #242424;
  border: 1px solid #2d2d2d;
}

.sidebar_black_version .main_menu nav>ul>li ul.mega_menu>li>a {
  color: #ffffff;

  text-transform: capitalize;
}

.sidebar_black_version .main_menu nav>ul>li ul.mega_menu>li>a:hover {
  color: #a8741a;
}

.sidebar_black_version .main_menu nav>ul>li ul.mega_menu>li ul li a {
  color: #ffffff;
}

.sidebar_black_version .main_menu nav>ul>li ul.mega_menu>li ul li a:hover {
  color: #a8741a;
}

.sidebar_black_version .main_menu nav>ul>li:hover>a {
  color: #a8741a;
}

.sidebar_black_version .main_menu nav>ul>li>a {
  color: #ffffff;

  text-transform: capitalize;
  font-size: 18px;
}

.sidebar_black_version .sidebar_logo {
  padding: 37px 0 80px 10px;
}

.sidebar_black_version .sidebar_newsletter h3 {
  color: #ffffff;

  text-transform: capitalize;
  font-size: 18px;
  font-weight: 400;
}

.sidebar_black_version .sidebar_newsletter p {
  color: #a0a0a0;
}

.sidebar_black_version .sidebar_form input {
  border-bottom: 1px solid #2d2d2d;
}

.sidebar_black_version .social_icone ul li a {
  background: #383838;
  color: #ccc;
}

.sidebar_black_version .social_icone ul li a:hover {
  background: #a8741a;
}

.sidebar_black_version .sidebar_footer p {
  color: #a0a0a0;
}

.sidebar_black_version .sidebar_footer p a {
  color: #a8741a;
}

/*home seven css here*/
.header_black.black_three .header_top {
  padding: 0;
}

@media only screen and (max-width: 767px) {
  .header_black.black_three .header_top {
    padding: 12px 0 4px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_black.black_three .top_right {
    text-align: left !important;
  }
}

.header_black.black_three .header_middel {
  padding: 20px 0;
  display: none;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .header_black.black_three .header_middel {
    display: block;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_black.black_three .header_middel {
    display: block;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_black.black_three .header_middel {
    display: block;
    padding: 28px 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .header_black.black_three .header_middel {
    display: block;
    padding: 20px 0 23px;
  }
}

.header_black.black_three .header_bottom {
  display: none;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .header_black.black_three .header_bottom {
    display: block;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_black.black_three .header_bottom {
    display: block;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_black.black_three .header_bottom {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .header_black.black_three .header_bottom {
    display: block;
  }
}

.header_black.black_three .logo {
  padding: 0 40px 0 0;
}

@media only screen and (max-width: 767px) {
  .header_black.black_three .logo {
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .header_black.black_three .mini_cart {
    right: -28px;
  }
}

@media only screen and (max-width: 767px) {
  .header_black.black_three .search_btn {
    position: absolute;
    top: 77px;
  }
}

.header_black.black_three .search_btn form {
  border-left: 1px solid #2d2d2d;
  border-right: 1px solid #2d2d2d;
}

@media only screen and (max-width: 767px) {
  .header_black.black_three .search_btn form {
    border: 0;
  }
}

.header_black.black_three .search_btn form input {
  border: 0;
  height: 50px;
}

@media only screen and (max-width: 767px) {
  .header_black.black_three .search_btn form input {
    border: 1px solid #2d2d2d;
    height: 40px;
  }
}

.header_black.black_three .search_btn form button {
  right: 20px;
}

.header_black.black_three .box_setting {
  margin-left: 10px;
}

.header_black.black_three .box_setting>a {
  height: 50px;
  border: 0;
  line-height: 50px;
}

.header_black.black_three .cart_link>a {
  border: 0;
}

.header_black.black_three .dropdown_language,
.header_black.black_three .dropdown_currency,
.header_black.black_three .dropdown_links {
  left: 0;
  right: auto;
}

@media only screen and (max-width: 767px) {
  .header_black.black_three .social_icone {
    margin-bottom: 0;
  }
}

.header_black.black_three .mobile-menu {
  top: 22px;
}

/*search container css here*/
.hover_category {
  position: relative;
}

.hover_category::before {
  content: "";
  width: 1px;
  height: 16px;
  background: #a1a1a1;
  position: absolute;
  top: 50%;
  margin-top: -8px;
  right: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hover_category::before {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .hover_category::before {
    display: none;
  }
}

.hover_category .select_option {
  border: 0;
  background: inherit;
  height: 47px;
  line-height: 47px;
  padding-left: 13px;
  padding-right: 30px;
  font-size: 14px;
  min-width: 170px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hover_category .select_option {
    min-width: 150px;
    padding-right: 28px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hover_category .select_option {
    height: 34px;
    line-height: 35px;
    font-size: 13px;
    padding-left: 50px;
    padding-right: 51px;
    border: 1px solid #ebebeb;
  }
}

@media only screen and (max-width: 767px) {
  .hover_category .select_option {
    height: 34px;
    line-height: 35px;
    font-size: 13px;
    padding-left: 50px;
    padding-right: 51px;
    border: 1px solid #ebebeb;
  }
}

.hover_category .select_option::after {
  top: 54%;
  right: 13px;
}

.hover_category .select_option ul.list {
  max-height: 300px;
  overflow: auto;
}

.search_container_new {
  margin-right: 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .search_container_new {
    margin-right: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .search_container_new {
    margin-bottom: 28px;
    display: none;
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .search_container_new {
    margin-bottom: 28px;
    margin-right: 0;
    display: none;
  }
}

.search_container_new form {
  display: flex;
  border-radius: 5px;
  border: 2px solid #ebebeb;
  background: #fff;
  width: 600px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .search_container_new form {
    width: 481px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .search_container_new form {
    flex-direction: column-reverse;
    border: 0;
    align-items: center;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .search_container_new form {
    flex-direction: column-reverse;
    border: 0;
    align-items: center;
    width: 100%;
  }
}

.search_container_new .search_box {
  position: relative;
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .search_container_new .search_box {
    border: 1px solid #ebebeb;
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .search_container_new .search_box {
    border: 1px solid #ebebeb;
    margin-bottom: 25px;
  }
}

.search_container_new .search_box input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #5a5a5a;
}

.search_container_new .search_box input::-moz-placeholder {
  /* Firefox 19+ */
  color: #5a5a5a;
}

.search_container_new .search_box input:-ms-input-placeholder {
  /* IE 10+ */
  color: #5a5a5a;
}

.search_container_new .search_box input:-moz-placeholder {
  /* Firefox 18- */
  color: #5a5a5a;
}

.search_container_new .search_box input {
  border: 0;
  background: inherit;
  width: 100%;
  height: 46px;
  color: #5a5a5a;
  font-size: 14px;
  font-weight: 400;
  padding: 0 132px 0 20px;
  opacity: 0.7;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .search_container_new .search_box input {
    font-size: 12px;
    padding: 0 92px 0 10px;
    width: 100%;
    height: 34px;
  }
}

@media only screen and (max-width: 767px) {
  .search_container_new .search_box input {
    font-size: 12px;
    padding: 0 92px 0 10px;
    width: 100%;
    height: 34px;
  }
}

.search_container_new .search_box button {
  border: 0;
  position: absolute;
  top: -2px;
  bottom: -2px;
  right: -2px;
  background: #f743a6;
  color: #fff;
  font-weight: 400;
  padding: 0 20px;
  min-width: 120px;
  border-radius: 0 5px 5px 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.search_container_new .search_box button:hover {
  background: #5a5a5a;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .search_container_new .search_box button {
    padding: 0 17px;
    min-width: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .search_container_new .search_box button {
    min-width: 78px;
    padding: 0 14px;
    top: -1px;
    bottom: -1px;
    right: -1px;
  }
}

@media only screen and (max-width: 767px) {
  .search_container_new .search_box button {
    min-width: 78px;
    padding: 0 14px;
    top: -1px;
    bottom: -1px;
    right: -1px;
  }
}

.middel_rightinfo_new {
  display: flex;
  align-items: center;
  margin-top: 7px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .middel_rightinfo_new {
    margin-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .middel_rightinfo_new {
    margin-top: 0;
  }
}

/*search container css end*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_bottom9 {
    max-height: inherit;
  }
}

/*-- 2.3 Categories menu css here --*/
.categories_menu {
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_menu {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .categories_menu {
    margin-bottom: 25px;
  }
}

.categories_title {
  background: #f743a6;
  padding: 0 30px;
  position: relative;
  cursor: pointer;
  height: 56px;
  line-height: 56px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_title {
    height: 45px;
    line-height: 46px;
  }
}

@media only screen and (max-width: 767px) {
  .categories_title {
    height: 43px;
    line-height: 43px;
  }
}

.categories_title::before {
  content: "\f394";
  color: #fff;
  display: inline-block;

  position: absolute;
  font-size: 22px;
  line-height: 0px;
  right: 20px;
  top: 50%;
  transform: translatey(-50%);
}

.categories_title h2 {
  font-size: 15px;
  font-weight: 600;
  line-height: 26px;
  color: #fff;
  cursor: pointer;
  margin-bottom: 0;
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .categories_title h2 {
    font-size: 13px;
    line-height: 22px;
  }
}

.categories_menu_toggle {
  padding: 15px 0 9px;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background: #fff;
  position: absolute;
  width: 100%;
  top: 100%;
  display: none;
  z-index: 9;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_menu_toggle {
    display: none;
    max-height: 350px;
    overflow: auto;
  }
}

@media only screen and (max-width: 767px) {
  .categories_menu_toggle {
    display: none;
    max-height: 350px;
    overflow: auto;
    padding: 0 11px 0 20px;
  }
}

.categories_menu_toggle>ul>li {
  position: relative;
}

.categories_menu_toggle>ul>li>a {
  font-size: 14px;
  line-height: 43px;
  text-transform: capitalize;
  font-weight: 400;
  display: block;
  cursor: pointer;
  padding: 0 20px 0 30px;
  color: #212121;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .categories_menu_toggle>ul>li>a {
    line-height: 38px;
  }
}

@media only screen and (max-width: 767px) {
  .categories_menu_toggle>ul>li>a {
    line-height: 35px;
    padding: 0;
  }
}

.categories_menu_toggle>ul>li>a i.fa-angle-right {
  float: right;
  font-size: 15px;
  line-height: 35px;
  transition: .3s;
  -webkit-transition: .3s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_menu_toggle>ul>li>a i.fa-angle-right {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .categories_menu_toggle>ul>li>a i.fa-angle-right {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_menu_toggle>ul>li>a:hover {
    color: #f743a6;
  }
}

@media only screen and (max-width: 767px) {
  .categories_menu_toggle>ul>li>a:hover {
    color: #f743a6;
  }
}

.categories_menu_toggle>ul>li:last-child>a {
  border-bottom: 0;
}

.categories_menu_toggle>ul>li:hover ul.categories_mega_menu {
  opacity: 1;
  visibility: visible;
  left: 100%;
}

.categories_menu_toggle>ul>li:hover>a {
  color: #f743a6;
}

.categories_menu_toggle>ul>li ul.categories_mega_menu {
  position: absolute;
  left: 120%;
  width: 720px;
  -webkit-box-shadow: 1px 0 6px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 1px 0 6px 0 rgba(0, 0, 0, 0.3);
  background: #fff;
  padding: 25px 20px 23px;
  overflow: hidden;
  top: 0;
  -webkit-transition: .3s;
  transition: .3s;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .categories_menu_toggle>ul>li ul.categories_mega_menu {
    width: 680px;
  }
}

@media only screen and (max-width: 767px) {
  .categories_menu_toggle>ul>li ul.categories_mega_menu {
    display: none;
    opacity: inherit !important;
    visibility: inherit !important;
    left: inherit !important;
    width: 100%;
    top: 100%;
    max-height: 350px;
    overflow: auto;
    transition: unset;
    box-shadow: inherit;
    position: inherit;
    padding: 6px 20px 0px 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_menu_toggle>ul>li ul.categories_mega_menu {
    display: none;
    opacity: inherit !important;
    visibility: inherit !important;
    left: inherit !important;
    width: 100%;
    top: 100%;
    max-height: 350px;
    overflow: auto;
    transition: unset;
    box-shadow: inherit;
    position: inherit;
    padding: 6px 20px 0px 40px;
  }
}

.categories_menu_toggle>ul>li ul.categories_mega_menu.open {
  display: block;
  left: 0;
}

.categories_menu_toggle>ul>li ul.categories_mega_menu>li {
  padding: 0 15px;
  width: 25%;
  float: left;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_menu_toggle>ul>li ul.categories_mega_menu>li {
    width: 100%;
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .categories_menu_toggle>ul>li ul.categories_mega_menu>li {
    width: 100%;
    padding: 0;
  }
}

.categories_menu_toggle>ul>li ul.categories_mega_menu>li>a {
  line-height: 30px;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  display: block;
  margin-bottom: 10px;
  color: #212121;
}

.categories_menu_toggle>ul>li ul.categories_mega_menu>li>a:hover {
  color: #f743a6;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_menu_toggle>ul>li ul.categories_mega_menu>li.menu_item_children>a {
    text-transform: capitalize;
    font-weight: 400;
  }
}

@media only screen and (max-width: 767px) {
  .categories_menu_toggle>ul>li ul.categories_mega_menu>li.menu_item_children>a {
    text-transform: capitalize;
    font-weight: 400;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_menu_toggle ul li.menu_item_children>a {
    position: relative;
  }

  .categories_menu_toggle ul li.menu_item_children>a::before {
    position: absolute;
    content: "+";
    right: 15px;
    top: 50%;
    transform: translatey(-50%);
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .categories_menu_toggle ul li.menu_item_children>a {
    position: relative;
  }

  .categories_menu_toggle ul li.menu_item_children>a::before {
    position: absolute;
    content: "+";
    right: 15px;
    top: 50%;
    transform: translatey(-50%);
    font-size: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_menu_toggle ul li.menu_item_children.open>a::before {
    display: none;
  }

  .categories_menu_toggle ul li.menu_item_children.open>a::after {
    position: absolute;
    content: "-";
    right: 15px;
    top: 50%;
    transform: translatey(-50%);
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .categories_menu_toggle ul li.menu_item_children.open>a::before {
    display: none;
  }

  .categories_menu_toggle ul li.menu_item_children.open>a::after {
    position: absolute;
    content: "-";
    right: 15px;
    top: 50%;
    transform: translatey(-50%);
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categorie_sub_menu {
    display: none;
    padding: 1px 0 9px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .categorie_sub_menu {
    display: none;
    padding: 1px 0 9px 30px;
  }
}

.categorie_sub_menu li a {
  color: #212121;
  text-transform: capitalize;
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
}

.categorie_sub_menu li a:hover {
  color: #f743a6;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categorie_sub_menu li a {
    line-height: 33px;
  }
}

@media only screen and (max-width: 767px) {
  .categorie_sub_menu li a {
    line-height: 33px;
  }
}

.categories_menu_toggle ul li ul.categories_mega_menu.column_3 {
  width: 620px;
}

@media only screen and (max-width: 767px) {
  .categories_menu_toggle ul li ul.categories_mega_menu.column_3 {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_menu_toggle ul li ul.categories_mega_menu.column_3 {
    width: 100%;
  }
}

.categories_menu_toggle ul li ul.categories_mega_menu.column_3>li {
  width: 33.33%;
}

@media only screen and (max-width: 767px) {
  .categories_menu_toggle ul li ul.categories_mega_menu.column_3>li {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_menu_toggle ul li ul.categories_mega_menu.column_3>li {
    width: 100%;
  }
}

.categories_menu_toggle ul li ul.categories_mega_menu.column_2 {
  width: 500px;
}

@media only screen and (max-width: 767px) {
  .categories_menu_toggle ul li ul.categories_mega_menu.column_2 {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_menu_toggle ul li ul.categories_mega_menu.column_2 {
    width: 100%;
  }
}

.categories_menu_toggle ul li ul.categories_mega_menu.column_2>li {
  width: 50%;
}

@media only screen and (max-width: 767px) {
  .categories_menu_toggle ul li ul.categories_mega_menu.column_2>li {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_menu_toggle ul li ul.categories_mega_menu.column_2>li {
    width: 100%;
  }
}

.categorie_sub {
  opacity: 0;
  z-index: 999;
  position: absolute;
  width: 101%;
  left: -1px;
  border: lef;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background: #fff;
  -webkit-transition: .3s;
  transition: .3s;
  top: 65%;
  visibility: hidden;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categorie_sub {
    width: 100%;
    left: 0px;
  }
}

.categorie_sub.open {
  top: 95%;
  opacity: 1;
  visibility: visible;
}

.categories_menu_toggle ul li.has-sub>a::before {
  position: absolute;
  content: "\f055";
  top: 0;
  right: 20px;
  font-size: 18px;
  pointer-events: none;
}

.categories_menu_toggle ul li.has-sub>a.open::before {
  content: "\f056";
}

.categories_menu_toggle ul li.has-sub ul.categorie_sub li a {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 16px;
  padding-left: 30px;
  display: block;
  color: #212121;
}

.categories_menu_toggle ul li.has-sub ul.categorie_sub li a:hover {
  color: #f743a6;
}

.categories_menu_toggle ul li.has-sub {
  padding-bottom: 10px;
}

.categories_menu_toggle ul li ul.categories_mega_menu>li:first-child {
  padding-left: 0;
}

.categories_menu_toggle ul li ul.categories_mega_menu>li:last-child {
  padding-right: 0;
}

.categorie_banner {
  position: absolute;
  bottom: 0;
  right: 20px;
  width: 500px;
}

@media only screen and (max-width: 767px) {
  .categorie_banner {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categorie_banner {
    display: none;
  }
}

.categorie_banner a img:hover {
  opacity: 0.8;
}

.main_menu9_inner {
  text-align: left;
}

.main_menu9_inner .main_menu nav>ul>li>a {
  padding: 15px 20px;
}

.header_top9 .dropdown_currency {
  right: 0;
  left: auto;
}

/*-- 2.7 Categories menu css end --*/
/*home ten css here*/
.header_ten .welcome_text p {
  color: #fff;
}

.header_ten .search_box button {
  background: #a8741a;
}

.categories_ten .categories_title {
  background: #a8741a;
}

.categories_ten .categories_menu_toggle>ul>li:hover>a {
  color: #a8741a;
}

.categories_ten .categories_menu_toggle>ul>li ul.categories_mega_menu>li>a:hover {
  color: #a8741a;
}

.categories_ten .categorie_sub_menu li a:hover {
  color: #a8741a;
}

.offcanvas_ten .canvas_open a {
  color: #fff;
  border: 1px solid #fff;
}

.offcanvas_ten .canvas_open a:hover {
  color: #a8741a;
  border-color: #a8741a;
}

/* 02. slider area css here */
.single_slider {
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
}

@media only screen and (max-width: 767px) {
  .single_slider {
    background-position: 14%;
  }
}

.single_slider .row {
  height: 627px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .single_slider .row {
    height: 450px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single_slider .row {
    height: 400px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single_slider .row {
    height: 380px;
  }
}

@media only screen and (max-width: 767px) {
  .single_slider .row {
    /* height: 400px; */
    position: absolute;
    /* left: 50%; */
    height: 100%;
    width: 100%;

  }
}

.slider_area {
  margin-bottom: 35px;
}

.slider_area.owl-carousel .owl-dots {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
}

.slider_area.owl-carousel .owl-dots .owl-dot {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  display: inline-block;
  background: #e1e1e1;
  margin: 0 5px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  position: relative;
}

.slider_area.owl-carousel .owl-dots .owl-dot.active {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.slider_area.owl-carousel .owl-dots .owl-dot.active::before {
  position: absolute;
  content: "";
  background: #f743a6;
  width: 13px;
  height: 13px;
  left: -3px;
  top: -3px;
  border-radius: 50%;
}

/*slider content css here*/
@media only screen and (max-width: 767px) {
  .slider_content {
    text-align: center;
  }
}

.slider_content p {
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
  color: #242424;
  margin-bottom: 11px;
  text-transform: capitalize;
}

@media only screen and (max-width: 767px) {
  .slider_content p {
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 6px;
  }
}

.slider_content h1 {
  font-size: 60px;
  line-height: 60px;
  font-weight: 700;
  color: #212121;
  text-transform: capitalize;
  margin-bottom: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider_content h1 {
    font-size: 48px;
    line-height: 52px;
    margin-bottom: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider_content h1 {
    font-size: 45px;
    line-height: 45px;
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .slider_content h1 {
    font-size: 24px;
    line-height: 26px;
    margin-bottom: 15px;
  }
}

.slider_content p.slider_price {
  font-size: 18px;
  margin-bottom: 29px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .slider_content p.slider_price {
    font-size: 14px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider_content p.slider_price {
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider_content p.slider_price {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .slider_content p.slider_price {
    font-size: 13px;
  }
}

.slider_content p.slider_price span {
  color: #f743a6;
  font-size: 30px;
  font-weight: 500;
  margin-left: 8px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .slider_content p.slider_price span {
    font-size: 22px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider_content p.slider_price span {
    font-size: 22px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider_content p.slider_price span {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .slider_content p.slider_price span {
    font-size: 15px;
  }
}

.slider_content a {
  font-weight: 500;
  margin-top: 40px;
  color: #fff;
  height: 50px;
  line-height: 50px;
  padding: 0 35px;
}

.slider_content a:hover {
  background: #212121;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider_content a {
    margin-top: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider_content a {
    margin-top: 20px;
    height: 43px;
    line-height: 43px;
    padding: 0 25px;
  }
}

@media only screen and (max-width: 767px) {
  .slider_content a {
    font-weight: 500;
    margin-top: 0;
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
  }
}

.active .slider_content p,
.active .slider_content h1,
.active .slider_content a {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.active .slider_content h1 {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.active .slider_content a {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

/*slider content css end*/
/*home two css here*/
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .home_slider_two .slider_content {
    margin-left: 170px;
    overflow: hidden;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .home_slider_two .slider_content p {
    font-size: 13px;
    margin-bottom: 5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .home_slider_two .slider_content h1 {
    font-size: 42px;
    line-height: 33px;
  }
}

/*home three css here*/
.home_slider_three {
  margin-bottom: 60px;
}

.home_slider_three .single_slider .row {
  height: 900px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .home_slider_three .single_slider .row {
    height: 800px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home_slider_three .single_slider .row {
    height: 500px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home_slider_three .single_slider .row {
    height: 400px;
  }
}

@media only screen and (max-width: 767px) {
  .home_slider_three .single_slider .row {
    height: 350px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .home_slider_three .slider_content h1 {
    font-size: 50px;
  }
}

/*home five css here*/
.slider_black .single_slider .row {
  height: 690px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .slider_black .single_slider .row {
    height: 500px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider_black .single_slider .row {
    height: 450px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider_black .single_slider .row {
    height: 400px;
  }
}

@media only screen and (max-width: 767px) {
  .slider_black .single_slider .row {
    height: 350px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .slider_black .slider_content {
    margin-left: 120px;
    overflow: hidden;
  }
}

.slider_black .slider_content p {
  color: #a8741a;
  font-size: 24px;
  line-height: 22px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .slider_black .slider_content p {
    font-size: 18px;
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider_black .slider_content p {
    font-size: 18px;
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider_black .slider_content p {
    font-size: 18px;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .slider_black .slider_content p {
    font-size: 16px;
    line-height: 20px;
  }
}

.slider_black .slider_content p.slider_price {
  color: #ffffff;
}

.slider_black .slider_content p.slider_price span {
  color: #a8741a;
  margin-bottom: 0;
}

.slider_black .slider_content h1 {
  color: #ffffff;

  font-weight: 400;
  margin-bottom: 10px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .slider_black .slider_content h1 {
    font-size: 40px;
    line-height: 46px;
    margin-bottom: 8px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider_black .slider_content h1 {
    font-size: 40px;
    line-height: 46px;
    margin-bottom: 8px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider_black .slider_content h1 {
    font-size: 34px;
    line-height: 45px;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .slider_black .slider_content h1 {
    font-size: 22px;
  }
}

.slider_black .slider_content span {
  font-size: 24px;
  color: #ccc;
  margin-bottom: 35px;
  display: inline-block;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .slider_black .slider_content span {
    font-size: 18px;
    margin-bottom: 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider_black .slider_content span {
    font-size: 18px;
    margin-bottom: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider_black .slider_content span {
    font-size: 18px;
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .slider_black .slider_content span {
    font-size: 14px;
    margin-bottom: 13px;
  }
}

.slider_black .slider_content a {
  background: #a8741a;
  margin-top: 30px;
}

.slider_black .slider_content a:hover {
  background: #212121;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .slider_black .slider_content a {
    margin-top: 43px;
  }
}

@media only screen and (max-width: 767px) {
  .slider_black .slider_content a {
    margin-top: 0px;
  }
}

.slider_black.owl-carousel .owl-dots .owl-dot {
  background: #242424;
}

.slider_black.owl-carousel .owl-dots .owl-dot.active::before {
  background: #a8741a;
}

.active .slider_content>span {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

/*home six slider css here*/
.slider_black.black_two .slider_content a {
  border: 2px solid #a8741a;
  background: inherit;
  color: #a8741a;
  line-height: 45px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider_black.black_two .slider_content a {
    line-height: 36px;
  }
}

@media only screen and (max-width: 767px) {
  .slider_black.black_two .slider_content a {
    line-height: 36px;
  }
}

.slider_black.black_two .slider_content a:hover {
  background: #a8741a;
  color: #ffffff;
}

/*home seven slider css here*/
.slider_black.black_three {
  margin-bottom: 0;
}

.slider_black.black_three .slider_content a {
  border: 2px solid #a8741a;
  background: inherit;
  color: #a8741a;
  line-height: 47px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider_black.black_three .slider_content a {
    line-height: 38px;
  }
}

@media only screen and (max-width: 767px) {
  .slider_black.black_three .slider_content a {
    line-height: 36px;
  }
}

.slider_black.black_three .slider_content a:hover {
  background: #a8741a;
  color: #ffffff;
}

.slider_black.black_three .single_slider .row {
  height: 900px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .slider_black.black_three .single_slider .row {
    height: 750px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider_black.black_three .single_slider .row {
    height: 500px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider_black.black_three .single_slider .row {
    height: 450px;
  }
}

@media only screen and (max-width: 767px) {
  .slider_black.black_three .single_slider .row {
    height: 350px;
  }
}

.slider_nine {
  margin-bottom: 0;
}

.header_middle9 .dropdown_setting {
  right: 0;
  left: auto;
}

@media only screen and (max-width: 767px) {
  .header_middle9 .dropdown_setting {
    right: -65px;
  }
}

.slider_ten {
  margin-bottom: 0;
}

/*  04. banner section css here  */
.banner_section {
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 70px;
}

@media only screen and (max-width: 767px) {
  .banner_section {
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .single_banner {
    /* margin-bottom: 30px; */
    /* height: 195px !important; */
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single_banner.bottom {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .single_banner.bottom {
    margin-bottom: 0;
  }
}

.banner_thumb {
  position: relative;
  overflow: hidden;
}

.banner_thumb::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  top: 0;
  left: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
  pointer-events: none;
  z-index: 1;
}

.banner_thumb img {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  width: 100%;
}

.banner_thumb:hover::before {
  opacity: 1;
}

.banner_thumb:hover img {
  transform: scale(1.1);
}

.banner_content {
  position: absolute;
  top: 25px;
  left: 25px;
}

.banner_content p {
  color: #7d7d7d;
  margin-bottom: 0;
}

.banner_content h2 {
  color: #242424;
  font-size: 24px;
  font-weight: 700;
  line-height: 27px;
  margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
  .banner_content h2 {
    font-size: 20px;
    line-height: 23px;
  }
}

.banner_content span {
  color: #242424;
}

.messages_info {
  text-align: center;
  padding: 35px 0 32px;
}

.messages_info p {
  color: #242424;
}

.messages_info p a {
  color: #f743a6;
}

/* .banner_fullwidth {
  background: url(../img/bg/banner4.jpg) no-repeat scroll center center/cover;
  margin-bottom: 64px;
} */
@media only screen and (max-width: 767px) {
  .banner_fullwidth {
    margin-bottom: 55px;
  }
}

.banner_fullwidth .row {
  height: 510px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner_fullwidth .row {
    height: 400px;
  }
}

@media only screen and (max-width: 767px) {
  .banner_fullwidth .row {
    height: 310px;
  }
}

.banner_text {
  text-align: center;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.banner_text:hover {
  transform: scale(1.05);
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .banner_text {
    width: 90%;
    margin: 0 auto;
  }
}

.banner_text P {
  font-size: 16px;
  margin: 0;
  color: #f743a6;
}

@media only screen and (max-width: 767px) {
  .banner_text P {
    font-size: 15px;
  }
}

.banner_text h2 {
  font-size: 50px;
  text-transform: capitalize;
  margin-bottom: 10px;
  color: #242424;
  font-weight: 700;
  line-height: 65px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner_text h2 {
    font-size: 38px;
    line-height: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .banner_text h2 {
    font-size: 22px;
    line-height: 42px;
  }
}

.banner_text>span {
  font-size: 16px;
  margin: 0;
  display: block;
  color: #5a5a5a;
}

@media only screen and (max-width: 767px) {
  .banner_text>span {
    font-size: 15px;
  }
}

.banner_text a {
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  color: #f743a6;
  border: 2px solid #f743a6;
  padding: 0 45px;
  border-radius: 3px;
  margin-top: 70px;
  line-height: 46px;
  display: inline-block;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner_text a {
    padding: 0 40px;
    margin-top: 50px;
    line-height: 44px;
  }
}

.banner_text a:hover {
  background: #f743a6;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .banner_text a {
    padding: 0 17px;
    margin-top: 20px;
    line-height: 34px;
    font-size: 12px;
  }
}

/*home two css here*/
.home_banner_two {
  border-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home_banner_two {
    margin-bottom: 63px;
  }
}

@media only screen and (max-width: 767px) {
  .home_banner_two {
    margin-bottom: 23px;
  }
}

@media only screen and (max-width: 767px) {
  .home_banner_two .banner_content {
    top: 8px;
  }
}

@media only screen and (max-width: 767px) {
  .home_banner_two .banner_content h2 {
    font-size: 18px;
    margin-bottom: 6px;
  }
}

/*hpme four css here*/
.banner_section_four {
  background: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .banner_section_four {
    padding: 0 28px;
  }
}

.banner_section_four .single_banner {
  cursor: pointer;
  position: relative;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  height: 600px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner_section_four .single_banner {
    background-size: contain !important;
  }
}

@media only screen and (max-width: 767px) {
  .banner_section_four .single_banner {
    background-size: contain !important;
  }
}

.banner_section_four .single_banner::before {
  content: "";
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  z-index: 1;
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.banner_section_four .single_banner:hover::before {
  opacity: 1;
}

.banner_section_four .single_banner:hover .banner_content {
  transform: scale(1.1);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner_section_four .single_banner.bottom {
    margin-top: 0;
  }
}

.banner_section_four .single_two {
  border-left: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
}

@media only screen and (max-width: 767px) {
  .banner_section_four .single_two {
    border-left: 0;
    border-right: 0;
  }
}

.banner_section_four .banner_content {
  bottom: 45px;
  text-align: center;
  top: inherit;
  z-index: 9;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  left: 0;
  right: 0;
}

.banner_section_four .banner_content p {
  color: #f743a6;
  font-size: 16px;
  margin-bottom: 12px;
}

.banner_section_four .banner_content a {
  text-decoration: underline;
  color: #5a5a5a;
  font-weight: 500;
}

.editAddBtn a {
  color: #fff !important;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  width: -webkit-max-content;
  width: max-content;
}

.banner_section_four .banner_content a:hover {
  color: #f743a6;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner_section_four .banner_content h2 {
    font-size: 20px;
  }
}

.messages_four {
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 70px;
}

@media only screen and (max-width: 767px) {
  .messages_four {
    margin-bottom: 60px;
  }
}

/*home five css here*/
.banner_black {
  border-bottom: 1px solid #2d2d2d;
}

.banner_black .banner_content {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  bottom: 25px;
  top: inherit;
}

@media only screen and (max-width: 767px) {
  .banner_black .banner_content {
    bottom: 15px;
  }
}

.banner_black .banner_content p {
  color: #ffffff;
  margin-bottom: 10px;
}

.banner_black .banner_content h2 {
  color: #a8741a;
  font-weight: 400;

}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner_black .banner_content h2 {
    font-size: 21px;
    line-height: 23px;
    margin-bottom: 13px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner_black .banner_content h2 {
    font-size: 21px;
    line-height: 23px;
    margin-bottom: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .banner_black .banner_content h2 {
    font-size: 21px;
    line-height: 23px;
    margin-bottom: 13px;
  }
}

.banner_black .banner_content span {
  color: #ffffff;
}

.banner_black .banner_thumb:hover .banner_content {
  transform: scale(1.1);
}

.banner_black .messages_info p {
  position: relative;
  color: #ffffff;
  display: inline-block;
}

.banner_black .messages_info p::before {
  position: absolute;
  content: "\f243";
  font-size: 18px;
  color: #a8741a;
  left: -26px;
}

@media only screen and (max-width: 767px) {
  .banner_black .messages_info p::before {
    display: none;
  }
}

.banner_black .messages_info p a {
  color: #a8741a;
}

.banner_black.black_two {
  border-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .banner_black.black_two {
    margin-bottom: 24px;
  }
}

/* .black_fullwidth {
  background: url(../img/bg/banner13.jpg);
} */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .black_fullwidth .banner_text {
    width: 90%;
    margin: 0 auto;
  }
}

.black_fullwidth .banner_text:hover {
  transform: scale(1.1);
}

.black_fullwidth .banner_text p {
  color: #a8741a;
}

.black_fullwidth .banner_text h2 {
  color: #ffffff;
  font-weight: 400;
}

.black_fullwidth .banner_text span {
  color: #a0a0a0;
}

.black_fullwidth .banner_text a {
  color: #a8741a;
  border: 2px solid #a8741a;
}

.black_fullwidth .banner_text a:hover {
  color: #ffffff;
  background: #a8741a;
}

.banner_section_five {
  border-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .banner_section_five {
    margin-bottom: 30px;
  }
}

.banner_section_five .row {
  margin-right: 0;
  margin-left: 0;
}

/* .banner_section_five .single_banner.one {
  background: url(../img/bg/banner14.jpg);
  height: 300px;
} */
@media only screen and (max-width: 767px) {
  .banner_section_five .single_banner.one {
    height: 242px;
  }
}

/* .banner_section_five .single_banner.two {
  background: url(../img/bg/banner15.jpg);
  height: 300px;
} */
@media only screen and (max-width: 767px) {
  .banner_section_five .single_banner.two {
    height: 242px;
  }
}

.banner_section_five .banner_text {
  padding: 70px 0px;
  width: 500px;
  margin: 0 auto;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner_section_five .banner_text {
    width: 400px;
  }
}

@media only screen and (max-width: 767px) {
  .banner_section_five .banner_text {
    width: 90%;
    padding: 50px 0px;
  }
}

.banner_section_five .banner_text:hover {
  transform: scale(1.1);
}

.banner_section_five .banner_text p {
  color: #ffffff;
  margin-bottom: 8px;
}

.banner_section_five .banner_text h2 {
  font-size: 30px;
  color: #a8741a;
  font-weight: 400;
  line-height: 44px;
}

@media only screen and (max-width: 767px) {
  .banner_section_five .banner_text h2 {
    font-size: 18px;
    line-height: 23px;
  }
}

.banner_section_five .banner_text span {
  color: #ffffff;
}

.banner_section_five .banner_text a {
  margin-top: 26px;
  line-height: 22px;
  border: 0;
  padding: 0;
  color: #a8741a;
  font-weight: 400;
}

.banner_section_five .banner_text a:hover {
  background: none;
}

/*home right css here*/
.banner_black.black_eight .single_banner {
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  height: 600px;
  position: relative;
  cursor: pointer;
}

.banner_black.black_eight .single_banner::before {
  content: "";
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  pointer-events: none;
}

.banner_black.black_eight .single_banner:hover::before {
  opacity: 1;
}

.banner_black.black_eight .single_banner:hover .banner_content {
  transform: scale(1.05);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner_black.black_eight .single_banner.bottom {
    margin-top: 0;
  }
}

.banner_black.black_eight .single_banner.banner_two {
  border-left: 1px solid #2d2d2d;
  border-right: 1px solid #2d2d2d;
}

.banner_black.black_eight .banner_content {
  text-align: center;
  left: 0;
  right: 0;
  z-index: 9;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.banner_black.black_eight .banner_content p {
  color: #a8741a;
}

.banner_black.black_eight .banner_content h2 {
  color: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner_black.black_eight .banner_content h2 {
    font-size: 18px;
  }
}

.banner_black.black_eight .banner_content a {
  text-decoration: underline;
  color: #a0a0a0;
  font-weight: 500;
}

.banner_black.black_eight .banner_content a:hover {
  color: #a8741a;
}

@media only screen and (max-width: 767px) {
  .banner_fullwidth.black_two .banner_text img {
    max-width: 150px;
  }
}

.banner_fullwidth.black_two .banner_text h2 {
  color: #ffffff;
  font-size: 48px;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .banner_fullwidth.black_two .banner_text h2 {
    font-size: 22px;
  }
}

.banner_fullwidth.black_two .banner_text h2 span {
  color: #a8741a;
}

.banner_fullwidth.black_two .banner_text>span {
  color: #a0a0a0;
}

.banner_fullwidth.black_two .banner_text a {
  color: #a8741a;
  border-color: #a8741a;
}

.banner_fullwidth.black_two .banner_text a:hover {
  background: #a8741a;
  color: #ffffff;
}

.home_banner9 {
  margin-bottom: 74px;
}

@media only screen and (max-width: 767px) {
  .home_banner9 {
    margin-bottom: 23px;
  }
}

/* 03. product section css here */
/*------ custom tooltip start -------*/
.tooltip.show {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
}

.tooltip>.tooltip-inner {
  font-size: 13px;
  background-color: #d8373e;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.tooltip-inner {
  padding: 2px 10px 5px;
  border-radius: 2px;
  font-size: 12px;
  line-height: 20px;
  background: #242424 !important;
}

.product_section {
  margin-bottom: 34px;
}

@media only screen and (max-width: 767px) {
  .product_section {
    margin-bottom: 25px;
  }
}

.tab-content>.tab-pane.active {
  display: block;
  height: auto;
  opacity: 1;
  overflow: visible;
}

.tab-content>.tab-pane {
  display: block;
  height: 0;
  opacity: 0;
  overflow: hidden;
}

.custom-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -6px;
  margin-right: -6px;
}

.product_tab_button {
  margin-bottom: 35px;
  text-align: center;
}

.product_tab_button ul {
  justify-content: center;
  margin: 0 auto;
  border: 2px solid #ebebeb;
}

@media only screen and (max-width: 767px) {
  .product_tab_button ul {
    border: 0;
  }
}

.product_tab_button ul.nav {
  display: inline-block;
  position: relative;
}

.product_tab_button ul.nav::before {
  content: "";
  width: 200px;
  height: 2px;
  position: absolute;
  right: 100%;
  top: 50%;
  background-image: linear-gradient(to right, transparent, #ebebeb);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_tab_button ul.nav::before {
    width: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .product_tab_button ul.nav::before {
    display: none;
  }
}

.product_tab_button ul.nav::after {
  content: "";
  width: 200px;
  height: 2px;
  position: absolute;
  left: 100%;
  top: 50%;
  background-image: linear-gradient(to right, #ebebeb, transparent);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_tab_button ul.nav::after {
    width: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .product_tab_button ul.nav::after {
    display: none;
  }
}

.product_tab_button ul li {
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .product_tab_button ul li {
    display: block;
  }
}

.product_tab_button ul li:first-child a {
  border-left: 0;
}

@media only screen and (max-width: 767px) {
  .product_tab_button ul li:first-child a {
    border-left: 2px solid #ebebeb;
    margin-top: 0;
  }
}

.product_tab_button ul li a {
  font-size: 20px;
  color: #242424;
  line-height: 48px;
  text-transform: capitalize;
  font-weight: 700;
  display: inline-block;
  border-left: 2px solid #ebebeb;
  padding: 0 30px;
  border-radius: 2px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

@media only screen and (max-width: 767px) {
  .product_tab_button ul li a {
    border-left: 0;
    border: 2px solid #ebebeb;
    min-width: 190px;
    margin-top: 8px;
    font-size: 16px;
    line-height: 40px;
  }
}

.product_tab_button ul li a:hover {
  color: #f743a6;
}

.product_tab_button ul li a.active {
  position: relative;
  color: #f743a6;
}

.product_tab_button ul li a.active::before {
  position: absolute;
  content: "";
  width: 104%;
  height: 109%;
  border: 2px solid #f743a6;
  left: -2px;
  top: -2px;
  border-radius: 2px;
}

@media only screen and (max-width: 767px) {
  .product_tab_button ul li a.active::before {
    width: 102%;
  }
}

.product_container .custom-col-5 {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 6px;
}

.product_container button {
  display: none !important;
}

.single_product:hover .product_thumb a.secondary_img {
  opacity: 1;
  visibility: visible;
}

.single_product:hover .quick_button {
  opacity: 1;
  visibility: visible;
}

.product_thumb {
  position: relative;
  margin-bottom: 8px;
}

.product_thumb a.secondary_img {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
}

.quick_button {
  position: absolute;
  bottom: 15px;
  left: 15px;
  right: 15px;
  border-radius: 3px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.quick_button a {
  line-height: 45px;
  background: #3e3e3e;
  color: #ffffff;
  padding: 0 10px;
  font-weight: normal;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  font-size: 13px;
  text-transform: capitalize;
  width: 100%;
  text-align: center;
  display: block;
}

.quick_button a:hover {
  background: #f743a6;
}

.product_content {
  text-align: center;
  position: relative;
}

.product_content h3 {
  line-height: 22px;
  font-size: 15px;
  font-weight: 700;
  text-transform: capitalize;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 13px;
}

.product_content h3::before {
  background-image: linear-gradient(to right, transparent, #ebebeb 30%, 85%, transparent);
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.product_content h3 a {
  color: #242424;
}

.product_content h3 a:hover {
  color: #f743a6;
}

.price_box>span {
  font-size: 16px;
  font-weight: 500;
  color: #f743a6;
}

.price_box>span.old_price {
  color: #545454;
  font-size: 14px;
  margin-right: 5px;
  text-decoration: line-through;
  font-weight: 400;
}

.product_hover {
  position: absolute;
  top: 50%;
  z-index: 9;
  left: 0;
  background: #ffffff;
  padding: 3px 8px 20px 8px;
  box-shadow: 0px 6px 7px 0 rgba(0, 0, 0, 0.3);
  transition: all 300ms ease-in-out;
  opacity: 0;
  visibility: hidden;
}

.single_product {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  margin-bottom: 30px;
}

.single_product:hover {
  background: #ffffff;
  border-radius: 3px;
  box-shadow: 0px 0 15px 0 rgba(0, 0, 0, 0.3);
}

.single_product:hover .product_hover {
  top: 66%;
  opacity: 1;
  visibility: visible;
}

.product_ratings {
  margin-bottom: 6px;
}

.product_ratings ul li {
  display: inline-block;
}

.product_ratings ul li a {
  color: #f743a6;
}

.action_links ul li {
  display: inline-block;
}

.action_links ul li.add_to_cart a {
  line-height: 35px;
  padding: 0 18px;
  width: 100%;
  text-transform: capitalize;
  font-size: 12px;
}

.action_links ul li a {
  line-height: 37px;
  height: 35px;
  width: 35px;
  background: #666;
  color: #ffffff !important;
  display: block;
  margin-bottom: 15px;
  margin-top: 5px;
  text-align: center;
}

.action_links ul li a:hover {
  background: #f743a6;
  color: #ffffff;
}

.tag_cate {
  margin-bottom: 10px;
}

.tag_cate a {
  color: #5a5a5a;
  font-size: 13px;
}

.tag_cate a:hover {
  color: #f743a6;
}

.product_desc {
  margin-bottom: 15px;
  position: relative;
  padding-bottom: 14px;
}

.product_desc::before {
  background-image: linear-gradient(to right, transparent, #ebebeb 30%, 85%, transparent);
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.product_desc p {
  font-size: 13px;
  line-height: 18px;
  color: #5a5a5a;
}

.p_section1 .slick-list {
  padding-bottom: 135px !important;
  margin-bottom: -135px;
  z-index: 9;
  padding-top: 18px !important;
  margin-top: -30px;
  position: relative;
}

.p_bottom.p_section1 .slick-list {
  padding-bottom: 120px !important;
  margin-bottom: -120px;
}

.product_container.bottom button {
  display: block !important;
  position: absolute;
  top: -63px;
  right: 30px;
  z-index: 99;
  border: 0;
  background: inherit;
  font-size: 23px;
  background: #fff;
}

.product_container.bottom button:hover {
  color: #f743a6;
}

@media only screen and (max-width: 767px) {
  .product_container.bottom button {
    top: -61px;
  }
}

.product_container.bottom button.next_arrow {
  right: 13px;
}

.section_title {
  margin-bottom: 33px;
  position: relative;
}

.section_title::before {
  content: "";
  height: 2px;
  background: #ebebeb;
  position: absolute;
  left: 0;
  right: 15px;
  top: 50%;
}

.section_title h2 {
  margin-bottom: 0;
  position: relative;
  display: inline-block;
  line-height: 30px;
  text-transform: capitalize;
  padding-right: 20px;
  background: #fff;
  color: #242424;
  font-size: 24px;
  font-weight: 700;
}

@media only screen and (max-width: 767px) {
  .section_title h2 {
    font-size: 19px;
  }
}

.product_section.p_bottom {
  margin-bottom: 68px;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 39px;
}

.product_section.p_bottom .section_title {
  margin-bottom: 33px;
}

@media only screen and (max-width: 767px) {
  .product_section.p_bottom {
    margin-bottom: 63px;
    padding-bottom: 34px;
  }
}

/*home two css here*/
.product_bottom_two {
  background: #f8f9f9;
  padding: 70px 0 50px;
  margin-bottom: 77px;
}

@media only screen and (max-width: 767px) {
  .product_bottom_two {
    padding: 60px 0 25px;
    margin-bottom: 52px;
  }
}

.product_bottom_two .product_container .custom-col-5 {
  padding: 0 15px;
}

@media only screen and (max-width: 767px) {
  .product_bottom_two .product_container .custom-col-5 {
    padding: 0 8px;
  }
}

@media only screen and (max-width: 479px) {
  .product_bottom_two .product_container .custom-col-5 {
    padding: 0 15px;
  }
}

.product_bottom_two .custom-row {
  margin-left: -15px;
  margin-right: -15px;
}

/*home three css here*/
.product_s_three {
  margin-bottom: 44px;
}

@media only screen and (max-width: 767px) {
  .product_s_three {
    margin-bottom: 25px;
  }
}

/*home five css here*/
.product_black_section .product_tab_button ul.nav {
  border: 2px solid #2d2d2d;
}

@media only screen and (max-width: 767px) {
  .product_black_section .product_tab_button ul.nav {
    border: 0;
  }

  .search_btn1 {
    display: none;
  }
}

.product_black_section .product_tab_button ul.nav::before {
  background-image: linear-gradient(to right, transparent, #2d2d2d);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_black_section .product_tab_button ul.nav::before {
    width: 120px;
  }
}

.product_black_section .product_tab_button ul.nav::after {
  background-image: linear-gradient(to right, #2d2d2d, transparent);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_black_section .product_tab_button ul.nav::after {
    width: 120px;
  }

  .search_btn1 {
    display: none;
  }
}

.product_black_section .product_tab_button ul.nav li:first-child a {
  border-left: 0;
}

.product_black_section .product_tab_button ul.nav li a {
  border-left: 2px solid #2d2d2d;
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .product_black_section .product_tab_button ul.nav li a {
    border: 2px solid #2d2d2d;
  }
}

.product_black_section .product_tab_button ul.nav li a:hover {
  color: #a8741a;
}

.product_black_section .product_tab_button ul.nav li a.active {
  color: #a8741a;
}

.product_black_section .product_tab_button ul.nav li a.active::before {
  border: 2px solid #a8741a;
}

.product_black_section .single_product {
  padding: 15px 17px;
}

.product_black_section .single_product:hover {
  background: #242424;
}

.product_black_section .product_hover {
  background: #242424;
  left: -17px;
  width: 117%;
}

.product_black_section .product_container .custom-col-5 {
  padding: 0;
}

.product_black_section .slick-list {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.product_black_section .quick_button a {
  font-size: 14px;
}

.product_black_section .quick_button a:hover {
  background: #a8741a;
}

.product_black_section .tag_cate a {
  color: #a0a0a0;
}

.product_black_section .tag_cate a:hover {
  color: #a8741a;
}

.product_black_section .product_content h3 {
  font-weight: 400;
  font-size: 16px;
}

.product_black_section .product_content h3::before {
  background-image: linear-gradient(to right, transparent, #2d2d2d 30%, 85%, transparent);
}

.product_black_section .product_content h3 a {
  color: #ffffff;
}

.product_black_section .product_content h3 a:hover {
  color: #a8741a;
}

.product_black_section .product_desc p {
  color: #a0a0a0;
}

.product_black_section .product_ratings ul li a {
  color: #a8741a;
}

.product_black_section .price_box span.current_price {
  color: #a8741a;
}

.product_black_section .product_desc::before {
  background-image: linear-gradient(to right, transparent, #2d2d2d 30%, 85%, transparent);
}

.product_black_section .action_links ul li a {
  background: #2e2e2e;
  color: #ccc;
}

.product_black_section .action_links ul li a:hover {
  background: #a8741a;
}

.product_black_section .action_links ul li.add_to_cart a {}

.product_black_section .section_title::before {
  background: #2d2d2d;
}

.product_black_section .section_title h2 {
  color: #ffffff;
  background: #242424;
  font-weight: 400;
}

.product_black_section .product_container.bottom button {
  color: #a0a0a0;
  background: #242424;
  top: -66px;
}

.product_black_section .product_container.bottom button:hover {
  color: #a8741a;
}

@media only screen and (max-width: 767px) {
  .product_black_section .product_container.bottom button {
    top: -61px;
  }
}

.product_black_section.bottom {
  border-bottom: 1px solid #2d2d2d;
  padding-bottom: 34px;
  margin-bottom: 80px;
}

@media only screen and (max-width: 767px) {
  .product_black_section.bottom {
    padding-bottom: 45px;
    margin-bottom: 87px;
  }
}

.product_black_section.bottom_two {
  background: #212121;
  padding-bottom: 31px;
  margin-bottom: 75px;
  padding-top: 64px;
}

.product9_bottom {
  padding: 80px 0 43px;
  margin-bottom: 80px;
}

@media only screen and (max-width: 767px) {
  .product9_bottom {
    padding: 60px 0 24px;
    margin-bottom: 60px;
  }
}

.instagram_wrapper_new {
  position: relative;
}

/* 05. blog area css here */
.blog_section {
  padding-bottom: 62px;
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 62px;
}

.blog_section .section_title {
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .blog_section {
    padding-bottom: 54px;
    margin-bottom: 51px;
  }
}

.blog_wrapper .col-lg-4 {
  flex: 0 0 100%;
  width: 100%;
}

.blog_thumb {
  position: relative;
  margin-bottom: 20px;
}

.blog_thumb a {
  display: block;
}

.blog_thumb::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(34, 31, 31, 0.75);
  top: 0;
  left: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.blog_thumb:hover::before {
  opacity: 1;
  visibility: visible;
}

.blog_thumb iframe {
  width: 100%;
}

.blog_content {
  text-align: center;
}

.blog_content h3 {
  font-size: 16px;
  text-transform: capitalize;
  line-height: 21px;
  position: relative;
  margin-top: 0;
  margin-bottom: 8px;
  font-weight: 700;
  margin-bottom: 13px;
}

@media only screen and (max-width: 767px) {
  .blog_content h3 {
    font-size: 14px;
  }
}

.blog_content h3 a {
  color: #242424;
}

.blog_content h3 a:hover {
  color: #f743a6;
}

.blog_content .read_more a {
  font-size: 14px;
  text-transform: capitalize;
  color: #f743a6;
}

.blog_content .read_more a:hover {
  color: #1c456c;
}

.author_name {
  margin-bottom: 10px;
  margin-bottom: 10px;
}

.author_name p {
  font-size: 13px;
  text-transform: capitalize;
  color: #a7a7a7;
}

.author_name p span.themes {
  color: #f743a6;
}

.author_name p span.post_by {
  color: #212121;
}

.post_desc {
  margin-bottom: 12px;
}

.post_desc p {
  line-height: 26px;
}

.blog_wrapper .owl-nav {
  position: absolute;
  top: -60px;
  right: 14px;
  display: flex;
  background: #fff;
  padding: 0 10px;
}

@media only screen and (max-width: 767px) {
  .blog_wrapper .owl-nav {
    top: -58px;
  }
}

.blog_wrapper .owl-nav div {
  font-size: 23px;
}

.blog_wrapper .owl-nav div:hover {
  color: #f743a6;
}

.blog_wrapper .owl-nav div.owl-next {
  margin-left: 8px;
}

/*home three blog css here*/
.blog_section_three {
  padding-bottom: 96px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .blog_section_three {
    padding-bottom: 76px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog_section_three {
    padding-bottom: 76px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_section_three {
    padding-bottom: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .blog_section_three {
    padding-bottom: 55px;
  }
}

/*home five css here*/
.blog_black {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 64px;
  position: relative;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .blog_black {
    margin-bottom: 55px;
  }
}

.blog_black .section_title::before {
  background: #2d2d2d;
}

.blog_black .section_title h2 {
  color: #ffffff;
  background: #242424;
  font-weight: 400;
}

.blog_black .blog_wrapper .owl-nav {
  background: #242424;
}

.blog_black .blog_wrapper .owl-nav div {
  color: #a0a0a0;
}

.blog_black .blog_wrapper .owl-nav div:hover {
  color: #a8741a;
}

.blog_black .blog_content {
  text-align: left;
}

.blog_black .blog_content h3 {
  font-size: 18px;
  font-weight: 400;
}

.blog_black .blog_content h3 a {
  color: #ffffff;
}

.blog_black .blog_content h3 a:hover {
  color: #a8741a;
}

.blog_black .blog_content .read_more a {
  color: #a0a0a0;
}

.blog_black .blog_content .read_more a:hover {
  color: #a8741a;
}

.blog_black .author_name p {
  color: #ffffff;
}

.blog_black .author_name p span.themes {
  color: #a8741a;
}

.blog_black .post_desc p {
  color: #a0a0a0;
}

/*06. newsletter area css here*/
.newsletter_area {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 70px;
  margin-bottom: 65px;
}

@media only screen and (max-width: 767px) {
  .newsletter_area {
    padding-bottom: 60px;
    margin-bottom: 55px;
  }
}

.newsletter_content {
  text-align: center;
}

.newsletter_content h2 {
  text-transform: capitalize;
  color: #242424;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.newsletter_content p {
  line-height: 26px;
  margin-bottom: 37px;
}

.newsletter_content form {
  width: 690px;
  position: relative;
  margin: 0 auto;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .newsletter_content form {
    width: 620px;
  }
}

@media only screen and (max-width: 767px) {
  .newsletter_content form {
    width: 100%;
  }
}

.newsletter_content form input {
  width: 100%;
  padding: 10px 224px 10px 20px;
  border: 2px solid #ebebeb;
  background: none;
  color: #a7a7a7;
  border-radius: 4px;
}

@media only screen and (max-width: 767px) {
  .newsletter_content form input {
    padding: 8px 109px 8px 10px;
  }
}

.newsletter_content form button {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  padding: 0 60px;
  background: #f743a6;
  color: #fff;
  height: 100%;
  border: 0;
  border-radius: 4px;
  text-transform: capitalize;
}

.newsletter_content form button:hover {
  background: #222;
}

@media only screen and (max-width: 767px) {
  .newsletter_content form button {
    padding: 0 17px;
    font-size: 14px;
  }
}

.mailchimp-error {
  text-align: center;
  color: green;
}

.mailchimp-success {
  text-align: center;
  color: green;
  margin: 0 auto;
  max-width: 550px;
}

.mailchimp-success.active {
  margin-top: 20px;
}

/*home five css here*/
.newsletter_black {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 70px;
}

.newsletter_black .mailchimp-error {
  color: #ffffff;
}

.newsletter_black .mailchimp-success {
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .newsletter_black {
    margin-bottom: 60px;
  }
}

.newsletter_black h2 {
  color: #ffffff;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .newsletter_black h2 {
    font-size: 20px;
  }
}

.newsletter_black p {
  color: #a0a0a0;
}

.newsletter_black .newsletter_content form {
  width: inherit;
  position: unset;
  display: flex;
  justify-content: center;
}

.newsletter_black .newsletter_content form input {
  width: 500px;
  border: 2px solid #2d2d2d;
  font-size: 12px;
  color: #a0a0a0;
  padding: 0 20px;
}

@media only screen and (max-width: 767px) {
  .newsletter_black .newsletter_content form input {
    width: 250px;
  }
}

@media only screen and (max-width: 479px) {
  .newsletter_black .newsletter_content form input {
    width: 160px;
  }
}

.newsletter_black .newsletter_content form button {
  position: unset;
  margin-left: 20px;
  height: 48px;
  background: #2e2e2e;
  font-weight: 400;
}

.newsletter_black .newsletter_content form button:hover {
  background: #a8741a;
}

/* 21. shipping css here */
.shipping_area {
  padding-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  .shipping_area {
    padding-bottom: 30px;
  }
}

.single-shipping {
  margin-bottom: 30px;
  padding: 25px 0 20px;
  background: #f8f9f9;
  text-align: center;
}

.single-shipping h3 {
  font-size: 18px;
  line-height: 24px;
  color: #242424;
  font-weight: 700;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .single-shipping h3 {
    font-size: 16px;
  }
}

.single-shipping p {
  font-size: 14px;
  line-height: 26px;
  color: #5a5a5a;
}

/*home three css here*/
.shipping_two {
  padding-bottom: 25px;
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 80px;
}

@media only screen and (max-width: 767px) {
  .shipping_two {
    margin-bottom: 60px;
  }
}

.shipping_two .single-shipping {
  padding: 0;
  background: inherit;
}

.shipping_two .single-shipping:hover .shipping_icone::before {
  color: #f743a6;
  border-color: #f743a6;
}

.shipping_content h3 {
  font-size: 18px;
  margin-bottom: 12px;
}

.shipping_content p {
  font-size: 15px;
  letter-spacing: 0.3px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shipping_content p {
    letter-spacing: 0;
  }
}

.shipping_icone {
  position: relative;
}

.shipping_icone::before {
  width: 70px;
  height: 70px;
  line-height: 67px;
  text-align: center;
  font-size: 21px;
  display: block;
  border-radius: 100%;
  border: 2px solid #323232;
  margin: auto;
  margin-bottom: auto;
  margin-bottom: 18px;
  transition: all .4s ease 0s;
}

.shipping_icone.icone_1::before {
  content: "\f276";
}

.shipping_icone.icone_2::before {
  content: "\f375";
}

.shipping_icone.icone_3::before {
  content: "\f3e2";
}

.shipping_icone.icone_4::before {
  content: "\f3b3";
}

/*home six css here*/
.shipping_black .single-shipping {
  background: #1d1d1d;
}

.shipping_black .single-shipping h3 {
  color: #ffffff;
  font-weight: 400;
}

.shipping_black .single-shipping p {
  color: #a0a0a0;
}

/*home seven css here*/
.shipping_two.s_black2 {
  border-bottom: 1px solid #2d2d2d;
  border-top: 1px solid #2d2d2d;
  margin-bottom: 60px;
  padding-top: 60px;
}

.shipping_two.s_black2 .single-shipping:hover .shipping_icone::before {
  color: #a8741a;
  border-color: #a8741a;
}

.shipping_two.s_black2 .shipping_icone::before {
  color: #a0a0a0;
  width: 60px;
  height: 60px;
  line-height: 57px;
}

.shipping_two.s_black2 .shipping_content h3 {
  color: #ffffff;
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 10px;
}

.shipping_two.s_black2 .shipping_content p {
  color: #a0a0a0;
}

.shipping_nine {
  padding: 80px 0 44px;
}

@media only screen and (max-width: 767px) {
  .shipping_nine {
    padding: 60px 0 24px;
  }
}

/*  07. footer area css here */
.footer_top {
  padding-bottom: 61px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer_top {
    padding-bottom: 42px;
  }
}

@media only screen and (max-width: 767px) {
  .footer_top {
    padding-bottom: 27px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .widgets_container {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .widgets_container {
    margin-bottom: 25px;
  }
}

.widgets_container>h3 {
  font-size: 20px;
  line-height: 26px;
  text-transform: capitalize;
  margin-bottom: 24px;
  font-weight: 600;
  color: #242424;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .widgets_container>h3 {
    margin-bottom: 8px;
  }
}

@media only screen and (max-width: 767px) {
  .widgets_container>h3 {
    margin-bottom: 8px;
    font-size: 18px;
    line-height: 25px;
  }
}

.footer_menu ul li a {
  display: block;
  font-weight: 400;
  line-height: 30px;
  font-size: 14px;
  color: #5a5a5a;
}

.footer_menu ul li a:hover {
  color: #f743a6;
}

@media only screen and (max-width: 767px) {
  .footer_menu ul li a {
    font-size: 13px;
  }
}

.footer_contact p {
  margin-bottom: 0;
  line-height: 30px;
}

.footer_contact p a:hover {
  color: #f743a6;
}

.footer_contact ul {
  margin-top: 10px;
}

.footer_contact ul li {
  display: inline-block;
  margin-right: 7px;
}

.footer_contact ul li:last-child {
  margin-right: 0;
}

.footer_contact ul li a {
  color: #b2b2b2;
  font-size: 18px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border: 1px solid #e1e1e1;
  background: #fff;
  text-align: center;
  border-radius: 50%;
  display: block;
}

.footer_contact ul li a:hover {
  color: #f743a6;
  border-color: #f743a6;
}

.simple_product_content .tag_cate {
  margin-bottom: 0;
}

.simple_product_content .product_name h3 {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 10px;
}

.simple_product_content .product_name h3 a {
  color: #1c456c;
}

.simple_product_content .product_name h3 a:hover {
  color: #f743a6;
}

.simple_product_content .product_price span {
  color: #5a5a5a;
  font-size: 14px;
  font-weight: 400;
}

.simple_product_content .product_price span.old_price {
  text-decoration: line-through;
  padding-right: 8px;
}

.simple_product_content .product_price span.current_price {
  color: #f743a6;
  font-weight: 500;
}

.simple_product_items {
  display: flex;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ebebeb;
}

.simple_product_items:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0;
}

.simple_product_thumb {
  width: 22%;
}

.simple_product_content {
  width: 70%;
  margin-left: 17px;
}

.footer_middel_menu {
  text-align: center;
  padding: 17px 0 15px;
  border-bottom: 1px solid #ebebeb;
  border-top: 1px solid #ebebeb;
}

.footer_middel_menu ul li {
  display: inline-block;
  margin-right: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer_middel_menu ul li {
    margin-right: 24px;
  }

  .sliderCategorys {
    display: block !important;
  }
}

@media only screen and (max-width: 767px) {
  .footer_middel_menu ul li {
    margin-right: 20px;
  }

  .sliderCategorys {
    display: block !important;
    z-index: 99;
  }
}

.footer_middel_menu ul li:last-child {
  margin-right: 0;
}

.footer_middel_menu ul li a {
  font-size: 16px;
  text-transform: capitalize;
  color: #5a5a5a;
}

.footer_middel_menu ul li a:hover {
  color: #f743a6;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer_middel_menu ul li a {
    font-size: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .footer_middel_menu ul li a {
    font-size: 13px;
  }
}

.copyright_area {
  text-align: center;
}

.copyright_area p {
  text-transform: capitalize;
  line-height: 26px;
}

@media only screen and (max-width: 767px) {
  .copyright_area p {
    margin-bottom: 5px;
  }
}

.copyright_area p a {
  color: #f743a6;
}

.copyright_area p a:hover {
  text-decoration: underline;
}

.copyright_area p i {
  color: red;
}

.footer_bottom {
  padding: 38px 0 46px;
}

@media only screen and (max-width: 767px) {
  .footer_bottom {
    padding: 20px 0 24px;
  }
}

/*home five footer css here*/
.footer_black {
  border-top: 1px solid #2d2d2d;
  padding-top: 67px;
}

@media only screen and (max-width: 767px) {
  .footer_black {
    padding-top: 55px;
  }
}

.footer_black .widgets_container>h3 {
  color: #ffffff;

  font-weight: 400;
}

.footer_black .footer_contact p {
  color: #a0a0a0;
}

.footer_black .footer_contact ul li a {
  background: #2f2f2f;
  color: #ffffff;
  border: 0;
}

.footer_black .footer_contact ul li a:hover {
  background: #a8741a;
}

.footer_black .footer_menu ul li a {
  color: #a0a0a0;
}

.footer_black .footer_menu ul li a:hover {
  color: #a8741a;
}

.footer_black .tag_cate a {
  color: #a0a0a0;
}

.footer_black .tag_cate a:hover {
  color: #a8741a;
}

.footer_black .product_name h3 {
  text-transform: capitalize;
}

.footer_black .product_name h3 a {
  color: #ffffff;
}

.footer_black .simple_product_content .product_price span.current_price {
  color: #a8741a;
}

.footer_black .simple_product_items {
  border-bottom: 1px solid #2d2d2d;
}

.footer_black .simple_product_items:last-child {
  border-bottom: 0;
}

.footer_black .footer_middel_menu {
  border-bottom: 1px solid #2d2d2d;
  border-top: 1px solid #2d2d2d;
}

.footer_black .footer_middel_menu ul li a {
  color: #a0a0a0;

}

.footer_black .footer_middel_menu ul li a:hover {
  color: #a8741a;
}

.footer_black .copyright_area p {
  color: #a0a0a0;
}

.footer_black .copyright_area p a {
  color: #a8741a;
}

/*other footer css here*/
.footer_other {
  border-top: 1px solid #ebebeb;
  padding-top: 64px;
}

@media only screen and (max-width: 767px) {
  .footer_other {
    padding-top: 54px;
  }
}

/*collection area css here*/
.collection_area {
  margin-bottom: 94px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .collection_area {
    margin-bottom: 74px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .collection_area {
    margin-bottom: 74px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .collection_area {
    margin-bottom: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .collection_area {
    margin-bottom: 53px;
  }
}

@media only screen and (max-width: 767px) {
  .collection_img {
    margin-bottom: 21px;
  }
}

.collection_img img:hover {
  opacity: 0.7;
}

@media only screen and (max-width: 767px) {
  .collection_content {
    text-align: center;
  }
}

.collection_content h1 {
  font-size: 120px;
  color: #f1f1f1;
  font-weight: 700;
  line-height: 100px;
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .collection_content h1 {
    font-size: 90px;
    line-height: 80px;
    margin-bottom: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .collection_content h1 {
    font-size: 90px;
    line-height: 80px;
    margin-bottom: 13px;
  }
}

.collection_content h4 {
  font-size: 18px;
  color: #5a5a5a;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 23px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .collection_content h4 {
    margin-bottom: 12px;
  }
}

@media only screen and (max-width: 767px) {
  .collection_content h4 {
    margin-bottom: 12px;
  }
}

.collection_content h2 {
  font-size: 36px;
  margin-top: 0;
  margin-bottom: 20px;
  color: #212121;
  font-weight: 700;
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .collection_content h2 {
    margin-bottom: 10px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .collection_content h2 {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 28px;
  }
}

.collection_content p {
  line-height: 24px;
  font-size: 16px;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .collection_content p {
    font-size: 15px;
  }
}

.collection_content a {
  font-size: 16px;
  color: #212121;
  font-weight: 600;
  padding-bottom: 5px;
  border-bottom: 2px solid #212121;
  display: inline-block;
  margin-top: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .collection_content a {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .collection_content a {
    margin-top: 15px;
    font-size: 16px;
  }
}

.collection_content a:hover {
  color: #f743a6;
  border-color: #f743a6;
}

@media only screen and (max-width: 767px) {
  .collection_2 {
    margin-bottom: 29px;
  }
}

@media only screen and (max-width: 767px) {
  .collection_2 .collection_content {
    margin-bottom: 29px;
  }
}

@media only screen and (max-width: 767px) {
  .collection_4 {
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 767px) {
  .collection_4 .collection_content {
    margin-bottom: 29px;
  }
}

/*categories section css here*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_area {
    margin-top: 68px;
  }
}

.categories_thumb {
  position: relative;
}

@media only screen and (max-width: 767px) {
  .categories_thumb {
    height: 100vh;
  }
}

@media only screen and (max-width: 767px) {
  .categories_thumb a {
    height: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .categories_thumb img {
    height: 100%;
    object-fit: cover;
  }
}

.categories_content {
  position: absolute;
  top: 50%;
  right: 50px;
  transform: translatey(-50%);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .categories_content {
    right: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_content {
    right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .categories_content {
    right: 15px;
  }
}

.categories_content h4 {
  color: #212121;
  font-size: 18px;
  margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
  .categories_content h4 {
    font-size: 16px;
    margin-bottom: 10px;
  }
}

.categories_content h2 {
  color: #212121;
  font-size: 35px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .categories_content h2 {
    font-size: 22px;
    margin-bottom: 10px;
  }
}

.categories_content p {
  color: #212121;
  font-size: 17px;
  width: 440px;
  font-weight: 500;
  margin-top: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_content p {
    width: 310px;
  }
}

@media only screen and (max-width: 767px) {
  .categories_content p {
    font-size: 14px;
    width: 210px;
    line-height: 20px;
    margin-bottom: 5px;
    margin-top: 0;
  }
}

.categories_content a {
  text-transform: capitalize;
  font-size: 16px;
  color: #212121;
  font-weight: 600;
  padding-bottom: 5px;
  border-bottom: 2px solid #212121;
}

.categories_content a:hover {
  color: #f743a6;
  border-color: #f743a6;
}

#fp-nav {
  right: 31px;
}

.categories_area .col-12 {
  padding: 0;
}

.categories_content.content_left {
  left: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories_content.content_left {
    left: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .categories_content.content_left {
    left: 30px;
  }
}

/* 08. shop page css here */
.header_shop {
  border-bottom: 1px solid #ebebeb;
}

.header_shop .header_top {
  padding: 7px 0;
}

.header_shop .header_middel {
  padding: 40px 0 18px;
}

@media only screen and (max-width: 767px) {
  .header_shop .header_middel {
    padding: 21px 0 18px;
  }
}

.header_shop .header_bottom {
  padding: 0;
}

.breadcrumbs_area {
  padding: 40px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumbs_area {
    padding: 0 0 37px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumbs_area {
    padding: 0 0 37px;
  }
}

.breadcrumb_content {
  text-align: center;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 37px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb_content {
    border-bottom: 0;
    padding-bottom: 0;
    border-top: 1px solid #ebebeb;
    padding-top: 37px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb_content {
    border-bottom: 0;
    padding-bottom: 0;
    border-top: 1px solid #ebebeb;
    padding-top: 37px;
  }
}

.breadcrumb_content ul {
  line-height: 15px;
}

.breadcrumb_content ul li {
  display: inline-block;
  text-transform: capitalize;
  font-size: 14px;
  margin-right: 5px;
  color: #f743a6;
}

.breadcrumb_content ul li:last-child {
  margin-right: 0;
}

.breadcrumb_content ul li a {
  color: #242424;
}

.breadcrumb_content ul li a:hover {
  color: #f743a6;
}

.widget_list {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #ebebeb;
}

.widget_list:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0;
}

.widget_list h2 {
  color: #242424;
  font-size: 24px;
  margin: 0 0 20px;
  text-transform: capitalize;
  font-weight: 700;
  line-height: 32px;
}

.widget_list.widget_categories>ul>li {
  line-height: 44px;
  border-bottom: 1px solid #ebebeb;
}

.widget_list.widget_categories>ul>li:last-child {
  border-bottom: 0;
}

.widget_list.widget_categories>ul>li>a {
  color: #242424;
  font-size: 14px;
  display: block;
}

.widget_list.widget_categories>ul>li>a:hover {
  color: #f743a6;
}

.widget_list.widget_brands ul li,
.widget_list.widget_color ul li {
  line-height: 44px;
  position: relative;
  padding-left: 28px;
}

.widget_list.widget_brands ul li::before,
.widget_list.widget_color ul li::before {
  position: absolute;
  content: "\f383";

  display: inline-block;
  height: 17px;
  width: 17px;
  text-align: center;
  line-height: 17px;
  border-radius: 3px;
  border: 1px solid #888;
  color: transparent;
  top: 50%;
  left: 0;
  transform: translatey(-50%);
}

.widget_list.widget_brands ul li span,
.widget_list.widget_color ul li span {
  color: #5a5a5a;
  font-size: 15px;
  float: right;
}

.widget_list.widget_brands ul li a,
.widget_list.widget_color ul li a {
  color: #5a5a5a;
  font-size: 15px;
  text-transform: capitalize;
}

.widget_list.widget_brands ul li a:hover,
.widget_list.widget_color ul li a:hover {
  color: #f743a6;
}

.ui-slider-horizontal .ui-slider-range {
  background: #f743a6;
  height: 5px;
}

.ui-slider-horizontal {
  height: 3px;
  background: #dbdbdb;
  border: none;
  width: 92%;
  margin: 0 auto;
  margin-bottom: 22px;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  background: #f743a6;
  border: 0;
  border-radius: 0;
  width: 15px;
  height: 15px;
  top: -5px;
  cursor: e-resize;
  border-radius: 50%;
}

.widget_list.widget_filter form {
  padding-top: 10px;
}

.widget_list.widget_filter form input {
  background: none;
  border: none;
  font-size: 14px;
  float: left;
  line-height: 31px;
  width: 100%;
  color: #5a5a5a;
  font-weight: 500;
}

.widget_list.widget_filter form button {
  height: 30px;
  line-height: 30px;
  padding: 0 16px;
  font-size: 12px;
  text-transform: capitalize;
  color: #ffffff;
  background: #212121;
  border: 0;
  border-radius: 3px;
  font-weight: 500;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.widget_list.widget_filter form button:hover {
  background: #f743a6;
}

.tag_widget ul li {
  display: inline-block;
}

.tag_widget ul li a {
  margin: 0 6px 5px 0;
  display: block;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #ebebeb;
  background: #ffffff;
  padding: 8px 14px;
  line-height: 20px;
  color: #5a5a5a;
  text-transform: capitalize;
  border-radius: 3px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tag_widget ul li a {
    padding: 0 13px;
  }
}

.tag_widget ul li a:hover {
  background: #f743a6;
  color: #fff;
  border-color: #f743a6;
}

.widget_list.compare_p .compare_img {
  float: left;
  width: 25%;
}

.widget_list.compare_p .compare_info {
  float: left;
  padding-left: 10px;
  text-align: left;
  width: 75%;
}

.widget_list.compare_p .compare_info h3 {
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 0;
  font-weight: 700;
}

.widget_list.compare_p .compare_info h3 a {
  color: #242424;
}

.widget_list.compare_p .compare_info h3 a:hover {
  color: #f743a6;
}

.widget_list.compare_p .compare_item {
  overflow: hidden;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 19px;
  margin-bottom: 18px;
}

.widget_list.compare_p .compare_item.bottom {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 20px;
}

.widget_list.compare_p .compare_btn a {
  background: #1a1a1a;
  color: #fff;
  font-size: 12px;
  font-weight: normal;
  height: 35px;
  line-height: 35px;
  display: inline-block;
  padding: 0 20px;
  text-transform: capitalize;
  border-radius: 3px;
}

.widget_list.compare_p .compare_btn a:hover {
  background: #f743a6;
  color: #ffffff;
}

.widget_list.compare_p .compare_btn a i {
  margin-left: 5px;
}

.widget_list.Featured_p .Featured_img {
  float: left;
  width: 25%;
}

.widget_list.Featured_p .Featured_info {
  float: left;
  padding-left: 10px;
  text-align: left;
  width: 75%;
}

.widget_list.Featured_p .Featured_info h3 {
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 0;
  font-weight: 700;
}

.widget_list.Featured_p .Featured_info h3 a {
  color: #242424;
}

.widget_list.Featured_p .Featured_info h3 a:hover {
  color: #f743a6;
}

.widget_list.Featured_p .Featured_item {
  overflow: hidden;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 19px;
  margin-bottom: 18px;
}

.widget_list.Featured_p .Featured_item.bottom {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

.shop_toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ebebeb;
  padding: 12px 10px;
  margin: 0 0 30px;
}

@media only screen and (max-width: 767px) {
  .shop_toolbar {
    flex-direction: column;
    padding: 12px 10px 0px;
  }
}

.orderby_wrapper {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .orderby_wrapper {
    flex-direction: column;
  }
}

.orderby_wrapper h3 {
  color: #5a5a5a;
  font-size: 14px;
  margin-bottom: 0;
  margin-right: 15px;
}

@media only screen and (max-width: 767px) {
  .orderby_wrapper h3 {
    margin-bottom: 8px;
    margin-right: 0;
  }
}

.niceselect_option {
  height: 35px;
  line-height: 35px;
  border-radius: 0;
}

@media only screen and (max-width: 767px) {
  .niceselect_option {
    margin-bottom: 12px;
  }
}

.page_amount {
  margin-left: 30px;
}

@media only screen and (max-width: 767px) {
  .page_amount {
    margin-bottom: 12px;
    margin-left: 0;
  }
}

.page_amount p {
  color: #5a5a5a;
}

@media only screen and (max-width: 767px) {
  .page_amount p {
    padding-left: 0;
    margin-left: 0;
    border-left: 0;
  }
}

.list_button ul li {
  margin-right: 15px;
}

.list_button ul li:last-child {
  margin-right: 0;
}

.list_button ul li a {
  font-size: 23px;
}

.list_button ul li a:hover {
  color: #f743a6;
}

.list_button ul li a.active {
  color: #f743a6;
}

.product_ratting ul li {
  display: inline-block;
}

.product_ratting ul li a {
  color: #f743a6;
}

.product_list_item:hover {
  background: inherit;
  box-shadow: inherit;
}

.product_list_item .product_content {
  text-align: left;
  top: 28px;
}

.product_list_item .product_content h3 {
  margin-bottom: 16px;
  padding-bottom: 0;
  font-size: 18px;
  font-weight: 400;
}

.product_list_item .product_content h3::before {
  display: none;
}

.product_list_item .product_content h3 a {
  color: #1c456c;
}

.product_list_item .product_content h3 a:hover {
  color: #a8741a;
}

.product_list_item .product_ratting {
  margin-bottom: 10px;
}

.product_list_item .product_desc {
  padding: 0;
  margin-bottom: 18px;
}

.product_list_item .product_desc::before {
  display: none;
}

.product_list_item .product_desc p {
  font-size: 14px;
  width: 500px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product_list_item .product_desc p {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_list_item .product_desc p {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .product_list_item .product_desc p {
    width: 100%;
  }
}

.product_list_item .price_box {
  margin-bottom: 22px;
}

.product_list_item .action_links ul li {
  margin-right: 5px;
}

.product_list_item .action_links ul li:last-child {
  margin-right: 0;
}

.product_list_item .action_links ul li a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
}

.product_list_item .action_links ul li.add_to_cart a {
  padding: 0 32px;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .pagination {
    margin-top: 19px;
  }
}

.pagination ul li {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background: #f1f1f1;
  color: #5a5a5a;
  border-radius: 3px;
  margin-left: 3px;
}

.pagination ul li:first-child {
  margin-left: 0;
}

.pagination ul li a {
  display: block;
  border-radius: 3px;
}

.pagination ul li a:hover {
  background: #f743a6;
  color: #ffffff;
}

.pagination ul li.current {
  background: #f743a6;
  color: #ffffff;
}

.pagination ul li.next {
  width: 40px;
}

.shop_toolbar.t_bottom {
  justify-content: center;
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop_toolbar.t_bottom {
    margin-bottom: 59px;
    margin-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .shop_toolbar.t_bottom {
    padding: 15px 10px 15px;
    margin-top: 23px;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .shop_toolbar.t_bottom .pagination {
    margin-top: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop_reverse .row {
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 767px) {
  .shop_reverse .row {
    flex-direction: column-reverse;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop_reverse .tab-content .row {
    flex-direction: row;
  }
}

@media only screen and (max-width: 767px) {
  .shop_reverse .tab-content .row {
    flex-direction: row;
  }
}

.shop_area {
  padding-bottom: 65px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop_area {
    padding-bottom: 34px;
  }
}

@media only screen and (max-width: 767px) {
  .shop_area {
    padding-bottom: 46px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop_area .list_view.active {
    padding-bottom: 36px;
  }
}

@media only screen and (max-width: 767px) {
  .shop_area .list_view.active {
    padding-bottom: 36px;
  }
}

.shop_area .quick_button a {}

.shop_fullwidth {
  padding-bottom: 70px;
}

@media only screen and (max-width: 767px) {
  .shop_fullwidth {
    padding-bottom: 60px;
  }
}

.shop_fullwidth .shop_toolbar.t_bottom {
  margin-bottom: 0;
}

/* 15. product details css here */
.product_bread .breadcrumb_content ul li a {
  line-height: 35px;
}

#img-1 {
  border: 1px solid #ebebeb;
}

.product_details {
  margin-bottom: 70px;
}

@media only screen and (max-width: 767px) {
  .product_details {
    margin-bottom: 55px;
  }
}

.header_product {
  border-bottom: 1px solid #ebebeb;
}

.product_d_right h1 {
  color: #242424;
  text-transform: capitalize;
  line-height: 21px;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .product_d_right h1 {
    font-size: 20px;
  }
}

.product_d_right .product_ratting {
  margin-bottom: 12px;
}

.product_d_right .product_price {
  margin-bottom: 29px;
}

@media only screen and (max-width: 767px) {
  .product_d_right .product_price {
    margin-bottom: 16px;
  }
}

.product_d_right .product_price span.current_price {
  font-size: 18px;
  color: #f743a6;
  font-weight: 700;
}

.product_d_right .product_price span.old_price {
  color: #a4a4a4;
  font-size: 14px;
  text-decoration: line-through;
  margin-right: 10px;
}

.product_d_right .product_desc {
  margin-bottom: 35px;
  padding-bottom: 28px;
  border-bottom: 1px solid #ebebeb;
}

@media only screen and (max-width: 767px) {
  .product_d_right .product_desc {
    margin-bottom: 30px;
    padding-bottom: 22px;
  }
}

.product_d_right .product_desc::before {
  display: none;
}

.product_d_right .product_desc p {
  width: 540px;
  font-size: 14px;
  line-height: 26px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product_d_right .product_desc p {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_d_right .product_desc p {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .product_d_right .product_desc p {
    width: 100%;
  }
}

.product_d_right .priduct_social ul li {
  display: inline-block;
  margin-right: 7px;
}

.product_d_right .priduct_social ul li:last-child {
  margin-right: 0;
}

.product_d_right .priduct_social ul li a {
  color: #242424;
  display: block;
  line-height: 45px;
  text-align: center;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  border: 1px solid #ebebeb;
}

.product_d_right .priduct_social ul li a:hover {
  background: #f743a6;
  color: #ffffff;
  border-color: #f743a6;
}

@media only screen and (max-width: 767px) {
  .product_d_right .priduct_social ul li a {
    line-height: 34px;
    width: 35px;
    height: 35px;
  }
}

.product_nav {
  float: right;
  position: relative;
  top: -40px;
}

.product_nav ul li {
  display: inline-block;
  margin-left: 3px;
}

.product_nav ul li:first-child {
  margin-left: 0;
}

.product_nav ul li a {
  background: #343434;
  border-radius: 3px;
  color: #d1d1d1;
  display: block;
  font-size: 15px;
  height: 30px;
  width: 30px;
  line-height: 28px;
  text-align: center;
}

.product_nav ul li a:hover {
  background: #f743a6;
  color: #ffffff;
}

.product_variant.quantity {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  margin-bottom: 27px;
}

@media only screen and (max-width: 767px) {
  .product_variant.quantity {
    margin-bottom: 16px;
  }
}

.product_variant.quantity label {
  color: #242424;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 14px;
  margin-bottom: 0;
}

.product_variant.quantity input {
  width: 130px;
  border: 1px solid #ebebeb;
  background: none;
  height: 42px;
  padding: 0 12px;
  border-radius: 5px;
  margin-left: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product_variant.quantity input {
    width: 110px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_variant.quantity input {
    width: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .product_variant.quantity input {
    width: 65px;
    margin-left: 10px;
  }
}

.product_variant.quantity button {
  border: 0;
  font-size: 16px;
  margin-left: 20px;
  background: #f743a6;
  height: 42px;
  line-height: 42px;
  text-transform: capitalize;
  min-width: 270px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product_variant.quantity button {
    min-width: 240px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_variant.quantity button {
    min-width: 170px;
  }
}

@media only screen and (max-width: 767px) {
  .product_variant.quantity button {
    min-width: inherit;
    margin-left: 10px;
  }
}

.product_variant.quantity button:hover {
  background: #242424;
}

.product_variant.color {
  margin-bottom: 35px;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .product_variant.color {
    margin-bottom: 18px;
  }
}

.product_variant.color h3 {
  color: #242424;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 14px;
  margin-bottom: 0;
  margin-right: 40px;
  min-width: 50px;
}

.product_variant.color .niceselect_option {
  width: 300px;
  height: 42px;
  line-height: 40px;
}

@media only screen and (max-width: 767px) {
  .product_variant.color .niceselect_option {
    width: 160px;
  }
}

.product_d_action {
  margin-bottom: 22px;
}

.product_d_action ul li a {
  font-size: 14px;
  color: #242424;
  line-height: 30px;
}

.product_d_action ul li a:hover {
  color: #f743a6;
}

.product_meta {
  margin-bottom: 26px;
}

.product_meta span {
  font-weight: 500;
  color: #242424;
}

.product_meta span a {
  color: #5a5a5a;
  margin-left: 10px;
  font-weight: 400;
}

.product_info_button {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 15px;
}

.product_info_button ul {
  justify-content: center;
}

.product_info_button ul li a {
  display: block;
  float: left;
  text-transform: capitalize;
  font-size: 24px;
  color: #212121;
  font-weight: 500;
  margin-right: 35px;
  line-height: 26px;
}

@media only screen and (max-width: 767px) {
  .product_info_button ul li a {
    margin-right: 25px;
    font-size: 18px;
  }
}

.product_info_button ul li a.active {
  color: #f743a6;
}

.product_info_button ul li a:hover {
  color: #f743a6;
}

.product_info_button ul li:last-child a {
  margin-right: 0;
}

.product_review_form button {
  border: none;
  background: #212121;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 700;
  padding: 5px 15px;
  display: block;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  margin-top: 20px;
  border-radius: 5px;
}

.product_review_form button:hover {
  background: #f743a6;
  color: #ffffff;
}

.product_info_content p {
  color: #5a5a5a;
  line-height: 28px;
}

.product_d_table {
  padding: 10px 0 22px;
}

.product_d_table table {
  border-top: 1px solid #ddd;
  width: 100%;
}

.product_d_table table tbody tr {
  border-bottom: 1px solid #ddd;
}

.product_d_table table tbody tr td {
  padding: 7px 17px;
}

.product_d_table table tbody tr td:first-child {
  border-right: 1px solid #ddd;
  width: 30%;
  font-weight: 700;
}

.product_info_inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 15px;
}

.product_info_inner .product_ratting {
  margin-bottom: 10px;
}

.product_info_inner .product_ratting p {
  margin-bottom: 5px;
}

.product_info_inner .product_ratting strong {
  margin-top: 10px;
  display: block;
  margin-bottom: 8px;
}

.tab-content {
  padding-top: 20px;
}

.reviews_wrapper h2 {
  font-size: 18px;
  color: #242424;
  font-weight: 700;
  text-transform: capitalize;

}

.reviews_wrapper .product_ratting {
  margin-bottom: 20px;
}

.reviews_wrapper .product_ratting h3 {
  font-size: 14px;
  color: #242424;
  font-weight: 700;
  text-transform: capitalize;

}

.comment_title {
  margin-bottom: 20px;
}

.product_review_form input {
  border: 1px solid #ddd;
  background: none;
  width: 100%;
  height: 40px;
  padding: 0 20px;
}

.product_review_form textarea {
  border: 1px solid #ddd;
  background: none;
  height: 120px;
  resize: none;
  width: 100%;
  margin-bottom: 14px;
  padding: 0 20px;
}

.product_review_form p {
  margin-bottom: 7px;
}

.star_rating {
  float: right;
}

.star_rating ul li {
  display: inline-block;
}

.star_rating ul li a {
  color: #f743a6;
}

.product_d_info {
  margin-bottom: 57px;
}

@media only screen and (max-width: 767px) {
  .product_d_info {
    margin-bottom: 47px;
  }
}

.reviews_comment_box {
  display: flex;
  margin-bottom: 22px;
}

.reviews_comment_box .comment_text {
  width: 100%;
  border: 1px solid #ebebeb;
  position: relative;
  margin-left: 21px;
  padding: 12px;
  border-radius: 3px;
}

.reviews_comment_box .comment_text::before {
  background: #fff;
  border-bottom: 1px solid #ebebeb;
  border-left: 1px solid #ebebeb;
  content: '';
  display: block;
  height: 10px;
  left: -6px;
  position: absolute;
  top: 10px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 10px;
}

.reviews_meta p {
  color: #777;
  font-size: 15px;
  margin-bottom: 15px;
}

.reviews_meta p strong {
  text-transform: uppercase;
  font-weight: 700;
}

.s-tab-zoom.owl-carousel .owl-nav {
  display: block;
}

.s-tab-zoom.owl-carousel .owl-nav div {
  position: absolute;
  background: #f2f2f2;
  border-radius: 3px;
  color: #333;
  height: 32px;
  top: 50%;
  transform: translatey(-50%);
  width: 32px;
  text-align: center;
  line-height: 32px;
  left: -7px;
  font-size: 18px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
}

.s-tab-zoom.owl-carousel .owl-nav div:hover {
  background: #f743a6;
  color: #ffffff;
}

.s-tab-zoom.owl-carousel .owl-nav div.owl-next {
  right: -7px;
  left: auto;
}

@media only screen and (max-width: 767px) {
  .product-details-tab {
    margin-bottom: 55px;
  }
}

.product-details-tab:hover .s-tab-zoom.owl-carousel .owl-nav div {
  opacity: 1;
  visibility: visible;
}

.single-zoom-thumb {
  margin-top: 20px !important;
  width: 80%;
  margin: 0 auto;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-zoom-thumb {
    width: 85%;
  }
}

@media only screen and (max-width: 767px) {
  .single-zoom-thumb {
    width: 101%;
  }
}

.single-zoom-thumb ul li {
  border: 1px solid #ddd;
}

.single-zoom-thumb ul li a {
  width: 100%;
}

.related_product {
  padding-bottom: 44px;
}

@media only screen and (max-width: 767px) {
  .related_product {
    padding-bottom: 47px;
  }
}

.related_product .single_product {
  margin-bottom: 0;
}

.upsell_product {
  padding-bottom: 56px;
}

.upsell_product .product_area {
  padding-bottom: 52px;
  border-bottom: 1px solid #ebebeb;
}

.upsell_product .single_product {
  margin-bottom: 0;
}

.product_page .footer_top {
  padding-bottom: 53px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_page .footer_top {
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .product_page .footer_top {
    padding-bottom: 34px;
  }
}

/* 12. product grouped css here */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_grouped {
    margin-bottom: 40px;
  }
}

.grouped_form {
  border: 1px solid #ebebeb;
  margin-bottom: 25px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .grouped_form {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .grouped_form {
    margin-bottom: 17px;
  }
}

.grouped_form table {
  width: 100%;
}

.grouped_form table tbody tr {
  border-bottom: 1px solid #ebebeb;
}

.grouped_form table tbody tr td {
  border-right: 1px solid #ddd;
  padding: 11px 5px;
  text-align: center;
}

.grouped_form table tbody tr td input[type="checkbox"] {
  width: 20px;
  height: 21px;
}

.grouped_form table tbody tr td input[type="number"] {
  width: 50px;
  background: inherit;
  border: 1px solid #ebebeb;
  padding: 0 5px;
  height: 40px;
}

.grouped_form table tbody tr td.grouped-product-list.quantity {
  width: 80px;
  text-align: center;
  line-height: 12px;
}

.grouped_form table tbody tr td.grouped-product-list.label {
  min-width: 200px;
  font-weight: 600;
  font-size: 14px;
}

.grouped_form table tbody tr td.grouped-product-list.label a {
  color: #212121;
}

.grouped_form table tbody tr td.grouped-product-list.label a:hover {
  color: #f743a6;
}

.grouped_form table tbody tr td.grouped-product-list.price {
  font-size: 14px;
  color: #212121;
  font-weight: 600;
  min-width: 200px;
}

.grouped_form table tbody tr td.grouped-product-list.price p {
  color: #212121;
  font-size: 12px;
  font-weight: 600;
  position: relative;
}

.grouped_form table tbody tr td.grouped-product-list.price p::before {
  color: #f6f6f6;
  content: '\f058';
  display: inline-block;

  font-size: 1em;
  position: absolute;
  top: 0;
  left: 40px;
}

.grouped_form table tbody tr td:last-child {
  border-right: 0;
}

.grouped_form table tbody tr:last-child {
  border-bottom: 0;
}

.box_quantity.group button {
  margin-left: 0;
}

/*product grouped css end*/
/*variabla product css here*/
.p_section1.related_product .slick-list {
  padding-bottom: 144px !important;
  margin-bottom: -135px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .variable_product {
    margin-bottom: 56px;
  }
}

@media only screen and (max-width: 767px) {
  .variable_product {
    margin-bottom: 58px;
  }
}

@media only screen and (max-width: 767px) {
  .variable_product .product-details-tab {
    margin-bottom: 49px;
  }
}

.variable_product .niceselect_option .list {
  width: 100%;
}

.product_d_meta {
  margin-bottom: 20px;
}

.product_d_meta span {
  display: block;
  line-height: 18px;
  margin-bottom: 17px;
  font-size: 14px;
  color: #212121;
  font-weight: 400;
}

.product_d_meta span:last-child {
  margin-bottom: 0;
}

.product_d_meta span a:hover {
  color: #f743a6;
}

/*product sidebar css here*/
.product_sidebar {
  margin-bottom: 64px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product_sidebar {
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_sidebar {
    margin-bottom: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .product_sidebar {
    margin-bottom: 55px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_sidebar .row {
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 767px) {
  .product_sidebar .row {
    flex-direction: column-reverse;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_sidebar .product_section .row {
    flex-direction: row;
  }
}

@media only screen and (max-width: 767px) {
  .product_sidebar .product_section .row {
    flex-direction: row;
  }
}

.product_sidebar .upsell_product {
  padding-bottom: 52px;
}

.product_sidebar .upsell_product .product_area {
  padding-bottom: 0;
  border-bottom: 0;
}

.product_sidebar .product_desc p {
  width: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product_sidebar .action_button ul li a {
    width: 40px;
    height: 35px;
    line-height: 35px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product_sidebar .quick_button {
    bottom: 12px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product_sidebar .quick_button a {
    line-height: 35px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product_sidebar .hover_action a {
    width: 40px;
    height: 35px;
    line-height: 35px;
  }
}

.product_sidebar .product_variant.quantity input {
  width: 90px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product_sidebar .product_variant.quantity input {
    width: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_sidebar .product_variant.quantity input {
    width: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .product_sidebar .product_variant.quantity input {
    width: 60px;
  }
}

.product_sidebar .product_variant.quantity button {
  min-width: 200px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product_sidebar .product_variant.quantity button {
    min-width: 140px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_sidebar .product_variant.quantity button {
    min-width: inherit;
  }
}

@media only screen and (max-width: 767px) {
  .product_sidebar .product_variant.quantity button {
    min-width: inherit;
  }
}

@media only screen and (max-width: 767px) {
  .product_sidebar .product-details-tab {
    margin-bottom: 0;
  }
}

.footer_widgets.sidebar_widgets .footer_top {
  padding-bottom: 0;
  border-bottom: 0;
}

.footer_widgets.sidebar_widgets .footer_top_inner {
  padding: 56px 0 52px;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer_widgets.sidebar_widgets .footer_top_inner {
    padding: 56px 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .footer_widgets.sidebar_widgets .footer_top_inner {
    padding: 56px 0 35px;
  }
}

.product_right_sidebar {
  margin-bottom: 68px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product_right_sidebar .row {
    flex-direction: row;
  }
}

@media only screen and (max-width: 767px) {
  .product_right_sidebar {
    margin-bottom: 58px;
  }
}

@media only screen and (max-width: 767px) {
  .product_right_sidebar .product_d_right {
    margin-bottom: 42px;
  }
}

/* 13. cart page css here */
.header_cart_page {
  border-bottom: 1px solid #ebebeb;
}

.other_bread {
  padding-top: 41px;
  padding-bottom: 41px;
}

.table_desc {
  border: 1px solid #ebebeb;
  margin-bottom: 70px;
  margin-top: 2px;
}

@media only screen and (max-width: 767px) {
  .table_desc {
    margin-bottom: 60px;
  }
}

.table_desc .cart_page table {
  width: 100%;
}

.table_desc .cart_page table thead tr th {
  border-bottom: 3px solid #f743a6;
  border-right: 1px solid #ebebeb;
  color: #242424;
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
  padding: 10px;
  text-align: center;

}

.table_desc .cart_page table tbody tr td {
  border-bottom: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
  text-align: center;
  padding: 10px;
}

.table_desc .cart_page table tbody tr td.product_remove {
  min-width: 100px;
}

.table_desc .cart_page table tbody tr td.product_remove a {
  font-size: 20px;
  color: #f743a6;
}

.table_desc .cart_page table tbody tr td.product_remove a:hover {
  color: #f743a6;
}

.table_desc .cart_page table tbody tr td.product_thumb {
  max-width: 180px;
}

.table_desc .cart_page table tbody tr td.product_name {
  min-width: 180px;
}

.table_desc .cart_page table tbody tr td.product_name a {
  color: #242424;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 400;
}

.table_desc .cart_page table tbody tr td.product_name a:hover {
  color: #f743a6;
}

.table_desc .cart_page table tbody tr td.product-price {
  min-width: 130px;
  color: #242424;
  font-size: 16px;
  font-weight: 500;
}

.table_desc .cart_page table tbody tr td.product_quantity {
  min-width: 180px;
}

.table_desc .cart_page table tbody tr td.product_quantity label {
  color: #242424;

  font-weight: 700;
  margin-right: 5px;
}

.table_desc .cart_page table tbody tr td.product_quantity input {
  width: 60px;
  height: 40px;
  padding: 0 5px 0 10px;
  background: none;
  border: 1px solid #ebebeb;
}

.table_desc .cart_page table tbody tr td .product_total {
  min-width: 120px;
}

.cart_page table thead tr:last-child th,
.table_desc table tbody tr td:last-child {
  border-right: 0;
}

.cart_submit {
  text-align: right;
  padding: 12px;
}

.cart_submit button {
  background: #212121;
  border: 0;
  color: #ffffff;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  height: 38px;
  line-height: 18px;
  padding: 10px 15px;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 3px;
}

.cart_submit button:hover {
  background: #f743a6;
}

.coupon_inner {
  padding: 10px 20px 25px;
}

.coupon_inner p {
  color: #5a5a5a;
  font-size: 13px;
  margin-bottom: 20px;
}

.coupon_inner button {
  background: #212121;
  border: 0;
  color: #ffffff;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  height: 38px;
  line-height: 18px;
  padding: 10px 15px;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 3px;
}

.coupon_inner button:hover {
  background: #f743a6;
}

.coupon_inner input {
  border: 1px solid #ebebeb;
  height: 42px;
  background: none;
  padding: 0 20px;
  margin-right: 20px;
  color: #5a5a5a;
}

@media only screen and (max-width: 767px) {
  .coupon_inner input {
    margin-bottom: 24px;
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coupon_inner input {
    margin-bottom: 24px;
    width: 100%;
  }
}

.coupon_inner a {
  display: block;
  text-align: right;
  color: #f743a6;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 20px;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 10px;
  border-radius: 3px;
}

.coupon_code {
  border: 1px solid #ebebeb;
}

@media only screen and (max-width: 767px) {
  .coupon_code.left {
    margin-bottom: 59px;
  }
}

.coupon_code h3 {
  color: black;
  line-height: 37px;
  padding: 5px 15px;
  background: lightgray;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
}

@media only screen and (max-width: 767px) {
  .coupon_code h3 {
    line-height: 28px;
    padding: 5px 15px;
    font-size: 15px;
  }
}

.cart_subtotal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cart_subtotal p {
  color: #212121;
  font-weight: 600;
  font-size: 14px;
}

.cart_subtotal p.cart_amount {
  font-size: 18px;
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
  .cart_subtotal p.cart_amount {
    font-size: 14px;
  }
}

.cart_subtotal p span {
  margin-right: 30px;
}

.checkout_btn {
  text-align: right;
}

.checkout_btn a {
  background: #f743a6;
  color: #ffffff;
  font-size: 16px;
  padding: 3px 14px;
  line-height: 30px;
  font-weight: 500;
  display: inline-block;
  text-transform: capitalize;
  margin-bottom: 0;
}

.checkout_btn a:hover {
  background: #212121;
}

.coupon_area {
  margin-bottom: 70px;
}

@media only screen and (max-width: 767px) {
  .coupon_area {
    margin-bottom: 60px;
  }
}

.footer_widgets.other_widgets .footer_top {
  padding-bottom: 0;
  border-bottom: 0;
}

.footer_widgets.other_widgets .footer_top_inner {
  padding: 55px 0 53px;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer_widgets.other_widgets .footer_top_inner {
    padding: 55px 0 59px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer_widgets.other_widgets .footer_top_inner {
    padding: 55px 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .footer_widgets.other_widgets .footer_top_inner {
    padding: 55px 0 35px;
  }
}

/*cart page css end*/
/* 14. checkout page css here */
.user-actions {
  margin-bottom: 20px;
}

.user-actions h3 {
  font-size: 13px;
  font-weight: 400;
  color: #212121;
  background-color: #f7f6f7;
  padding: 15px 10px;
  border-top: 3px solid #f743a6;
  margin-bottom: 0;
}

.user-actions h3 a {
  color: #f743a6;
}

.checkout_info {
  border: 1px solid #ebebeb;
  margin-top: 25px;
  padding: 20px 30px;
}

@media only screen and (max-width: 767px) {
  .checkout_info {
    padding: 20px 20px;
  }
}

.checkout_info p {
  margin-bottom: 0;
}

.checkout_info a {
  color: #f743a6;
  margin-top: 15px;
  display: block;
}

.form_group {
  margin-bottom: 20px;
}

.form_group label {
  color: #212121;
  font-size: 14px;
  display: block;
  line-height: 18px;
  margin-bottom: 7px;
}

.form_group input {
  border: 1px solid #ebebeb;
  background: none;
  height: 45px;
  width: 350px;
  padding: 0 20px;
}

@media only screen and (max-width: 767px) {
  .form_group input {
    width: 100%;
  }
}

.form_group button {
  display: inline-block;
  width: 80px;
  background: #212121;
  border: 0;
  color: #ffffff;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  margin-right: 20px;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
}

.form_group button:hover {
  background: #f743a6;
}

.form_group input[type="checkbox"] {
  width: 15px;
  height: 15px;
  margin-right: 10px;
  position: relative;
  top: 3px;
}

.form_group.group_3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.form_group.group_3 label {
  margin-bottom: 0;
  line-height: 34px;
  cursor: pointer;
}

.form_group.group_3 label:hover {
  color: #f743a6;
}

#checkout_coupon input {
  background: none;
  border: 1px solid #ebebeb;
  width: 200px;
  height: 45px;
  font-size: 12px;
  padding: 0 20px;
  color: #5a5a5a;
}

#checkout_coupon button {
  width: 130px;
  margin-left: 12px;
  background: #212121;
  color: #ffffff;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 13px;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border: 0;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
}

@media only screen and (max-width: 767px) {
  #checkout_coupon button {
    margin-top: 20px;
  }
}

#checkout_coupon button:hover {
  background: #f743a6;
}

.mb-20 {
  margin-bottom: 20px;
}

.checkout_form h3 {
  font-size: 16px;
  line-height: 30px;
  padding: 5px 10px;
  text-transform: uppercase;
  color: #ffffff;
  background: #212121;
  font-weight: 600;
}

.checkout_form input {
  border: 1px solid #ebebeb;
  background: none;
  height: 40px;
  width: 100%;
  padding: 0 20px;
  color: #5a5a5a;
}

.checkout_form .nice-select {
  width: 100%;
}

.checkout_form .nice-select ul.list {
  width: 100%;
  height: 180px;
  overflow: auto;
}

.checkout_form .nice-select::after {
  top: 56%;
}

.checkout_form label {
  color: #242424;

  font-weight: 700;
  margin-bottom: 7px;
}

.checkout_form label span {
  color: #f743a6;
}

.checkout_form label.righ_0 {
  cursor: pointer;
  font-size: 15px;
  line-height: 27px;
  padding: 5px 10px;
  text-transform: capitalize;
  color: #ffffff;
  background: #212121;
  font-weight: 600;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .checkout_form label.righ_0 {
    font-size: 13px;
    line-height: 25px;
    padding: 3px 10px;
  }
}

.checkout_form label.righ_0:hover {
  background: #212121;
}

.checkout_form input[type="checkbox"] {
  width: 15px;
  height: 15px;
  position: relative;
  top: 2px;
  margin-right: 10px;
}

.order_button button {
  cursor: pointer;
  font-size: 16px;
  line-height: 30px;
  padding: 5px 10px;
  text-transform: capitalize;
  color: #ffffff;
  background: #f743a6;
  font-weight: 600;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  margin-bottom: 0;
  border-radius: 3px;
}

.order_button button:hover {
  background: #212121;
}

@media only screen and (max-width: 767px) {
  .order-notes {
    margin-bottom: 56px;
  }
}

.order-notes textarea {
  border: 1px solid #e5e5e5;
  border-radius: 0;
  height: 45px;
  max-width: 100%;
  padding: 0 30px 0 20px;
  background: none;
  font-size: 13px;
  resize: none;
  line-height: 45px;
  width: 100%;
  color: #5a5a5a;
}

.order-notes label {
  line-height: 13px;
}

.Checkout_section {
  padding-bottom: 66px;
}

@media only screen and (max-width: 767px) {
  .Checkout_section {
    padding-bottom: 60px;
  }
}

.order_table {
  margin-bottom: 35px;
}

.order_table table {
  width: 100%;
}

.order_table table thead tr th {
  color: #242424;

  min-width: 50%;
  text-align: center;
  padding: 15px 0;
  border-bottom: 1px solid #ddd;
}

.order_table table tbody tr td {
  min-width: 50%;
  text-align: center;
  padding: 15px 0;
  border-bottom: 1px solid #ddd;
}

.order_table table tfoot tr th {
  color: #242424;

  min-width: 50%;
  text-align: center;
  padding: 15px 0;
  border-bottom: 1px solid #ddd;
}

.order_table table tfoot tr td {
  min-width: 50%;
  text-align: center;
  padding: 15px 0;
  border-bottom: 1px solid #ddd;
}

.panel-default input[type="radio"] {
  width: 15px;
  height: 15px;
  position: relative;
  top: 2px;
  margin-right: 10px;
}

.panel-default img {
  width: 160px;
}

.order_button button {
  border: 0;
}

.card-body1 {
  margin-bottom: 15px;
}

/*checkout page css end*/
/* 22. wishlist css here */
.table_desc.wishlist table tbody tr:last-child td {
  border-bottom: 0;
}

.table_desc.wishlist table tbody tr td.product_total a {
  background: #f743a6;
  font-size: 12px;
  font-weight: 700;
  height: 38px;
  line-height: 18px;
  padding: 10px 20px;
  color: #ffffff;
  text-transform: uppercase;
  border-radius: 3px;
}

.table_desc.wishlist table tbody tr td.product_total a:hover {
  background: #212121;
}

.wishlist_share {
  text-align: center;
  padding: 20px 0;
  border: 1px solid #ebebeb;
}

.wishlist_share h4 {
  font-size: 18px;
  color: #242424;
  font-weight: 700;
  text-transform: capitalize;
}

.wishlist_share ul li {
  display: inline-block;
}

.wishlist_share ul li a {
  padding: 0 10px;
  color: #242424;
  display: block;
}

.wishlist_share ul li a:hover {
  color: #f743a6;
}

.wishlist_area {
  padding-bottom: 70px;
}

@media only screen and (max-width: 767px) {
  .wishlist_area {
    padding-bottom: 60px;
  }
}

/*wishlist css end*/
/* 15. contact page css here */
.contact_area {
  margin-bottom: 70px;
}

@media only screen and (max-width: 767px) {
  .contact_area {
    margin-bottom: 60px;
  }
}

.contact_message h3 {
  font-size: 21px;
  text-transform: capitalize;
  color: #242424;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 25px;
}

@media only screen and (max-width: 767px) {
  .contact_message h3 {
    font-size: 20px;
    margin-bottom: 20px;
  }
}

.contact_message p {
  font-size: 14px;
  line-height: 24px;
  color: #212121;
  margin-bottom: 20px;
}

.contact_message ul li {
  padding: 13px 0;
  border-top: 1px solid #ebebeb;
}

.contact_message ul li:last-child {
  padding-bottom: 0;
}

.contact_message ul li i {
  margin-right: 10px;
}

.contact_message ul li a {
  color: #f743a6;
}

.contact_message ul li a:hover {
  color: #212121;
}

.contact_message label {
  line-height: 18px;
  color: #212121;
  font-weight: 500;
  margin-bottom: 10px;
}

.contact_message input {
  border: 1px solid #ebebeb;
  height: 45px;
  background: #ffffff;
  width: 100%;
  padding: 0 20px;
  color: #5a5a5a;
}

.contact_message textarea {
  height: 170px;
  border: 1px solid #ebebeb;
  background: #ffffff;
  resize: none;
  margin-bottom: 20px;
  width: 100%;
  padding: 10px 20px;
  color: #5a5a5a;
}

.contact_message button {
  font-weight: 400;
  height: 42px;
  line-height: 42px;
  padding: 0 30px;
  text-transform: capitalize;
  border: none;
  background: #242424;
  color: #ffffff;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 4px;
}

.contact_message button:hover {
  background: #f743a6;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact_message.content {
    margin-bottom: 62px;
  }
}

@media only screen and (max-width: 767px) {
  .contact_message.content {
    margin-bottom: 52px;
  }
}

.contact_message.form p.form-messege {
  margin-bottom: 0;
}

.contact_map {
  margin-bottom: 67px;
}

@media only screen and (max-width: 767px) {
  .contact_map {
    margin-bottom: 57px;
  }
}

.map-area #googleMap {
  height: 460px;
  width: 100%;
}

/*contact page css end*/
/* 17. login page css here */
.account_form h2 {
  font-size: 30px;
  text-transform: capitalize;
  color: #212121;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 34px;
}

@media only screen and (max-width: 767px) {
  .account_form h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.account_form form {
  border: 1px solid #ebebeb;
  padding: 23px 20px 29px;
  border-radius: 5px;
}

.account_form label {
  font-size: 15px;
  font-weight: 400;
  color: #212121;
  cursor: pointer;
  line-height: 12px;
  margin-bottom: 12px;
}

.account_form label:hover {
  color: #f743a6;
}

.account_form input {
  border: 1px solid #ebebeb;
  height: 40px;
  max-width: 100%;
  padding: 0 20px;
  background: none;
  width: 100%;
}

.account_form button {
  background: #f743a6;
  border: 0;
  color: #ffffff;
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  height: 34px;
  line-height: 21px;
  padding: 5px 20px;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  margin-left: 20px;
  border-radius: 20px;
}

.account_form button:hover {
  background: #212121;
}

.login_submit label input[type="checkbox"] {
  width: 15px;
  height: 13px;
  margin-right: 3px;
}

.login_submit {
  text-align: right;
}

.login_submit a {
  font-size: 13px;
  color: #f743a6;
  float: left;
  line-height: 39px;
}

@media only screen and (max-width: 767px) {
  .login_submit a {
    float: none;
    line-height: 18px;
    display: block;
    margin-bottom: 20px;
  }
}

.customer_login {
  padding-bottom: 70px;
}

@media only screen and (max-width: 767px) {
  .customer_login {
    padding-bottom: 60px;
  }
}

.account_form p {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .account_form.register {
    margin-top: 57px;
  }
}

/*login page css end */
/* 16. faq page css here */
.faq_content_area {
  padding-bottom: 66px;
}

@media only screen and (max-width: 767px) {
  .faq_content_area {
    padding-bottom: 56px;
  }
}

.accordion_area {
  padding-bottom: 70px;
}

@media only screen and (max-width: 767px) {
  .accordion_area {
    padding-bottom: 60px;
  }
}

.card.card_dipult {
  border: none;
  margin-bottom: 10px;
}

.card.card_dipult:last-child {
  margin-bottom: 0;
}

.card-header.card_accor {
  padding: 0;
  border: none;
}

.card-header.card_accor button {
  height: 45px;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  white-space: initial;
}

@media only screen and (max-width: 767px) {
  .card-header.card_accor button {
    height: 60px;
  }
}

.card-header.card_accor button i {
  position: absolute;
  top: 50%;
  -webkit-transform: translatey(-50%);
  transform: translatey(-50%);
  right: 20px;
  color: #BCBCBC;
}

@media only screen and (max-width: 767px) {
  .card-header.card_accor button i {
    right: 10px;
  }
}

.card-header.card_accor button.btn-link {
  border: 1px solid #f743a6;
  width: 100%;
  text-align: left;
  color: #f743a6;
  font-size: 14px;

  font-weight: 700;
}

.card-header.card_accor button.btn-link.collapsed {
  background: #f2f2f2;
  border: 1px solid #ebebeb;
  width: 100%;
  text-align: left;
  color: #242424;
}

.accordion_area .collapse.show {
  border-left: 1px solid #f743a6;
  border-bottom: 1px solid #f743a6;
  border-right: 1px solid #f743a6;
}

.card-header.card_accor button.btn-link i.fa-plus {
  display: none;
}

.card-header.card_accor button.btn-link.collapsed i.fa-plus {
  display: block;
}

.card-header.card_accor button.btn-link.collapsed i.fa-minus {
  display: none;
}

.faq_content_wrapper h4 {
  font-size: 18px;
  color: #242424;
  font-weight: 700;
  margin-bottom: 15px;
  line-height: 18px;
}

@media only screen and (max-width: 767px) {
  .faq_content_wrapper h4 {
    font-size: 14px;
    line-height: 24px;
  }
}

/*faq page css end*/
/*  18. my account css here */
@media only screen and (max-width: 767px) {
  .dashboard_tab_button {
    margin-bottom: 20px;
  }
}

.dashboard_tab_button ul li {
  margin-bottom: 5px;
}

.dashboard_tab_button ul li a {
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
  text-transform: capitalize;
  background: #212121;
  border-radius: 3px;
}

.dashboard_tab_button ul li a:hover {
  background: #f743a6;
  color: #ffffff;
}

.dashboard_tab_button ul li a.active {
  background: #f743a6;
}

.main_content_area {
  padding-bottom: 65px;
}

@media only screen and (max-width: 767px) {
  .main_content_area {
    padding-bottom: 55px;
  }
}

.dashboard_content h3 {
  font-size: 22px;
  text-transform: capitalize;
  font-weight: 700;
  color: #242424;
  margin-bottom: 15px;
}

.dashboard_content h4 {
  font-size: 20px;
  text-transform: capitalize;
  color: #212121;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 10px;
}

.dashboard_content a {
  color: #f743a6;
  font-weight: 500;
}

.dashboard_content p a {
  color: #f743a6;
  font-weight: 500;
}

.table-responsive table thead {
  background: #f2f2f2;
}

.table-responsive table thead tr th {
  text-align: center;
  color: #242424;

}

.table-responsive table tbody tr td {
  border-right: 1px solid #ebebeb;
  color: #212121;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 14px;
  text-align: center;
  min-width: 150px;
}

.table-responsive table tbody tr td:last-child a {
  color: #f743a6;
}

.table-responsive .table {
  border-left: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
}

.dashboard_content address {
  font-weight: 500;
  color: #212121;
}

.input-radio span input[type="radio"],
.account_login_form form span input[type="checkbox"] {
  width: 15px;
  height: 15px;
  margin-right: 2px;
  position: relative;
  top: 2px;
}

.input-radio span {
  color: #212121;
  font-weight: 500;
  padding-right: 10px;
}

.account_login_form form input {
  border: 1px solid #ddd;
  background: none;
  height: 40px;
  margin-bottom: 20px;
  width: 100%;
  padding: 0 20px;
  color: #5a5a5a;
}

/*my account css end*/
/* 10. about page css here */
/*welcome about section css here*/
/*instagram area*/
.instagram_area {
  padding-bottom: 66px;
}

@media only screen and (max-width: 767px) {
  .instagram_area {
    padding-bottom: 56px;
  }
}

.instagram_wrapper {
  display: flex;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .instagram_wrapper {
    display: inherit;
    overflow: hidden;
  }
}

@media only screen and (max-width: 767px) {
  .single_instagram {
    width: 50%;
    float: left;
  }
}

@media only screen and (max-width: 479px) {
  .single_instagram {
    width: 100%;
    float: left;
  }
}

@media only screen and (max-width: 479px) {
  .single_instagram a {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) and (max-width: 479px) {
  .single_instagram a img {
    width: 100%;
  }
}

.instagram_btn {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 50%;
  transform: translatey(-50%);
}

.instagram_btn a {
  background: #a8741a;
  color: #ffffff;
  display: inline-block;
  font-size: 13px;
  line-height: 19px;
  padding: 8px 15px;
  border-radius: 4px;
}

.instagram_btn a:hover {
  background: #359dff;
}

/*instagram end*/
/* 30. about page css here */
.about_section {
  padding-bottom: 61px;
}

@media only screen and (max-width: 767px) {
  .about_section {
    padding-bottom: 49px;
  }
}

.about_content {
  text-align: center;
}

.about_content h1 {
  display: inline-block;
  font-size: 24px;
  line-height: 24px;
  text-transform: capitalize;
  font-weight: 700;
  color: #242424;
  margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
  .about_content h1 {
    font-size: 20px;
  }
}

.about_content p {
  color: #5a5a5a;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 26px;
}

@media only screen and (max-width: 767px) {
  .about_content p {
    margin-bottom: 8px;
  }
}

.about_content span {
  font-size: 14px;
  color: #f743a6;
  font-style: italic;
}

.about_thumb {
  margin-bottom: 21px;
}

.chose_title {
  text-align: center;
  margin-bottom: 7px;
}

@media only screen and (max-width: 767px) {
  .chose_title {
    margin-bottom: 0;
  }
}

.chose_title h1 {
  color: #242424;
  display: inline-block;
  font-size: 24px;
  line-height: 24px;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .chose_title h1 {
    font-size: 20px;
  }
}

.testimonial_are {
  padding-bottom: 64px;
}

@media only screen and (max-width: 767px) {
  .testimonial_are {
    padding-bottom: 63px;
  }
}

.chose_content h3 {
  font-size: 14px;
  text-transform: capitalize;
  color: #242424;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 15px;
}

.choseus_area {
  margin-bottom: 65px;
}

@media only screen and (max-width: 767px) {
  .choseus_area {
    margin-bottom: 55px;
  }
}

.single_chose {
  text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single_gallery_section {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .single_gallery_section {
    margin-bottom: 25px;
  }
}

.about_gallery_section {
  margin-bottom: 61px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about_gallery_section {
    margin-bottom: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .about_gallery_section {
    margin-bottom: 23px;
  }
}

.gallery_thumb {
  margin-bottom: 18px;
}

@media only screen and (max-width: 767px) {
  .gallery_thumb img {
    width: 100%;
  }
}

.about_gallery_content {
  text-align: center;
}

.about_gallery_content h3 {
  font-size: 14px;
  text-transform: capitalize;
  color: #242424;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 10px;
}

.about_gallery_content p {
  line-height: 26px;
}

.testimonial_titile {
  text-align: center;
  margin-bottom: 25px;
}

@media only screen and (max-width: 767px) {
  .testimonial_titile {
    margin-bottom: 15px;
  }
}

.testimonial_titile h1 {
  display: inline-block;
  font-size: 24px;
  line-height: 24px;
  text-transform: capitalize;
  font-weight: 700;
  color: #242424;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .testimonial_titile h1 {
    font-size: 20px;
  }
}

.testimonial_active.owl-carousel .single_testimonial img {
  width: inherit;
  margin: 0 auto;
  margin-bottom: 15px;
}

.testimonial_active.owl-carousel .owl-dots {
  position: relative;
  width: 100%;
  text-align: center;
  top: 30px;
}

@media only screen and (max-width: 767px) {
  .testimonial_active.owl-carousel .owl-dots {
    top: 10px;
  }
}

.testimonial_active.owl-carousel .owl-dots .owl-dot {
  width: 10px;
  height: 10px;
  background: #ebebeb;
  border-radius: 100%;
  margin: 0 4px;
  display: inline-block;
}

.testimonial_active.owl-carousel .owl-dots .owl-dot.active {
  background: #f743a6;
}

.single_testimonial {
  text-align: center;
}

.single_testimonial p {
  padding: 0 145px;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 35px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single_testimonial p {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .single_testimonial p {
    padding: 0;
    font-size: 15px;
    margin-bottom: 20px;
  }
}

.single_testimonial span {
  display: block;
  margin-bottom: 20px;
  line-height: 16px;
}

@media only screen and (max-width: 767px) {
  .single_testimonial span {
    margin-bottom: 12px;
  }
}

.single_testimonial span.name {
  font-weight: 700;
  color: #242424;
  text-transform: uppercase;

}

/*about page css end*/
/* 20. services page css here */
.services_gallery {
  padding-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  .services_gallery {
    padding-bottom: 30px;
  }
}

.services_content h3 {
  font-size: 15px;
  color: #212121;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 11px;
  line-height: 14px;
}

.services_content p {
  color: #5a5a5a;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 0;
}

.services_thumb {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .services_thumb img {
    width: 100%;
  }
}

.single_services {
  margin-bottom: 25px;
}

.our_services {
  padding: 58px 0 34px;
  background: #ECECEC;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .our_services {
    margin-bottom: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .our_services {
    padding: 49px 0 25px;
    margin-bottom: 60px;
  }
}

.services_item {
  margin-bottom: 27px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.services_title {
  text-align: center;
  margin-bottom: 35px;
}

.services_title h2 {
  font-size: 25px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 11px;
  color: #212121;
}

@media only screen and (max-width: 767px) {
  .services_title h2 {
    font-size: 18px;
  }
}

.services_icone {
  margin-right: 15px;
}

.services_icone i {
  font-size: 35px;
  line-height: 46px;
  color: #f743a6;
}

.services_desc h3 {
  font-size: 13px;
  color: #212121;
  font-weight: 500;
}

.services_desc p {
  color: #5a5a5a;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .services_section_thumb {
    margin-bottom: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .services_section_thumb {
    margin-bottom: 28px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .unlimited_services {
    margin-bottom: 69px;
  }
}

@media only screen and (max-width: 767px) {
  .unlimited_services {
    margin-bottom: 59px;
  }
}

.unlimited_services_content h1 {
  font-weight: 600;
  line-height: 40px;
  text-transform: uppercase;
  color: #212121;
  font-size: 25px;
  margin-bottom: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .unlimited_services_content h1 {
    line-height: 25px;
    font-size: 20px;
    margin-bottom: 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .unlimited_services_content h1 {
    line-height: 21px;
    font-size: 20px;
    margin-bottom: 6px;
  }
}

@media only screen and (max-width: 767px) {
  .unlimited_services_content h1 {
    line-height: 20px;
    font-size: 18px;
    margin-bottom: 6px;
  }
}

.unlimited_services_content p {
  color: #5a5a5a;
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 25px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .unlimited_services_content p {
    font-size: 14px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .unlimited_services_content p {
    font-size: 13px;
    margin-bottom: 15px;
  }
}

.unlimited_services_content a {
  font-size: 14px;
  color: #242424;
  padding: 6px 20px;
  display: inline-block;
  border: 2px solid #242424;
  border-radius: 20px;
}

.unlimited_services_content a:hover {
  background: #f743a6;
  border-color: #f743a6;
  color: #fff;
}

.priceing_table {
  background: #ECECEC;
  padding: 70px 0 40px;
}

@media only screen and (max-width: 767px) {
  .priceing_table {
    padding: 60px 0 30px;
  }
}

.single_priceing {
  background: #fff;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px #FFFFFF inset, 0 1px 3px rgba(34, 25, 25, 0.4);
  box-shadow: 0 1px #FFFFFF inset, 0 1px 3px rgba(34, 25, 25, 0.4);
  text-align: center;
  margin-bottom: 30px;
}

.priceing_title {
  padding: 20px;
  background: #f743a6;
}

.priceing_title h1 {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0;
}

.priceing_list {
  padding: 0 20px 30px;
}

.priceing_list h1 {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 36px 0 24px;
  margin: 0;
  line-height: 20px;
  color: #5a5a5a;
}

.priceing_list h1 span {
  color: #212121;
  font-size: 40px;
}

.priceing_list ul li {
  color: #5a5a5a;
  padding: 15px 0;
  border-bottom: 1px solid #ebebeb;
  line-height: 24px;
}

.priceing_list ul li:first-child {
  border-top: 1px solid #ebebeb;
}

.priceing_list a {
  margin: 30px 0 0;
  line-height: 40px;
  padding: 0 20px;
  border: 1px solid #212121;
  color: #212121;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.priceing_list a.list_button,
.priceing_list a:hover {
  background: #f743a6;
  border-color: #f743a6;
  color: #fff;
}

.advantages_ecommerce {
  padding: 64px 0 64px;
}

@media only screen and (max-width: 767px) {
  .advantages_ecommerce {
    padding: 58px 0 60px;
  }
}

.advantages_ecommerce h3 {
  font-size: 25px;
  color: #212121;
  font-weight: 500;
  text-transform: capitalize;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .advantages_ecommerce h3 {
    font-size: 20px;
    line-height: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .advantages_ecommerce h3 {
    font-size: 14px;
    line-height: 20px;
  }
}

.advantages_ecommerce p {
  font-size: 14px;
  color: #212121;
  font-style: italic;
  font-weight: 400;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .advantages_ecommerce p {
    font-size: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .advantages_ecommerce p {
    font-size: 13px;
  }
}

.advantages_button {
  text-align: center;
}

.advantages_button a {
  font-size: 13px;
  color: #212121;
  padding: 7px 20px;
  display: inline-block;
  border: 2px solid #212121;
  border-radius: 20px;
}

.advantages_button a:hover {
  background: #f743a6;
  color: #fff;
  border-color: #f743a6;
}

@media only screen and (max-width: 767px) {
  .advantages_button a {
    padding: 5px 11px;
    font-size: 13px;
    margin-top: 20px;
  }
}

/*services page css end*/
/* 19. portfolio page css here */
.portfolio_content {
  background: #f9f9f9;
  padding: 30px 12px 34px;
}

@media only screen and (max-width: 767px) {
  .portfolio_content {
    padding: 21px 12px 26px;
  }
}

.portfolio_content h3 {
  font-size: 18px;
  line-height: 24px;
  color: #242424;
  font-weight: 700;
  margin-bottom: 14px;
}

@media only screen and (max-width: 767px) {
  .portfolio_content h3 {
    margin-bottom: 10px;
  }
}

.portfolio_content p {
  line-height: 26px;
  margin-bottom: 17px;
}

@media only screen and (max-width: 767px) {
  .portfolio_content p {
    margin-bottom: 13px;
  }
}

.portfolio_content a {
  padding: 11px 22px;
  font-size: 14px;
  border-radius: 30px;
  background: rgba(0, 0, 0, 0.45);
  color: #fff;
  display: inline-block;
}

.portfolio_content a:hover {
  background: #f743a6;
}

@media only screen and (max-width: 767px) {
  .portfolio_content a {
    padding: 8px 20px;
  }
}

.single_portfolio {
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .single_portfolio {
    margin-bottom: 60px;
  }
}

.portfolio_area {
  margin-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  .portfolio_area {
    margin-bottom: 0;
  }
}

/* 11. blog page css here */
.blog_page {
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .blog_page {
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_page {
    margin-bottom: 0;
  }
}

.blog_page .blog_grid {
  margin-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  .blog_page .blog_grid {
    margin-bottom: 60px;
  }
}

.blog_page .blog_grid:last-child {
  margin-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  .blog_page .blog_grid:last-child {
    margin-bottom: 60px;
  }
}

.blog_page .blog_grid .blog_thumb {
  float: inherit;
  width: 100%;
  margin-bottom: 24px;
}

.blog_page .blog_grid .blog_content {
  float: inherit;
  padding-left: 0;
  width: 100%;
}

.blog_page .blog_grid .blog_thumb_active {
  float: inherit;
  width: 100%;
  margin-bottom: 24px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_page .blog_grid_area {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .blog_page .blog_grid_area {
    margin-bottom: 0;
  }
}

/*blog left css here*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_grid_area {
    margin-bottom: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .blog_grid_area {
    margin-bottom: 60px;
  }
}

.breadcrumb_content h3 {
  color: #242424;
  font-size: 36px;
  text-transform: capitalize;
  font-weight: 700;
  line-height: 48px;
}

@media only screen and (max-width: 767px) {
  .breadcrumb_content h3 {
    font-size: 25px;
    line-height: 22px;
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_sidebar {
    margin-bottom: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .blog_sidebar {
    margin-bottom: 60px;
  }
}

.blog_sidebar .sidebar_widget {
  border-bottom: 1px solid #ebebeb;
}

.blog_sidebar .sidebar_widget:last-child {
  border-bottom: 0;
}

.blog_sidebar h3.widget_title {
  color: #242424;
  font-size: 24px;
  margin: 0 0 20px;
  font-weight: 700;
  line-height: 32px;
}

.blog_sidebar .search_form {
  padding-bottom: 35px;
  margin-bottom: 27px;
}

.blog_sidebar .search_form form {
  position: relative;
}

.blog_sidebar .search_form form input {
  width: 100%;
  height: 40px;
  padding: 0 40px 0 20px;
  color: #5a5a5a;
  border: 1px solid #ebebeb;
}

.blog_sidebar .search_form form button {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  height: 100%;
  background: inherit;
  border: 0;
  color: #808080;
  font-size: 20px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.blog_sidebar .search_form form button:hover {
  color: #f743a6;
}

.blog_sidebar .widget_categories {
  padding-bottom: 30px;
  margin-bottom: 26px;
}

.blog_sidebar .widget_categories ul li {
  margin-bottom: 5px;
}

.blog_sidebar .widget_categories ul li:last-child {
  margin-bottom: 0;
}

.blog_sidebar .widget_categories ul li a {
  color: #242424;
  font-size: 14px;
  line-height: 26px;
}

.blog_sidebar .widget_categories ul li a:hover {
  color: #f743a6;
}

.blog_sidebar .recent_post {
  padding-bottom: 35px;
  margin-bottom: 26px;
}

.blog_sidebar .sidebar_post {
  margin-bottom: 22px;
  display: flex;
  align-items: center;
}

.blog_sidebar .sidebar_post:last-child {
  margin-bottom: 0;
}

.blog_sidebar .sidebar_post .post_img {
  width: 55px;
  margin-right: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog_sidebar .sidebar_post .post_img {
    width: 49px;
    margin-right: 8px;
  }
}

.blog_sidebar .sidebar_post .post_text h3 {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .blog_sidebar .sidebar_post .post_text h3 {
    font-size: 13px;
  }
}

.blog_sidebar .sidebar_post .post_text h3 a {
  color: #242424;
}

.blog_sidebar .sidebar_post .post_text h3 a:hover {
  color: #f743a6;
}

.blog_sidebar .sidebar_post .post_text span {
  color: #a7a7a7;
  font-size: 12px;
}

.blog_sidebar .popular_post {
  padding-bottom: 35px;
  margin-bottom: 26px;
}

.blog_grid {
  overflow: hidden;
  margin-bottom: 45px;
}

.blog_grid:last-child {
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_grid {
    margin-bottom: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .blog_grid {
    margin-bottom: 60px;
  }
}

.blog_grid .blog_thumb {
  float: left;
  width: 49%;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .blog_grid .blog_thumb {
    float: inherit;
    width: 100%;
    margin-bottom: 21px;
  }
}

.blog_grid .blog_thumb a img {
  width: 100%;
}

.blog_grid .blog_thumb_active {
  float: left;
  width: 49%;
}

@media only screen and (max-width: 767px) {
  .blog_grid .blog_thumb_active {
    float: inherit;
    width: 100%;
    margin-bottom: 20px;
  }
}

.blog_grid .blog_thumb_active:hover .owl-nav div {
  opacity: 1;
  left: 10px;
}

.blog_grid .blog_thumb_active:hover .owl-nav div.owl-next {
  right: 10px;
}

.blog_grid .blog_thumb_active:hover .owl-dots {
  opacity: 1;
}

/* .blog_grid .blog_thumb_active .owl-nav div {
  position: absolute;
  top: 50%;
  display: block;
  width: 40px;
  height: 40px;
  left: 30px;
  margin-top: -20px !important;
  background: black 0 0 url("../img/icon/blog-nav.png") no-repeat;
  background-color: black;
  background-position-x: 0px;
  background-position-y: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  filter: alpha(opacity=0);
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
} */
.blog_grid .blog_thumb_active .owl-nav div i {
  display: none;
}

.blog_grid .blog_thumb_active .owl-nav div.owl-next {
  left: auto;
  right: 30px;
  background-position: -40px 0;
}

.blog_grid .blog_thumb_active .owl-dots {
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translatey(-50%);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
  left: 60px;
  right: 60px;
}

.blog_grid .blog_thumb_active .owl-dots .owl-dot {
  width: 10px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  display: inline-block;
  margin: 0 2px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.blog_grid .blog_thumb_active .owl-dots .owl-dot:hover {
  background: #fff;
}

.blog_grid .blog_content {
  float: right;
  padding-left: 30px;
  width: 51%;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .blog_grid .blog_content {
    float: inherit;
    width: 100%;
    padding-left: 0;
  }
}

.blog_grid .blog_content h3.post_title {
  font-size: 24px;
  text-transform: inherit;
  font-weight: 700;
  margin-bottom: 18px;
  line-height: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_grid .blog_content h3.post_title {
    font-size: 18px;
    margin-bottom: 7px;
  }
}

@media only screen and (max-width: 767px) {
  .blog_grid .blog_content h3.post_title {
    font-size: 15px;
  }
}

.blog_grid .blog_content h3.post_title a {
  color: #242424;
}

.blog_grid .blog_content h3.post_title a:hover {
  color: #f743a6;
}

.blog_grid.video .blog_thumb {
  height: 240px;
}

.blog_left {
  margin-bottom: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog_left {
    margin-bottom: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_left .row {
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 767px) {
  .blog_left .row {
    flex-direction: column-reverse;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_left {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .blog_left {
    margin-bottom: 0;
  }
}

.post_date {
  margin-bottom: 12px;
}

.post_date span.day {
  color: #a4a4a4;
  font-size: 48px;
  line-height: 40px;
}

.post_date span.month {
  font-size: 14px;
  color: #a4a4a4;
}

.blog_content p.post_desc {
  color: #5a5a5a;
  font-size: 14px;
  line-height: 25px;
  margin-bottom: 21px;
}

.blog_content a.read_more {
  background: #292929;
  color: #fff;
  padding: 0 25px;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  height: 42px;
  line-height: 42px;
  text-transform: capitalize;
  border-radius: 3px;
}

.blog_content a.read_more:hover {
  background: #f743a6;
}

.post_meta {
  margin-bottom: 10px;
}

.post_meta span {
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 5px;
  margin-left: 5px;
  color: #a7a7a7;
}

.post_meta span i {
  margin-right: 3px;
}

.post_meta span a {
  color: #f743a6;
}

.post_meta span a:hover {
  color: #242424;
}

.blog_pagination {
  margin-bottom: 70px;
}

.blog_pagination .pagination {
  border: 1px solid #ebebeb;
  padding: 8px 10px;
  justify-content: center;
  margin-top: 0;
}

@media only screen and (max-width: 767px) {
  .blog_pagination {
    margin-bottom: 60px;
  }
}

/*blog none sidebar*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_none .blog_grid_area {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .blog_none .blog_grid_area {
    margin-bottom: 0;
  }
}

.blog_none .blog_grid .blog_thumb {
  float: inherit;
  width: 100%;
}

.blog_none .blog_grid .blog_content {
  float: inherit;
  padding-left: 0;
  width: 100%;
}

.blog_none .blog_grid .blog_thumb_active {
  float: inherit;
  width: 100%;
}

/*blog fullwidth css here*/
.blog_fullwidth {
  margin-bottom: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_fullwidth {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .blog_fullwidth {
    margin-bottom: 0;
  }
}

.blog_fullwidth .blog_grid .blog_thumb {
  float: inherit;
  width: 100%;
  margin-bottom: 20px;
}

.blog_fullwidth .blog_grid .blog_content {
  float: inherit;
  width: 100%;
  padding-left: 0;
}

.blog_fullwidth .blog_grid .blog_thumb_active {
  float: inherit;
  width: 100%;
  margin-bottom: 20px;
}

.blog_fullwidth .blog_grid.video .blog_thumb {
  height: 450px;
}

@media only screen and (max-width: 767px) {
  .blog_fullwidth .blog_grid.video .blog_thumb {
    height: 160px;
  }
}

/*blog page css end*/
/*blog right sidebar*/
.blog_right {
  margin-bottom: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_right {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .blog_right {
    margin-bottom: 0;
  }
}

/*blog stycky  css heer*/
.blog_area.sticky {
  margin-bottom: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_area.sticky {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .blog_area.sticky {
    margin-bottom: 0;
  }
}

.blog_grid .blog_thumb::before {
  display: none;
}

.blog_grid.blog_sticky .blog_thumb {
  float: inherit;
  width: 100%;
  margin-bottom: 20px;
}

.blog_grid.blog_sticky .blog_content {
  float: inherit;
  width: 100%;
  padding-left: 0;
}

/* 12. blog details css here */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_details_wrapper {
    margin-bottom: 38px;
  }
}

@media only screen and (max-width: 767px) {
  .blog_details_wrapper {
    margin-bottom: 60px;
  }
}

.blog_details_wrapper .blog_thumb {
  margin-bottom: 31px;
}

.blog_details_wrapper .blog_thumb::before {
  display: none;
}

.blog_details_wrapper .blog_content {
  text-align: left;
}

.blog_details_wrapper .blog_content h3 {
  font-size: 24px;
  text-transform: inherit;
  font-weight: 700;
  margin-bottom: 18px;
  line-height: 30px;
  color: #242424;
}

.blog_details_wrapper .post_content>p {
  color: #5a5a5a;
  line-height: 24px;
  margin-bottom: 10px;
  font-size: 15px;
}

.blog_details_wrapper .post_content blockquote {
  border-left: 4px solid #f743a6;
  color: #777;
  margin: 20px 0 20px 0;
  padding: 0 20px;
  font-style: italic;
}

.blog_details_wrapper .social_sharing ul li {
  display: inline-block;
}

.blog_details_wrapper .social_sharing ul li a {
  background: #e6e6e6;
  border-radius: 100%;
  display: inline-block;
  font-size: 12px;
  height: 26px;
  line-height: 26px;
  margin-right: 5px;
  text-align: center;
  width: 26px;
  color: #f743a6;
}

.blog_details_wrapper .social_sharing ul li a:hover {
  color: #ffffff;
  background: #f743a6;
}

.blog_details_wrapper .social_sharing ul li:first-child a {
  padding-left: 0;
  border-left: 0;
}

.entry_content {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 26px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .entry_content {
    flex-direction: column;
  }
}

.entry_content .post_meta {
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .entry_content .post_meta {
    margin-bottom: 10px;
  }
}

.comments_box {
  margin-bottom: 36px;
  border-top: 1px solid #ebebeb;
  padding-top: 17px;
}

@media only screen and (max-width: 767px) {
  .comments_box {
    margin-bottom: 51px;
  }
}

.comments_box h3 {
  color: #242424;
  font-size: 24px;
  margin: 0 0 20px;
  font-weight: 700;
  line-height: 32px;
}

.comment_list {
  overflow: hidden;
  margin-bottom: 30px;
}

.comment_list:last-child {
  margin-bottom: 0;
}

.comment_list .comment_thumb {
  display: inline-block;
  float: left;
}

@media only screen and (max-width: 767px) {
  .comment_list .comment_thumb {
    width: 40px;
  }
}

.comment_list .comment_content {
  margin-left: 70px;
  position: relative;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  padding: 15px;
}

@media only screen and (max-width: 767px) {
  .comment_list .comment_content {
    margin-left: 55px;
  }
}

.comment_list .comment_content h5 {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 5px;

}

.comment_list .comment_content h5 a {
  color: #f743a6;
}

.comment_list .comment_content h5 a:hover {
  color: #242424;
}

.comment_list .comment_content span {
  color: #5a5a5a;
  line-height: 18px;
  margin-bottom: 8px;
  font-size: 12px;
  font-style: italic;
  display: inline-block;
}

.comment_list .comment_content p {
  margin-bottom: 0;
  font-size: 14px;
  color: #5a5a5a;
}

.comment_reply {
  position: absolute;
  top: 20px;
  right: 12px;
}

.comment_reply a {
  padding: 2px 8px;
  border-radius: 3px;
  color: #fff;
  background: #333;
}

.comment_reply a:hover {
  background: #f743a6;
}

.comments_form h3 {
  color: #242424;
  font-size: 24px;
  margin: 0 0 20px;
  font-weight: 700;
  line-height: 32px;
}

.comments_form p {
  color: #5a5a5a;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 12px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .comments_form form .row {
    flex-direction: row;
  }
}

.comments_form form label {
  color: #242424;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}

.comments_form form textarea {
  width: 100%;
  height: 200px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  margin-bottom: 10px;
  resize: none;
  padding: 10px;
}

.comments_form form input {
  width: 100%;
  height: 40px;
  border: 1px solid #ebebeb;
  margin-bottom: 15px;
  padding: 0 20px;
}

.comments_form form button {
  border: 0;
  line-height: 36px;
  background: #333;
}

.comments_form form button:hover {
  background: #f743a6;
}

.comment_list.list_two {
  padding-left: 50px;
}

@media only screen and (max-width: 767px) {
  .comment_list.list_two {
    padding-left: 0;
  }
}

.related_posts {
  margin-bottom: 52px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .related_posts .row {
    flex-direction: row !important;
  }
}

.related_posts h3 {
  color: #242424;
  font-size: 24px;
  margin: 0 0 20px;
  font-weight: 700;
  line-height: 32px;
}

.related_thumb {
  margin-bottom: 20px;
}

.related_content h3 {
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  margin-bottom: 5px;
}

.related_content h3 a {
  color: #242424;
}

.related_content h3 a:hover {
  color: #f743a6;
}

.related_content span {
  color: #a7a7a7;
  font-size: 13px;
  line-height: 17px;
}

.related_content span i {
  margin-right: 3px;
}

.blog_details {
  margin-bottom: 70px;
}

@media only screen and (max-width: 767px) {
  .blog_details {
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_details .blog_sidebar {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .blog_details .blog_sidebar {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .single_related {
    margin-bottom: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single_related.column_3 {
    margin-top: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .single_related.column_3 {
    margin-bottom: 0;
  }
}

/*blog details css end*/
/*  24. modal css here */
.modal-dialog.modal-dialog-centered {
  min-width: 870px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modal-dialog.modal-dialog-centered {
    min-width: 730px;
  }
}

@media only screen and (max-width: 767px) {
  .modal-dialog.modal-dialog-centered {
    min-width: 90%;
  }
}

.modal_tab_img {
  text-align: center;
  margin-bottom: 10px;
}

.modal_tab_img img {
  margin: 0 auto;
}

.modal_tab_button ul li a {
  padding: 0;
  border: 1px solid #ebebeb;
  margin: 0 2px;
}

.modal_tab_button ul li a img {
  width: 100%;
}

.modal_title h2 {
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 17px;
  color: #242424;
  line-height: 26px;
}

.see_all {
  margin-bottom: 27px;
}

.see_all a {
  color: #5a5a5a;
  text-decoration: underline;
}

.see_all a:hover {
  color: #f743a6;
}

.modal_social h2 {
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 15px;
  color: #242424;
  line-height: 20px;
}

.modal_social ul li {
  display: inline-block;
  margin-right: 3px;
}

.modal_social ul li a {
  text-transform: uppercase;
  display: inline-block;
  border: 1px solid #ddd;
  width: 38px;
  height: 38px;
  text-align: center;
  line-height: 36px;
  border-radius: 50%;
  font-size: 15px;
  color: #212121;
  font-weight: 400;
}

.modal_social ul li a:hover {
  background: #f743a6;
  color: #ffffff;
  border-color: #f743a6;
}

.modal_price {
  margin-bottom: 16px;
}

.modal_price span {
  font-weight: 600;
  color: #f743a6;
  font-size: 16px;
}

.modal_price span.old_price {
  color: #5a5a5a;
  font-size: 14px;
  font-weight: 400;
  text-decoration: line-through;
  margin-left: 5px;
}

.modal_description p {
  line-height: 24px;
  font-size: 15px;
  color: #5a5a5a;
  margin: 0;
}

.modal_add_to_cart {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 30px;
}

.modal_add_to_cart form input {
  width: 80px;
  border: 1px solid #ebebeb;
  background: none;
  padding: 0 17px;
  border-radius: 5px;
  height: 40px;
}

.modal_add_to_cart form button {
  background: none;
  border: 1px solid #212121;
  margin-left: 10px;
  font-size: 12px;
  font-weight: 700;
  height: 38px;
  line-height: 18px;
  padding: 10px 15px;
  text-transform: uppercase;
  background: #212121;
  color: #ffffff;
  border-radius: 5px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
}

.modal_add_to_cart form button:hover {
  background: #f743a6;
  color: #ffffff;
  border-color: #f743a6;
}

.modal_body {
  padding: 0 6px 38px;
}

@media only screen and (max-width: 767px) {
  .modal_body {
    padding: 0 6px 38px;
  }
}

@media only screen and (max-width: 767px) {
  .modal_body .modal_tab {
    margin-bottom: 60px;
  }
}

.modal-content button.close {
  position: relative;
  left: 94%;
  width: 30px;
  height: 30px;
  display: block;
  border: 1px solid #ebebeb;
  top: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .modal-content button.close {
    left: 83%;
    width: 29px;
    height: 32px;
    top: 4px;
    margin-bottom: 14px;
  }
}

.modal_add_to_cart.mb-15 {
  margin-bottom: 23px;
}

.modal_description.mb-15 {
  margin-bottom: 25px;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 23px;
}

.product_navactive.owl-carousel:hover .owl-nav div {
  opacity: 1;
  visibility: visible;
}

.product_navactive.owl-carousel .owl-nav {
  display: block;
}

.product_navactive.owl-carousel .owl-nav div {
  position: absolute;
  background: #f2f2f2;
  border-radius: 3px;
  color: #333;
  height: 32px;
  top: 50%;
  transform: translatey(-50%);
  width: 32px;
  text-align: center;
  line-height: 32px;
  left: -7px;
  font-size: 18px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
}

.product_navactive.owl-carousel .owl-nav div:hover {
  background: #f743a6;
  color: #ffffff;
}

.product_navactive.owl-carousel .owl-nav div.owl-next {
  right: -7px;
  left: auto;
}

/*modal css end*/
/*  23. newsletter popup css here */
.newletter-popup {
  background: #fff;
  top: 50% !important;
  margin-top: -179px;
  position: fixed !important;
  padding: 50px;
  text-align: center;
  display: none;
}

@media only screen and (max-width: 767px) {
  .newletter-popup {
    padding: 30px 20px;
    width: 95%;
  }
}

#boxes .newletter-title h2 {
  font-size: 30px;
  text-transform: uppercase;
  color: #242424;
  margin: 0 0 30px;
}

@media only screen and (max-width: 767px) {
  #boxes .newletter-title h2 {
    font-size: 22px;
    margin: 0 0 10px;
  }
}

#boxes .box-content label {
  font-weight: 400;
  font-size: 14px;
}

#boxes .box-content .newletter-label {
  width: 70%;
  margin-bottom: 36px;
}

@media only screen and (max-width: 767px) {
  #boxes .box-content .newletter-label {
    width: 100%;
  }
}

#frm_subscribe form {
  width: 340px;
  margin: 0 auto;
  position: relative;
}

@media only screen and (max-width: 767px) {
  #frm_subscribe form {
    width: 100%;
  }
}

#frm_subscribe form input {
  background: #EBEBEB;
  padding: 0 122px 0 20px;
  height: 45px;
  border: 0;
  width: 100%;
  color: #747474;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  #frm_subscribe form input {
    padding: 0 104px 0 20px;
  }
}

#frm_subscribe form a.theme-btn-outlined {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 110px;
  background: #f743a6;
  cursor: pointer;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  line-height: 45px;
}

#frm_subscribe form a.theme-btn-outlined:hover {
  background: #242424;
}

@media only screen and (max-width: 767px) {
  #frm_subscribe form a.theme-btn-outlined {
    width: 90px;
  }
}

#boxes .box-content .subscribe-bottom {
  margin-top: 20px;
}

#boxes .box-content .subscribe-bottom label {
  margin: 0;
  font-size: 13px;
}

#boxes .box-content .subscribe-bottom #newsletter_popup_dont_show_again {
  display: inline-block;
  margin: 0;
  vertical-align: middle;
}

#boxes #frm_subscribe #notification {
  color: #f00;
}

#boxes #frm_subscribe #notification .success {
  color: #67D7BB;
}

#popup2 {
  position: absolute;
  right: 1px;
  top: 1px;
  text-align: center;
  cursor: pointer;
  font-size: 13px;
  text-transform: capitalize;
  padding: 6px 12px;
  background: #242424;
  font-weight: 600;
  line-height: 17px;
  color: #ffffff;
  display: block;
  transition: 0.3s;
}

#popup2:hover {
  background: #f743a6;
}

/*newsletter popup css end*/
/* 09. error page css heer   */
.header_error {
  border-bottom: 1px solid #ebebeb;
}

.error_form {
  text-align: center;
}

.error_form h1 {
  font-size: 200px;
  font-weight: 700;
  color: #f743a6;
  letter-spacing: 10px;
  line-height: 160px;
  margin: 0 0 60px;
}

@media only screen and (max-width: 767px) {
  .error_form h1 {
    font-size: 130px;
    line-height: 120px;
    letter-spacing: 4px;
    margin: 0 0 18px;
  }
}

.error_form h2 {
  color: #212121;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 24px;
}

@media only screen and (max-width: 767px) {
  .error_form h2 {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 23px;
  }
}

.error_form p {
  font-size: 17px;
  color: #5a5a5a;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .error_form p {
    font-size: 15px;
    margin-bottom: 28px;
    line-height: 23px;
  }
}

.error_form form {
  width: 450px;
  margin: 0 auto;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .error_form form {
    width: 100%;
  }
}

.error_form form input {
  padding: 0 60px 0 30px;
  background: #f8f8f8;
  border: 1px solid #ddd;
  color: #5a5a5a;
  height: 40px;
  width: 100%;
}

.error_form form button {
  position: absolute;
  right: 0;
  height: 100%;
  border: none;
  background: no-repeat;
  font-size: 20px;
  color: #5a5a5a;
  cursor: pointer;
  -webkit-transition: .3s;
  transition: .3s;
  top: 0;
  text-transform: uppercase;
  padding: 0 15px;
  font-weight: 600;
}

.error_form form button:hover {
  color: #f743a6;
}

.error_form a {
  color: #fff;
  display: inline-block;
  background: #f743a6;
  font-size: 12px;
  font-weight: bold;
  height: 40px;
  line-height: 42px;
  padding: 0 30px;
  text-transform: uppercase;
  margin-top: 35px;
  border-radius: 3px;
}

.error_form a:hover {
  background: #212121;
}

.error_section {
  padding-bottom: 120px;
  padding-top: 61px;
}

@media only screen and (max-width: 767px) {
  .error_section {
    padding-bottom: 70px;
    padding-top: 0;
  }
}

/*404 page css end*/
/* 25. privacy-policy css here*/
.privacy_policy_main_area {
  padding: 26px 0 35px;
}

.privacy_content {
  margin-bottom: 22px;
}

.privacy_content:last-child {
  margin-bottom: 0;
}

.privacy_content h2 {
  font-size: 22px;
  line-height: 22px;
  font-weight: 500;
  margin-bottom: 7px;
  color: #212121;
}

@media only screen and (max-width: 767px) {
  .privacy_content h2 {
    font-size: 18px;
  }
}

.privacy_content h3 {
  font-size: 22px;
  line-height: 22px;
  font-weight: 500;
  margin-bottom: 7px;
  color: #212121;
}

@media only screen and (max-width: 767px) {
  .privacy_content h3 {
    font-size: 18px;
  }
}

.privacy_content p {
  font-size: 15px;
  line-height: 25px;
  margin-bottom: 19px;
  color: #212121;
}

@media only screen and (max-width: 767px) {
  .privacy_content p {
    font-size: 14px;
  }
}

.privacy_content p a {
  color: #a43d21;
}

.privacy_content p a:hover {
  color: #f743a6;
}

.privacy_content.section_1 p {
  margin-bottom: 0;
}

.privacy_content.section_2 h2 {
  margin-bottom: 24px;
}

/*privacy policy css end*/
/* 26. compare css here*/
.compaer-page {
  margin: 32px 0 60px;
}

.compare-table .table tbody tr:first-child {
  border-top: 1px solid #eeeeee;
}

.compare-table .table tbody tr td {
  text-align: center;
  border: none;
  padding: 25px 30px;
  vertical-align: middle;
  border-bottom: 1px solid #eeeeee;
  border-left: 1px solid #eeeeee;
}

.compare-table .table tbody tr td:last-child {
  border-right: 1px solid #eeeeee;
}

.compare-table .table tbody tr td.first-column {
  min-width: 180px;
  font-size: 17px;
  font-weight: 600;
  margin: 0;
  line-height: 1;
}

.compare-table .table tbody tr td.product-image-title {
  min-width: 305px;
  vertical-align: bottom;
}

.compare-table .table tbody tr td.product-image-title .image {
  clear: both;
  width: 100%;
  margin-bottom: 40px;
}

.compare-table .table tbody tr td.product-image-title .image img {
  max-width: 100%;
}

.compare-table .table tbody tr td.product-image-title .category {
  clear: both;
  color: #454545;
  float: left;
  font-size: 13px;
  letter-spacing: 0.5px;
  line-height: 15px;
  margin-bottom: 7px;
  margin-top: 15px;
  text-transform: capitalize;
}

.compare-table .table tbody tr td.product-image-title .title {
  float: left;
  clear: both;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 10px;
}

.compare-table .table tbody tr td.product-image-title .title:hover {
  color: #f743a6;
}

.compare-table .table tbody tr td.pro-desc p {
  text-align: left;
  margin: 0;
}

.compare-table .table tbody tr td.pro-price {
  font-size: 15px;
  font-weight: 600;
}

.compare-table .table tbody tr td.pro-color {
  font-size: 15px;
  font-weight: 600;
}

.compare-table .table tbody tr td.pro-stock {
  font-size: 15px;
  font-weight: 600;
}

.compare-table .table tbody tr td.pro-addtocart .add-to-cart {
  position: relative;
  background: #f743a6;
  border-radius: 4px;
  display: inline-block;
  width: 170px;
  padding: 10px 6px;
  transition: all 0.7s;
  color: #ffffff;
}

.compare-table .table tbody tr td.pro-addtocart .add-to-cart:hover {
  background: #5a5a5a;
}

.compare-table .table tbody tr td.pro-addtocart .add-to-cart span {
  transition: .3s;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 21px;
  overflow: hidden;
  display: block;
  text-align: center;
}

.compare-table .table tbody tr td.pro-remove button {
  border: none;
  background-color: transparent;
  padding: 0;
}

.compare-table .table tbody tr td.pro-remove button i {
  font-size: 20px;
}

.compare-table .table tbody tr td.pro-remove button:hover {
  color: #f743a6;
}

.compare-table .table tbody tr td.pro-ratting a {
  font-size: 14px;
  color: #5a5a5a;
}

.compare-table .table tbody tr td.pro-ratting a:hover {
  color: #f743a6;
}

/* 27. coming soon css here*/
/* .coming_soon_area {
  height: 100vh;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  background-image: url(../img/bg/coming-soon.jpg);
  position: relative;
  display: flex;
  align-items: center;
} */
.coming_soon_area::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #000;
  opacity: 0.15;
}

.coming_soon_title {
  text-align: center;
  margin-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  .coming_soon_title {
    margin-bottom: 33px;
  }
}

.coming_soon_title h2 {
  font-size: 37px;
  text-transform: capitalize;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
  .coming_soon_title h2 {
    font-size: 23px;
    line-height: 24px;
    margin-bottom: 11px;
  }
}

.coming_soon_title p {
  color: #ffffff;
  font-size: 15px;
  max-width: 730px;
  margin: 0 auto;
  line-height: 24px;
}

.coming_soon_timing {
  text-align: center;
  margin: 57px 0 42px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming_soon_timing {
    margin: 50px 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .coming_soon_timing {
    margin: 33px 0 19px;
  }
}

.single_countdown {
  display: inline-block;
  margin-right: 35px;
}

@media only screen and (max-width: 479px) {
  .single_countdown {
    margin-right: 20px;
  }
}

.single_countdown:last-child {
  margin-right: 0;
}

.coming_soon_newsletter {
  text-align: center;
}

.coming_soon_newsletter .subscribe_form {
  margin-top: 0;
}

.coming_soon_newsletter .subscribe_form form {
  margin: 0 auto;
  width: 600px;
  position: relative;
  background: inherit;
  border: 1px solid #ebebeb;
  border-radius: 50px;
}

@media only screen and (max-width: 767px) {
  .coming_soon_newsletter .subscribe_form form {
    width: 100%;
  }
}

.coming_soon_newsletter .subscribe_form form input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #ffffff;
}

.coming_soon_newsletter .subscribe_form form input::-moz-placeholder {
  /* Firefox 19+ */
  color: #ffffff;
}

.coming_soon_newsletter .subscribe_form form input:-ms-input-placeholder {
  /* IE 10+ */
  color: #ffffff;
}

.coming_soon_newsletter .subscribe_form form input:-moz-placeholder {
  /* Firefox 18- */
  color: #ffffff;
}

.coming_soon_newsletter .subscribe_form form input {
  color: #ffffff;
  font-size: 14px;
  padding: 0 120px 0 28px;
  background: inherit;
  height: 48px;
  width: 100%;
  border: 0;
}

@media only screen and (max-width: 767px) {
  .coming_soon_newsletter .subscribe_form form input {
    padding: 0 104px 0 28px;
    height: 44px;
  }
}

.coming_soon_newsletter .subscribe_form form button {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
  background: inherit;
  color: #ffffff;
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  height: 100%;
  padding: 0 18px;
}

@media only screen and (max-width: 767px) {
  .coming_soon_newsletter .subscribe_form form button {
    padding: 0 12px;
  }
}

.coming_soon_newsletter .subscribe_form form button:hover {
  color: #f743a6;
}

.coming_soon_newsletter .subscribe_form form button::before {
  content: "";
  background: #ffffff;
  width: 2px;
  position: absolute;
  bottom: 8px;
  top: 8px;
  left: 0;
}

.coming_soon_newsletter h3 {
  font-size: 16px;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 15px;
  letter-spacing: 2px;
  text-transform: capitalize;
}

.coming_soon_newsletter .mailchimp-error {
  text-align: center;
  color: #ffffff;
}

.coming_soon_newsletter .mailchimp-success {
  text-align: center;
  color: #ffffff;
}

.coming_soon_logo {
  margin-bottom: 52px;
}

@media only screen and (max-width: 479px) {
  .coming_soon_logo {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .coming_soon_logo a img {
    width: 170px;
  }
}

.coming_soon_link {
  text-align: center;
}

.coming_soon_link ul li {
  display: inline-block;
  margin-right: 20px;
}

.coming_soon_link ul li:last-child {
  margin-right: 0;
}

.coming_soon_link ul li a {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  height: 40px;
  line-height: 40px;
  padding: 0 50px;
  display: inline-block;
  background: #f743a6;
  color: #5a5a5a;
  border-radius: 5px;
}

.coming_soon_link ul li a:hover {
  background: #5a5a5a;
  color: #ffffff;
}

.countdown_number {
  font-size: 26px;
  font-weight: 500;
  line-height: 1;
  color: #ffffff;
  margin-bottom: 8px;
  width: 60px;
  height: 40px;
  line-height: 38px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

@media only screen and (max-width: 479px) {
  .countdown_number {
    width: 55px;
    font-size: 20px;
  }
}

.countdown_title {
  font-size: 13px;
  text-transform: capitalize;
  color: #ffffff;
}

/*# sourceMappingURL=style.css.map */


.js-qty__wrapper {
  display: inline-block;
  position: relative;
  max-width: 100px;
  min-width: 75px;
  overflow: visible;
  background-color: #fff;
  background-color: var(--colorBody);
  color: #000;
  color: var(--colorTextBody);
}

.js-qty__num {
  display: block;
  background: none;
  text-align: center;
  width: 100%;
  padding: 8px 25px;
  margin: 0;
  z-index: 1;
}

.js-qty__adjust--minus {
  left: 0;
  border-right: 1px solid;
  border-right-color: #f2f2f2;
  border-right-color: var(--colorBodyDim);
}

.js-qty__adjust {
  cursor: pointer;
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  border: 0 none;
  background: none;
  text-align: center;
  overflow: hidden;
  padding: 0 5px;
  line-height: 1;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: background-color .1s ease-out;
  z-index: 2;
  fill: #000;
  fill: var(--colorTextBody);
}

.js-qty__adjust .icon {
  display: block;
  font-size: 8px;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  fill: inherit;
}

.icon__fallback-text {
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  position: absolute;
  height: 1px;
  width: 1px;
}

.js-qty__adjust--plus {
  right: 0;
  border-left: 1px solid;
  border-left-color: #f2f2f2;
  border-left-color: var(--colorBodyDim);
}

.js-qty__adjust {
  cursor: pointer;
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  border: 0 none;
  background: none;
  text-align: center;
  overflow: hidden;
  padding: 0 5px;
  line-height: 1;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: background-color .1s ease-out;
  z-index: 2;
  fill: #000;
  fill: var(--colorTextBody);
}