#root {
  width: 100%;
  overflow: hidden;
}




::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* line 18, ../scss/_frame.scss */
::-webkit-scrollbar-track {
  background: #F5F5F5;
}

/* line 22, ../scss/_frame.scss */
::-webkit-scrollbar-thumb {
  background: #f743a6;
  border-radius: 2px;
}

/* line 27, ../scss/_frame.scss */
::-webkit-scrollbar-thumb:hover {
  -webkit-filter: brightness(120%);
  filter: brightness(120%);
}


.single_product {
  /* transform: scale(1.1); */
  box-shadow: 1px 10px 25px #0000001a;
  /* position: relative;
  z-index: 10; */
  /* padding: 20px; */
  /* margin: 10px; */
}

.mv-100 {
  margin-top: 100px;
  margin-bottom: 30px;
}

.mv-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.mt-50 {
  margin-top: 50px;
  /* margin-bottom: 50px; */
}

.product_section {
  margin-bottom: 200px;
}


.homeslider {
  position: relative;
}

.homeslider .slick-dots li.slick-active button:before {
  opacity: .75;
  color: #f743a6;
}

.homeslider .slick-dots li button:before {

  font-size: 15px;
  line-height: 20px;
  position: absolute;
  top: -48px !important;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .25;
  color: #f743a6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.loadingPage {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  /* background-color: #00000087; */
  background-color: white;
}





.video {
  /* width: 100%; */
  height: 100%;
  width: 100%;
  height: 100%;
  /* position: absolute;
  top: 0;
  left: 0; */
  -o-object-position: center;
  object-position: center;
  -o-object-fit: cover;
  object-fit: cover;
}






.videoContainer {
  height: 150vh;
}

.sidbar {
  height: 50vh;
  overflow: auto;
}



.home_single_slider {
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
}

.bg2 {
  position: relative;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
}



.quick_button a {
  line-height: 45px;
  background: #3e3e3e;
  color: #ffffff !important;
  padding: 0 10px;
  font-weight: normal;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  font-size: 13px;
  text-transform: capitalize;
  width: 100%;
  text-align: center;
  display: block;
}

.searchDrop {
  background: #ffffff;
  padding: 10px;
  position: relative;
  z-index: 999;
  border: 1px solid rgba(138, 138, 138, 0.243);
}

.searchDrop li {
  cursor: pointer;
  border-bottom: 1px solid rgba(144, 144, 144, 0.255);
}

.aboutSection>p {

  font-size: 20px;
}

.addBtn1 {
  border: 1px solid #212121;
  margin-left: 10px;
  font-size: 12px;
  font-weight: 700;
  height: 38px;
  line-height: 18px;
  padding: 10px 15px;
  text-transform: uppercase;
  background: #212121;
  color: #ffffff;
  border-radius: 5px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
}

.sel {
  border: 1px solid #ebebeb;
  background: none;
  height: 40px;
  width: 100%;
  padding: 0 20px;
  color: #5a5a5a;
}

.slider_content {
  text-align: center;
}

.mb-50 {
  margin-bottom: 50px;
}

.catName {
  text-align: center;
}

.catName h3 {
  /* margin-bottom: 20px; */
  font-size: 20px;
}


.highLight img {
  width: 70px;
  margin: auto;
}

.socialHBtn {
  background: #f743a6;
  box-shadow: none;
  color: #ffffff;
  display: inline-block;
  height: 45px;
  line-height: 45px;
  padding: 0 22px;
  text-transform: uppercase;
  font-size: 13px;
  border-radius: 3px;
}

.socialHBtn:hover {
  color: #ffffff;
  background: #212121;
}

.socialHBtn:focus {
  color: #ffffff;
  /* background: #212121; */
}

.socialheading {
  letter-spacing: 6px;
}

.mt-15 {
  margin-top: 15px;
}

.catBox {
  box-shadow: 1px 10px 25px #0000001a;
  padding: 10px;
}

.addressCard {
  padding: 10px;
  border: 1px solid #5a5a5a;
  margin-bottom: 10px;
  cursor: pointer;
}


.mainContainerV {
  position: relative;
  overflow: hidden;
  background: #0f0f0f;
  height: 650px;
}

.childCon {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  z-index: 1;
}



.childCon:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  background-color: #000;
  /* background-color: var(--colorImageOverlay); */
  opacity: .1;
  /* opacity: var(--colorImageOverlayOpacity); */
}


.subchildCon {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.subchildCon video {
  position: absolute;
  width: 300%;
  height: 300%;
  left: -100%;
  top: -100%;
}

.single_product:hover {
  background: #ffffff;
  border-radius: 3px;
  box-shadow: 0px 0 15px 0 rgb(0 0 0 / 30%) !important;
}

.prohg {
  padding: 20px;
  margin: 10px;
}

.editAddBtn a {
  margin-top: 10px;
  width: max-content;
  padding-right: 10px;
  padding-left: 10px;
  color: #ffffff !important;
}

.orderDe {
  background: #f743a6;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #ffffff;
}


.search_btn1 a {
  width: 45px;
  height: 45px;
  margin-right: 15px;
  border-radius: 3px;
  text-align: center;
  line-height: 45px;
  border: 1px solid #ebebeb;
  color: #212121;
  font-size: 20px;
  display: block;
}

.sliderCategory {
  width: 95%;
  overflow: auto;
  /* position: absolute;
  top: 10px;
  left: 10px; */
  text-align: center;
}

.sliderCategorys {
  width: 100%;
  top: 81px;
  background: #ffffff;
  z-index: 99;
  display: none;
  position: fixed;
  overflow: auto;
  text-align: center;
}

.sliderCategorybox {
  margin: 5px;
  padding: 5px;
  /* background: #f743a6; */
  box-shadow: none;
  /* color: #ffffff; */
  color: #2c2a2a;
  display: inline-block;
  /* height: 45px; */
  /* line-height: 45px; */
  padding: 0 22px;
  text-transform: uppercase;
  font-size: 13px;
  border-radius: 3px;
  cursor: pointer;
}

.sliderCategorybox:hover {
  color: #ffffff !important;
  background: #ff89ca;
}

.hidden {
  display: none !important;
}

.active {
  display: block !important;
}

.sliderCategorybox-active {
  color: #ffffff !important;
  background: #F743A6;
}

.changeLog {
  cursor: pointer;
}

.menu-item-has-children .sliderCategorybox {
  border-bottom: 1px solid #ebebeb;
  padding: 0 5px;
}

.accountbold {
  font-weight: 700 !important;
}

#exampleModal {
  background-color: #0f0f0f7a;
}

.modal {
  z-index: 99999999 !important;
}



.cartIcon {
  position: relative;
}

.cartIcon .cartItmeCount {
  font-size: 10px;
  background: rgb(247, 67, 166);
  color: #ffffff;
  padding: 0px 6px;
  line-height: 16px;
  border-radius: 50%;
  display: block;
  position: absolute;
  bottom: 3px;
  right: 0px;
}

.searchDrop {
  background: #ffffff;
  padding: 10px;
  position: relative;
  z-index: 999;
  border: 1px solid rgba(138, 138, 138, 0.243);
}

.searchDrop li {
  cursor: pointer;
  border-bottom: 1px solid rgba(144, 144, 144, 0.255);
}

.socailIcon {
  cursor: pointer;
}

.whatsappIconH {
  width: 40px;
  height: 40px;
  padding: 3px;
  background: #2aff00;
  border-radius: 50%;
  color: #ffffff;
  position: fixed;
  bottom: 95px;
  right: 23px;
  z-index: 9999999;
}

.whatsappIconH svg {
  font-size: 34px;
}

.whatsappIconH:hover {
  color: #ffffff !important;
}

.testimonial .author-image img {
  width: 70px;
}

.onhPointer {
  cursor: pointer;
}

.NavLink {
  font-weight: 800 !important;
}


.button2 {
  background: #f743a6;
  box-shadow: none;
  color: #ffffff;
  display: inline-block;
  height: 34px;
  line-height: 34px;
  padding: 0 10px;
  text-transform: uppercase;
  font-size: 10px;
  border-radius: 3px;
  border: none;
}

.button2:hover {
  background: #242424;
}

.navlinks {
  display: flex;
  justify-content: space-between;
  display: "flex";
  align-items: center;
  gap: "2px";

}

.link-btn {
  display: none;
}

.subMenu-nev {
  background: white;
  display: none;
  position: fixed;
  top: 0px;
  right: 0;
  width: 300px;
  z-index: 9999;
  border: 1px solid #666262;
  padding: 20px;
  flex-direction: column;
  box-shadow: #212121;
  gap: 5px;
  height: 100%;
}

.active {
  display: flex !important;
}

@media screen and (max-width: 768px) {
  .navlinks {
    display: none;
  }

  .link-btn {
    display: block;
  }



}